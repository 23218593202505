import React, { useEffect, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { Components } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";
import ViewerApi from "../api/ViewerApi";

/**
 * Инструмент для просмотра файлов: изображение, pdf
 *
 * @param {String} type тип файла
 * @param {String} name название файла
 * @param {String} icon название файла
 * @param {String} file id файла в файловом хранилище
 * @param {String} loaded url локального Blob файла, который уже был загружен.
 * Те это файл, который уже был загружен клиентом и его не нужно брать из файлового хранилища АИС
 * @param {Func} onLoad callback ф-я на загрузку файла
 */
const File = forwardRef((props, ref) => {
  const { name, type, icon } = props;
  const { rootStore } = useStores();
  const api = new ViewerApi(rootStore);
  const [loaded, setLoaded] = useState(props.loaded);

  useEffect(() => {
    if (!props.loaded && props.file) {
      fileLoader(props.file);
    } else if (props.loaded) {
      setLoaded(props.loaded);
    }
  }, [props.file, props.loaded, props.onLoad]);

  const fileLoader = async(file) => {
    const loadedFile = await api.getFile(file);
    if (props.onLoad) {
      props.onLoad(loadedFile, false);
    } else {
      setLoaded(loadedFile);
    }
  };

  return (
    <div 
      ref={ref}
      className="file-content"
    >
      <Components.Viewer
        type={type}
        src={loaded}
        name={name}
        icon={icon}
      />
    </div>
  );
});

File.displayName = "File";

File.propTypes = {
  type:   PropTypes.string,
  name:   PropTypes.string,
  icon:   PropTypes.string,
  file:   PropTypes.string,
  loaded: PropTypes.string,
  onLoad: PropTypes.func
};

export default File;
