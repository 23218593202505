import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import SearchIssueItem from "./SearchIssueItem";

import { IssueModel } from "../../models";
import IssueStore from "../../stores/IssueStore";
import { Components, Field } from "@ais3p/ui-framework";


/**
 * Компонент для поиска задач по тексту
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} props.store хранилище для работы с задачами
 * @param {IssueModel} props.issue текущая задача
 * @param {String} props.mode какой тип задач ищем - parent|subtask
 * @param {Function} props.onSelectIssue callback функция при выборе найденной задачи
 * @param {Function} props.onCancel callback функция при отмене поиска
 */

const SearchIssues = ({ store, issue, mode, onSelectIssue, onCancel }) => {
  let timer = 0; // таймер для принятия решения - закончено ли изменение ширины столбца
  const delay = 1000; // задержка мс для принятия решения - закончено ли изменение ширины столбца

  const [issues, setIssues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState("");
  
  const onChangeText = useCallback((text) => {
    clearTimeout(timer);
    setText(text);
    // сделал такую задержку, чтобы исключить множественные запросы при наборе
    timer = setTimeout(() => {
      if (text) {
        searchIssues(text, mode);
      }      
    }, delay);
  }, [mode]);

  const searchIssues = async(text, mode) => {
    setIsLoading(true);
    try {
      const data = await store.searchIssuesByText(text);
      if (mode === "parent") {
        setIssues(data.filter((issue) => {
          return issue.status?.isClosed === false;
        }));
      } else {
        setIssues(data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="search-issues">
      <div className="search-issues-header">
        <Field.String
          icon="search-M"
          label={mode === "parent" ? "Поиск родительской задачи" : "Поиск подзадачи"}
          autoFocus={true}
          name="text"
          value={text}
          placeholder={
            mode === "parent" ? 
              "Наберите текст для поиска родительской задачи" :
              "Наберите текст для поиска подзадачи"
          }
          onChange={onChangeText}
        />
      </div>
      <div className="search-issues-list">
        {isLoading &&
          <div className="search-issues-preloader">
            <Components.Preloader />
          </div>
        }
        {issues.map((item) => {
          return (
            <SearchIssueItem
              key={item.uid} 
              item={item}
              issue={issue}
              store={store}
              onSelectIssue={onSelectIssue}
            />
          );
        })}
      </div>
      <div className="search-issues-controls">
        <Components.Button
          text="Отменить"
          icon="cancel-M"
          color="negative"
          onPress={onCancel}
        />
      </div>
    </div>
  );
};

SearchIssues.propTypes = {
  store:         PropTypes.instanceOf(IssueStore).isRequired, 
  issue:         PropTypes.instanceOf(IssueModel).isRequired, 
  mode:          PropTypes.string.isRequired, 
  onSelectIssue: PropTypes.func.isRequired,
  onCancel:      PropTypes.func.isRequired
};

export default SearchIssues;
