import { ContextMenu, Dnd } from "@ais3p/ui-framework";
import { observer } from "mobx-react";
import React, { useCallback, useMemo } from "react";
import AisIcon from "~/core/components/AisIcon";
import { 
  DND_EDITORITEM_TYPE
} from "~/core/constants/DnD";
import { getClassTitle } from "~/core/constants/Classes";

const TextItemIcon = observer(({ data, onClick, setContextMenu }) => {
  const { isFocusUid, isVersion, shortClass, plusMenuItems, isLocked, isParentLocked, 
    isChildLocked, isLockedByMe, isParentLockedByMe, isChildLockedByMe } = data;

  const onClickIcon = useCallback(
    (e) => {
      e.stopPropagation();
      onClick && onClick(data);
    },
    [data]
  );

  const hasItems = useMemo(() => {
    let size = 0;
    plusMenuItems && plusMenuItems.forEach((item) => {
      if (item && item.availableKindsArray && item.availableKindsArray.length) {
        size += item.availableKindsArray.length;
      }
    });
    return !!size;
  }, [plusMenuItems]);

  const contextMenuCollect = useCallback(
    (item) => {
      if (isVersion) {
        setContextMenu([
          {
            icon:   "copy-M",
            type:   "read",
            title:  "Скопировать ссылку",
            action: "createUrl"
          }
        ]);
        return;
      }

      const menuItems = [
        {
          icon:   "copy-M",
          type:   "read",
          title:  "Скопировать ссылку",
          action: "createUrl"
        }
      ];

      if (
        item && (!item.isLocked || !!item.isLockedByMe)
        && (!item.isParentLocked || item.isParentLockedByMe) 
        && (!item.isChildLocked || item.isChildLockedByMe) 
      ) {
        if (item.className === "text.element.Picture") {
          menuItems.unshift({
            icon:   "editing-M",
            title:  "Изменить подпись",
            action: "editCaption"
          });
          menuItems.unshift({
            icon:   "editing-M",
            title:  "Изменить рисунок",
            action: "editPicture" 
          });
        }
  
        menuItems.push({
          icon:   "delete-M",
          title:  "Удалить",
          action: "delete"
        });
      }

      if (item && !item.isLocked && 
          !(item.isParentLocked || item.isParentLockedByMe) && !(item.isChildLocked || item.isChildLockedByMe)) {
        menuItems.unshift({
          icon:   "fixed-M",
          title:  "Заблокировать",
          action: "lock"
        });
      } else if (item && !!item.isLockedByMe) {
        menuItems.unshift({
          icon:   "permissions-M",
          title:  "Разблокировать",
          action: "unlock"
        });
      }

      if (item.className === "text.element.Enumeration") {
        menuItems.unshift({
          icon:     "list-M",
          title:    "Вариант списка",
          children: [
            {
              icon:   "list-num-M",
              title:  "Нумерованый",
              action: "setVariantOrdered" 
            }, {
              icon:   "list-mark-M",
              title:  "Обычный",
              action: "setVariantUnordered" 
            }
          ]
        });
      }

      if (hasItems) {
        const items = [];
        plusMenuItems.forEach((item) => {
          if (item.availableKindsArray.length) {
            const level = Math.ceil(item.level / 2);
            const children = [];
            item.availableKindsArray.forEach((availableKind) => {
              children.push({
                icon:   availableKind.icon || item.icon,
                title:  availableKind.title || getClassTitle(item.class),
                action: "addItem",
                data:   { ...item, kind: availableKind }
              });
            });
            if (children.length === 1) {
              items.push({ 
                ...children[0], 
                title: `${item.title || ""} ${children[0].title}${item.level >= 0 ? ` уровень ${level}` : ""}` 
              });
            } else if (children.length > 1) {
              items.push({
                icon:  item.icon,
                title: `${item.title}${item.level >= 0 ? ` уровень ${level}` : ""}`,
                children
              });
            }
          }
        });
        menuItems.unshift({
          icon:     "newelement-before-M",
          title:    "Добавить",
          children: items
        });
      }
      setContextMenu(menuItems);
    },
    [isVersion, setContextMenu, hasItems, plusMenuItems]
  );

  const canDrag = useCallback(() => {
    return !((isLocked && !isLockedByMe) || 
            (isParentLocked && !isParentLockedByMe) || 
            (isChildLocked && !isChildLockedByMe));
  }, [isVersion, isLocked, isParentLocked, isChildLocked, isLockedByMe, isParentLockedByMe, isChildLockedByMe]);

  return (
    <Dnd.Source
      isRelative={true}
      item={{
        class:   data.className,
        parent:  null,
        tool:    data.domain,
        title:   data.title,
        uid:     data.uid,
        version: data.version
      }}
      uid={data.uid}
      // eslint-disable-next-line react/jsx-no-bind
      canDrag={canDrag}
      type={DND_EDITORITEM_TYPE}
    >
      <ContextMenu.Trigger
        menuId={data.editable}
        context={data}
        collect={contextMenuCollect}
      >
        <AisIcon
          onClick={onClickIcon}
          className={`icon ${shortClass} ${isFocusUid ? "focus" : ""}`}
          item={data}
        />
      </ContextMenu.Trigger>
    </Dnd.Source>
  );
});

export default TextItemIcon;
