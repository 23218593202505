import React, { useCallback, useState, useMemo } from "react";
import { observer } from "mobx-react";
import { Components, Field } from "@ais3p/ui-framework";
import classNames from "classnames";
import useStores from "~/core/utils/useStores";
import UserModel from "~/modules/users/models/User";

const EmailItem = observer(({ index, email, onAddEmail }) => {
  const { accountStore, uiStore, userStore } = useStores();

  const [address, setAddress] = useState("");
  const [isPending, setIsPending] = useState(false);
  
  const onDoDelete = useCallback(async() => {
    uiStore.setConfirmPending(true);
    setIsPending(true);
    try {
      await accountStore.deleteNotificationEmail(email.uid);
      uiStore.hideConfirm();
    } finally {
      uiStore.setConfirmPending(false);
      setIsPending(false);
    }
  }, [email?.uid]);

  const onCancelDelete = useCallback(() => {
    uiStore.hideConfirm();
  }, []);

  const removeEmail = useCallback(() => {
    uiStore.setConfirm({
      icon:    "delete-M",
      title:   "Удаление email",
      content: `Вы действительно хотите удалить email  - ${email.address}?`,
      buttons: [
        <Components.Button
          key="delete"
          text="Удалить"
          icon="delete-M"
          onPress={onDoDelete}
          color="negative"
        />,
        <Components.Button
          key="cancel"
          text="Отмена"
          icon="cancel-M"
          onPress={onCancelDelete}
          color="dark"
        />
      ],
      onKeyPressEsc:   onCancelDelete,
      onKeyPressEnter: onDoDelete
    });
  }, [email?.uid, email?.address]);

  const onChangeIsEnabled = useCallback(async(v) => {
    if (email?.uid) {
      setIsPending(true);
      try {
        await accountStore.updateNotificationEmail(email.uid, email.address, v);
      } finally {
        setIsPending(false);
      }
    }
  }, [email?.uid, email?.address]);

  const onChangeAddress = useCallback((v) => {
    setAddress(v);
  }, []);
  
  const validateEmail = useCallback((address) => {
    const res = UserModel.validateEmail(address, userStore, accountStore.uid);
    if (res.isValid) {
      const emails = accountStore.emails.filter((e) => {
        return e.address === address;
      });

      if (emails.length > 0) {
        return {
          isValid: false,
          hint:    `E-mail адрес "${address}" уже существует в списке адресов.`
        };
      }
    }

    return res;
  }, [accountStore.uid]);

  const isValid = useMemo(() => {
    const res = validateEmail(address);
    return res.isValid;
  }, [address, validateEmail]);

  const saveEmail = useCallback(async() => {
    setIsPending(true);
    try {
      const email = await accountStore.addNotificationEmail(accountStore.uid, address);
      onAddEmail && onAddEmail(email);
    } finally {
      setIsPending(false);
    }
  }, [address, accountStore.uid, onAddEmail]);

  if (!email) {
    return (
      <li className="email-item">
        {isPending && 
          <Components.Preloader size={3} className="preloader-center" />
        }
        <div className={"email-item-title"}>
          <Field.String
            icon=""
            label="E-mail"
            name="address"
            type="email"
            value={address}
            onChange={onChangeAddress}
            {...validateEmail(address)}
          />
        </div>
        <div className={"email-item-controls padding-top"}>
          <Components.Button
            color="negative"
            tooltip={"Отменить добавление email записи"}
            icon={"cancel-M"}
            onPress={onAddEmail}
            className={"email-item-controls-button"}
          />
          <Components.Button
            color="positive"
            tooltip={"Сохранить email запись"}
            icon={"save-M"}
            onPress={saveEmail}
            isDisabled={!isValid}
            className={"email-item-controls-button"}
          />
        </div>
      </li>
    );
  }

  return (
    <li className={classNames("email-item", { disabled: !email.isEnabled })}>
      {isPending && 
      <Components.Preloader size={3} className="preloader-center" />
      }
      <div className="email-item-num">
        {index + 1}
      </div>
      <div className={"email-item-icon"}>
        {email.isPrimary &&
          <Components.Tooltip
            content="Основной адрес"
          >
            <Components.Icon
              name={"star-S"}
              size={3}
            />
          </Components.Tooltip>
        }
      </div>
      <div className={"email-item-title"}>
        {email.address}
      </div>
      <div className={"email-item-controls"}>
        <Field.Boolean 
          value={email.isEnabled}
          name="isEnabled"
          tooltip="Включен ли адрес для отправки"
          onChange={onChangeIsEnabled}
        />
        <Components.Button
          color="negative"
          tooltip={!email.isPrimary && "Удалить email"}
          icon={"delete-M"}
          isDisabled={email.isPrimary}
          onPress={!email.isPrimary && removeEmail}
          className={"email-item-controls-button"}
        />
      </div>
    </li>
  );
});

export default EmailItem;
