import { action, computed, observable } from "mobx";

/**
 * Модель записи email для оповещения
 */
class Email {
  /**
   * @type {String}
   * 
   * uid записи email для оповещения
   */
  @observable
  uid = undefined;

  /**
   * @type {String}
   * 
   * Адрес email для оповещения
   */
  @observable
  address = undefined;

  /**
   * @type {Boolean}
   * Признак, что адрес основной
   */
  @observable
  isPrimary = undefined;

  /**
   * @type {Boolean}
   * Признак, что адрес доступен
   */
  @observable
  isEnabled = undefined;

  /**
   * @type {String}
   * 
   * uid пользователя, к котрому принадлжеит email адрес для оповещения
   */
  @observable
  subject = undefined;

  static create({
    uid,
    address,
    isPrimary,
    isEnabled,
    subject
  }) {
    return new Email({
      uid,
      address,
      isPrimary,
      isEnabled,
      subject
    });
  } 

  constructor({
    uid,
    address,
    isPrimary,
    isEnabled,
    subject
  }) {
    this.uid = uid;
    this.address = address;
    this.isPrimary = isPrimary;
    this.isEnabled = isEnabled;
    this.subject = subject;
  }

  /**
   * Получить uid пользователя, к котрому принадлжеит email адрес для оповещения
   * 
   * alias  subject
   * @return {String}
   */
  @computed
  get userUid() {
    return this.subject;
  }

  /**
   * Получить адрес email для оповещения
   * 
   * alias  address
   * 
   * @return {String}
   */
  @computed
  get email() {
    return this.address;
  }

  /**
   * Задать новый адрес email 
   * 
   * @param {String} address новый адрес email для оповещения
   */
  @action
  setAddress(address) {
    this.address = address;
  }

  /**
   * Задать признак доступности email
   * 
   * @param {Boolean} isEnabled признак доступности email
   */
  @action
  setIsEnabled(isEnabled) {
    this.isEnabled = isEnabled;
  }

  /**
   * Обновить данные email
   * 
   * @param {Object} data набор данных для обновления email
   */
  @action
  update(data) {
    if (data.hasOwnProperty("isEnabled")) {
      this.isEnabled = data.isEnabled;
    }

    if (data.hasOwnProperty("isPrimary")) {
      this.isPrimary = data.isPrimary;
    }

    if (data.hasOwnProperty("address")) {
      this.address = data.address;
    }
  }
}

export default Email;
