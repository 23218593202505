import {  React, useMemo, useEffect, useCallback } from "react";
import process from "process";
import { observer } from "mobx-react";
import { logger } from "./logger";
import LoggerContext from "./context";
import Stack from "./stack";

/**
 * Контейнер для логгера и перехвата ошибок в UI
 * 
 * @param {String} traceId уникальный индификатор для поиска ошибки в Kabana
 * @param {Number} limit максимальное чило записей в стеке данных - действия пользователя, добавленные через логгер, 
 * которые можно хранить в памяти
 * @param {Function} props.stdout пользовательский метод для вывода сообщения, например в консоль
 * Метод будет вызываться со слеюущими параметрами: (level: string, message: string, important?: boolean)
 * @param {Function} onPrepareStack  Позволяет добавлять дополнительную информацию в стек перед вызовом onError. 
 * Например, можно добавить  имя пользователя, получившего ошибку, 
 * выбранную пользователем тему, имя пользователя, получившего ошибку и т. д.
 * @param {Function} onError callback метод, вызывыемый при возникновении ошибки. 
 * В параметрах отправляется стек действий до ошибки. Эти данные можно будет отправить на бэкэнд 
 * или обрабатать иным образом. Например вывести  в консоль
 * @param {Elements} children набор вложенных React компонент
 */
const LoggerContainer = observer(({ traceId, limit, stdout, onPrepareStack, onError, children }) => {
  // let hasCriticalError = false;

  /**
   * Стек данных
   */
  const stack = useMemo(() => {
    return new Stack(traceId);
  }, [traceId]);
  
  /**
   * Флаг, указывающий на окружение - разработчика или нет (production)
   *
   * @return {Boolean}
   */
  const isDev = useMemo(() => {
    return (!process.env.NODE_ENV || process.env.NODE_ENV === "development");
  }, []);

  /**
   * Прикрепляем методы, отслеживающие действия пользвоателя на клавиатуре и мышке
   */
  const bindActions = () => {
    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
  };

  /**
   * Открепляем методы, отслеживающие действия пользвоателя на клавиатуре и мышке
   */
  const unbindActions = () => {
    window.removeEventListener("error", handlerError);
    document.removeEventListener("keydown", onKeyDown);
    document.removeEventListener("keyup", onKeyUp);
    document.removeEventListener("mousedown", onMouseDown);
    document.removeEventListener("mouseup", onMouseUp);
  };

  // Инициализируем логгер
  useEffect(() => {
    // const active = !isDev; // логгируем только на продакшн
    const active = true;

    logger.init({
      active,
      stdout,
      limit
    });

    if (active) {
      bindActions();
      window.addEventListener("error", handlerError);
    }

    return (() => {
      unbindActions();
    });
  }, [stdout, limit, bindActions, unbindActions, isDev]);

  /**
   * Получить стек данных
   */
  const getStackData = useCallback(() => {
    const data = stack.getData(logger);
    if (onPrepareStack && typeof  onPrepareStack === "function") {
      return onPrepareStack(data);
    }
    return data;
  }, [logger, stack, onPrepareStack]);

  // значения для контекста
  const loggerValue = useMemo(() => {
    return ({
      logger,
      getStackData,
      onError
    });
  }, [logger, onError, getStackData, stack]);
  
  // Обработчик js ошибки в UI
  const handlerError = useCallback((e) => {
    const { lineno, error } = e;
    unbindActions();
    // if (!hasCriticalError) {
    //   hasCriticalError = true;
    let stackData = stack.criticalDataError(logger, error, lineno); 
    if (onPrepareStack && typeof onPrepareStack === "function") {
      stackData = onPrepareStack(stackData);
    }

    if (onError && typeof onError === "function") {
      onError(stackData);
    }
    // }
  }, [onError, logger, stack, onPrepareStack]);

  /**
   * Обработчик нажатия кнопки у мышки. 
   * Временно запоминаем это нажатие, чтобы если после нажати кнопки произойдет js ошибка, то
   * можно было понять из-за какого нажатия это произошло
   */
  const onMouseDown = useCallback((e) => {
    if (e) {
      stack.setMousePressed({
        button: e?.button,
        text:   e?.target?.innerText || e?.target?.className
      });
    }
  }, [stack]);

  /**
   * Обработчик нажатия кнопки на клавиатуре. 
   * Временно запоминаем это нажатие, чтобы если после нажати кнопки произойдет js ошибка, то
   * можно было понять из-за какого нажатия это произошло
   */
  const onKeyDown = useCallback((e) => {
    if (e && e.code) {
      stack.setKeyboardPressed(e.code);
    }
  }, [stack]);

  /**
   * Очищаем значения нажатия кнопки на клавиатуре
   */
  const onKeyUp = useCallback(() => {
    setTimeout(() => {
      stack.setKeyboardPressed(null);
    }, 0);
  }, [stack]);

  /**
   * Очищаем значения нажатия кнопки у мышки
   */
  const onMouseUp = useCallback(() => {
    setTimeout(() => {
      stack.setMousePressed(null);
    }, 0);
  }, [stack]);

  return (
    <LoggerContext.Provider
      value={loggerValue}
    >
      {children}
    </LoggerContext.Provider>
  );
});

export default  LoggerContainer;
