import moment from "moment";

/**
 * Сформировать набор данных для запроса получения списка задач
 *
 * @param {Object} values данные с панели с формы фильтров
 * @param {String} projectUid uid активного проекта
 *
 * @retrun {Object}
 */
const generateFiltersData = (values, projectUid, store) => {
  let status = [];
  const project = store.getProjectByUid(projectUid);
  if (values.status) {
    status = Array.isArray(values.status) ? values.status : [values.status];
  }
  let data = {
    tracker:            values.tracker,
    status,
    priority:           values.priority,
    author:             values.author,
    assignedTo:         values.assignedTo,
    project:            [projectUid],
    createDateFrom:     values.createDateFrom && moment(values.createDateFrom).format("YYYY-MM-DD"),
    createDateTo:       values.createDateTo && moment(values.createDateTo).format("YYYY-MM-DD"),
    updateDateFrom:     values.updateDateFrom && moment(values.updateDateFrom).format("YYYY-MM-DD"),
    updateDateTo:       values.updateDateTo && moment(values.updateDateTo).format("YYYY-MM-DD"),
    startDateFrom:      values.startDateFrom && moment(values.startDateFrom).format("YYYY-MM-DD"),
    startDateTo:        values.startDateTo && moment(values.startDateTo).format("YYYY-MM-DD"),
    dueDateFrom:        values.dueDateFrom && moment(values.dueDateFrom).format("YYYY-MM-DD"),
    dueDateTo:          values.dueDateTo && moment(values.dueDateTo).format("YYYY-MM-DD"),
    subject:            values.subject,
    includeSubprojects: values.includeSubprojects
  };
  if (values.customFields) {
    const customFields = {};
    for (const id in values.customFields) {
      if (Array.isArray(values.customFields[id])) {
        if (values.customFields[id].length > 0) {
          customFields[id] = values.customFields[id].join("|");
        }
      } else {
        if (values.customFields[id]) {
          const field = project?.filterCustomFields.filter((f) => {
            return Number(f.id) === Number(id);
          })[0];
          if (field) {
            switch (field.fieldFormat) {
              case "string":
              case "text":{
                // добавляем для строковых полей префикс ~, чтобы было сравнение LIKE T6410#169777
                customFields[id] = `~${values.customFields[id]}`;
                break;
              }
              default:{
                customFields[id] = values.customFields[id];
                break;
              }
            }
          } else {
            customFields[id] = values.customFields[id];    
          }
        }
      }
    }
    data = {
      ...data,
      customFields
    };
  }
  return data;
};

export default generateFiltersData;
