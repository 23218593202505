import { observable, action, computed, toJS } from "mobx";

import AccountApi from "../api/accountApi";
import Workspace from "../models/Workspace";
import User from "~/modules/users/models/User";
import Group from "~/modules/users/models/Group";
import { DOMAIN_KINDS } from "~/core/constants/Domains";
import Email from "../models/Email";

const LOCK_KEY = "ais3p";

class AccountStore {
  /**
   * Uid учетной записи пользователя
   *
   * @type {String}
   */
  @observable uid;

  /**
   * Token авторизационной сессии
   *
   * @type {String}
   */
  @observable token = null;

  /**
   * Login пользователя
   *
   * @type {String}
   */
  @observable login;

  /**
   * Учетная запись пользователя
   *
   * @type {User}
   */
  @observable user = undefined;

  @observable presset;

  @observable editingId;
  @observable isCreating;

  @observable workspaces = new Map();
  @observable pendingWorkspaces = false;
  @observable permissions = new Map();

  @observable loadingPresset = false;
  @observable autoLoginPending = false;
  @observable pending;
  @observable iconMap = new Map();
  @observable errorObj;
  /**
   * Набор emails пользваотелей для оповещения
   * 
   * @type Map<Email>
   */
  @observable emailsMap = new Map();

  constructor(root) {
    this.rootStore = root;
    this.api = new AccountApi(root);
    this.checkPersistedAccountData();
  }

  /**
   * Проинициализировать хранилище
   * Если в session storage сохранены uid пользователя и token сессии, то будет автоматический вход
   */
  async init() {
    if (this.uid && this.token) {
      this.setAutoLoginPending(true);
      try {
        await this.initUser(this.uid, true);
      } finally {
        this.setAutoLoginPending(false);
      }
    }
  }

  /**
   * Загрузка данных пользователя и иницализация его окружения
   */
  async initUser(uid, restore = false) {
    this.setPending(true);
    try {
      const userData = await this.api.loadUser(uid, true);
      const u = User.create(userData);
      if (userData.groups && Array.isArray(userData.groups)) {
        userData.groups.forEach((gr) => {
          u.addGroup(Group.create(gr));
        });
      }
      this.setUser(u);
      await this.afterLogin(restore);
    } catch (e) {
      this.onError(e);
    } finally {
      this.setPending(false);
    }
  }

  /**
   * Задать модель пользователя
   *
   * @param {UsertModel} user модель пользователя
   */
  @action
  setUser(user) {
    this.user = user;
  }

  /**
   * Задать авторизационные данные пользвоателя, полученны при создании сессии на стороне сервиса
   *
   * @param {UsertModel} user модель пользователя
   */
  @action
  setAuthData(authData, login) {
    this.uid = authData.operator;
    this.token = authData.bearerToken;
    this.login = login;
    this.persistAccountData();
  }

  /**
   * Сохранить учетные данные в хранилище сессии
   */
  persistAccountData() {
    if (this.uid && this.token) {
      sessionStorage.setItem("uid", this.uid);
      sessionStorage.setItem("token", this.token);
      sessionStorage.setItem("login", this.login);
      window.name = LOCK_KEY; // прописываем признак того, что произошла авторизация.
      // при дублировании вкладки этого параметра в window не будет T5681
    } else {
      sessionStorage.clear();
    }
  }

  /**
   * Проверяем, сохранены ли учетные данные в хранилище сессии
   */
  @action
  checkPersistedAccountData() {
    // При дублировании вкладки параметра name в window не будет, 
    // тогда очищаем хранилище сессии - решение задачи T5681
    if (window.name !== LOCK_KEY) {
      sessionStorage.clear();
      return;
    }
    const uid = sessionStorage.getItem("uid");
    const token = sessionStorage.getItem("token");
    if (uid && token) {
      this.uid = uid;
      this.token = token;
    }
    this.login = sessionStorage.getItem("login");
  }

  /**
   * Очищаем учетные данные в хранилище сессии
   */
  clearAccountData() {
    sessionStorage.clear();
  }

  /**
   * Обновить права пользователя для работы в UI
   */
  @action
  updatePermissions(perms) {
    if (perms) {
      this.permissions.clear();
      const permsObject = {};
      perms.forEach(([login, address, value]) => { // eslint-disable-line
        if (permsObject[address]) {
          permsObject[address].push(value);
        } else {
          permsObject[address] = [value];
        }
      });
      Object.keys(permsObject).forEach((address) => {
        const permsSet = new Set(permsObject[address]);
        let [domain, kindId, name] = address.split("."); // eslint-disable-line
        if (!name) {
          name = kindId;
          kindId = "object";
        }
        let kind = this.permissions.get(kindId);
        if (!kind) {
          this.permissions.set(kindId, new Map());
          kind = this.permissions.get(kindId);
        }
        const toolName = `${domain}.${name}`;
        kind.set(toolName, permsSet);
      });
    }
  }

  /**
   * Авторизовать пользователя на стороне сервиса
   *
   * @param {String} login логин пользователя
   * @param {String} password пароль пользователя
   * @param {Boolean} restore восстановить ли рабочее пространство пользователя после входа
   */
  async performLogin(login, password, restore) {
    this.setPending(true);
    try {
      try {
        const authData = await this.api.login(login, password);
        this.setAuthData(authData, login);
      } catch (e) {
        switch (e.status) {
          case 400:
            this.rootStore.onError(e, false);
            break;
          case 401:
            this.setError("Неверный логин или пароль", e.status);
            break;
          case 404:
            this.rootStore.onError(
              "Сервис авторизации недоступен. Попробуйте авторизоавтьcя позже.",
              false, e.status
            );
            break;
          case 408:
            this.rootStore.onError(
              "Время ожидания запроса истекло. Проверьте настройки подключения и повторите запрос позже.", 
              false, 408);
            break;
          default:
            this.rootStore.onError(e, false);
        }
        return;
      }

      await this.initUser(this.uid, restore);
    } finally {
      this.setPending(false);
    }
  }

  /**
   * Установить текст и статус ошибки для окна ввода логина и пароля
   * @param {String} text текст ошибки
   * @param {Number} status статус ошибки
   * @returns {object} errorObj - объект данных ошибки
   */
  @action
  setError(text, status) {
    this.errorObj = { text, status };
  }
  /**
   * Деавторизовать пользователя на стороне сервиса
   *
   */
  async performLogout() {
    this.setPending(true);
    try {
      await this.api.logout();
      // удаляем все объекты из глобального хранилища. Для другого пользователя могут загрузиться
      // свои редакции
      this.rootStore.objectStore.clear();
      this.rootStore.wsStore.disconnect();
      this.clearUserData();
    } catch (error) {
      this.onError(error);
    } finally {
      this.setPending(false);
    }
  }

  /**
   * Произвести инициализацию окружения после авторизации пользователя
   *
   * @param{String} login логин пользователя. Необходим для загрузки `permissions`
   * @param{Boolean} restore восстановить ли рабочее пространство пользователя после входа
   */
  async afterLogin(restore) {
    try {
      const permissions = await this.api.loadPermissions(this.uid);
      this.updatePermissions(permissions);
      this.rootStore.uiStore.loadModulesConfig();
      this.rootStore.wsStore.init(this.token);
    } catch (error) {
      if (error.name === 401) {
        this.onError(`${error.message} Выполните вход повторно.`);
        this.clearUserData();
      } else {
        this.onError(
          `${error.message} Восстановление рабочего пространства невозможно.`,
          true
        );
      }
      return;
    }

    await this.rootStore.configStore.init();
    await this.rootStore.kindsStore.init(restore);
    await this.rootStore.relationStore.getKinds();
    await this.rootStore.workflowStore.getMachines();
    await this.rootStore.userStore.init();
    await this.rootStore.aboutStore.init();
    
    await this.restorePresset(restore);
    await this.loadWorkspaces();
    await this.loadPictograms();
  }

  @action
  loadWSbyId(id) {
    const ws = this.workspaces.get(id);
    if (ws) {
      ws.loadToLayout();
    }
  }
  /**
   * Загрузить последнее сохраненное рабочее пространство пользователя
   */
  @action
  async restorePresset(restore) {
    if (!restore) {
      this.setPresset(false);
      return;
    }
    this.setLoadingPresset(true);
    try {
      const data = await this.api.loadPresset(this.uid);
      const presset = JSON.parse(data.layout);
      if (
        presset.items &&
        Object.keys(presset.items).length === presset.targets.length
      ) {
        this.setPresset(presset);
      } else {
        this.setPresset(false);
      }
    } catch (error) {
      this.setPresset(false);
      console.warn("no layout saves");
    } finally {
      this.setLoadingPresset(false);
    }
  }

  /**
   * Загрузить рабочии пространства
   */
  @action
  async loadWorkspaces() {
    if (!this.isPendingWorkspaces) {
      this.setPendingWorkspaces(true);
      try {
        const workspaces = await this.api.loadUserWorkspaces(this.uid);
        
        workspaces.forEach((ws) => {
          this.addWorkspace(ws);
        });
      } catch (e) {
        console.warn(e);
        this.onError(e);
      }
      this.setPendingWorkspaces(false);
    }
  }

  /**
   * Загрузить пиктограммы объектов
   */
  async loadPictograms() {
    try {
      const data = await this.api.loadPictograms();
      this.setUserIcons(data);
    } catch (e) {
      this.onError(e);
    }
  }

  /**
   * Получить название иконки для объекта
   *
   * @param {Object} item объект в АИСППП
   * @return {String}
   */
  getIconString(item) {
    const iconByUid = this.getIcon(item.uid);
    if (iconByUid) {
      return iconByUid;
    }

    const kindItem = this.rootStore.objectStore.getRepresentation(
      item.uid,
      DOMAIN_KINDS
    );
    if (kindItem || item.membersKind) {
      let kindsArray = [];
      if (item.membersKind) {
        kindsArray = [item.kindUids];
      } else {
        kindsArray = kindItem.kindUids;
      }
      const iconStrings = [];
      kindsArray.forEach((kind) => {
        const str = this.getIcon(kind);
        if (str) {
          iconStrings.push(str);
        }
      });
      if (iconStrings.length > 0) {
        return iconStrings[iconStrings.length - 1];
      } else if (kindsArray.length > 0) {
        return "token-M";
      }
    }

    return null;
  }

  /**
   * Получить название иконки  по uid Вида
   *
   * @param {String} kindUid uid Dида
   * @return {String}
   */
  getIcon(kindUid) {
    if (!kindUid) {
      return undefined;
    }
    return this.iconMap.get(kindUid);
  }

  /**
   * Задать загруженный набор пиктограмм
   *
   * @param {Object} data набор значений key:pictogramName
   */
  @action
  async setUserIcons(data = {}) {
    Object.keys(data).forEach((key) => {
      this.iconMap.set(key, data[key]);
    });
  }

  /**
   * Задать рабочее пространство
   * @param {Workspace} ws рабочее пространство
   */
  @action
  addWorkspace(ws) {
    this.workspaces.set(ws.uid, new Workspace(ws, this));
  }

  @action
  showForm(id, getPresset) {
    this.getPresset = getPresset;
    if (id) {
      this.editingId = id;
    } else {
      this.isCreating = true;
    }
  }

  /**
   * Сохранить рабочее пространство
   * @param {String} name навзание рабочего пространства
   * @param {String} description описание рабочего пространства
   */
  @action
  async saveWorkspace(name, description) {
    if (!this.isCreating) {
      this.editingItem.change({ name, description });
    } else {
      const layout = this.getPresset;
      const newWS = await this.api.createWorkspace({
        layout,
        name,
        description
      });
      this.addWorkspace(newWS);
    }
    this.hideForm();
  }

  /**
   * Удалить рабочее пространство
   * @param {String} uid uid рабочего пространства
   */
  @action
  async deleteWorkspace(uid) {
    await this.api.deleteWorkspace({ uid });
    this.workspaces.delete(uid);
  }

  @action
  saveWorkspaceToItem(id, getPresset) {
    this.getPresset = getPresset;
    const ws = this.workspaces.get(id);
    if (ws) {
      const layout = this.getPresset;
      ws.change({ layout });
    }
  }

  @action
  setWSFunc(func) {
    this.setWS = func;
  }

  @action
  async setWorkspace(layout) {
    this.setLoadingPresset(true);
    setTimeout(() => { // hack to make changes work on layout
      this.setPresset(layout);
      this.setWS && this.setWS(layout);
      this.setLoadingPresset(false);
    }, 0);
  }

  @action
  hideForm() {
    this.editingId = undefined;
    this.isCreating = undefined;
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  setAutoLoginPending(pending = false) {
    this.autoLoginPending = pending;
  }

  @action
  setPresset(value) {
    this.presset = value;
  }

  @action
  setPendingWorkspaces(value = false) {
    this.pendingWorkspaces = value;
  }

  @action
  setLoadingPresset(value) {
    this.loadingPresset = value;
  }

  @computed
  get isPending() {
    return this.pending || this.rootStore.kindsStore.isPending;
  }

  @computed
  get isPendingWorkspaces() {
    return this.pendingWorkspaces;
  }

  @computed
  get isLoggedIn() {
    return (
      this.uid && this.token && this.presset !== undefined && !this.isPending
    );
  }

  async savePresset(data) {
    this.api.setPresset(this.uid, data);
  }

  @computed
  get layoutPresset() {
    let presset = undefined;
    if (this.uid) {
      if (this.presset && this.presset.blocks && [...Object.keys(this.presset.blocks)].length > 0) {
        presset = toJS(this.presset); // localStorage[this.uid];
      } else {
        presset = {
          blocks: {
            root: {
              isRoot:    true,
              id:        "root",
              direction: "row",
              width:     false,
              height:    false,
              childIds:  [],
              panelId:   "base-panel"
            }
          },
          panels: {
            "base-panel": {
              id:      "base-panel",
              blockId: "root",
              items:   [
                "base-item"
              ]
            }
          },
          overlayPanels: {},
          items:         {
            "base-item": {
              properties: {
                layoutStore: null
              },
              id:       "base-item",
              uid:      null,
              panelId:  "base-panel",
              toolId:   "library",
              name:     "Библиотека",
              subItems: {}
            }
          },
          targets: [
            "base-item"
          ],
          persistId: this.uid
        };
      }
    }
    return presset;
  }

  @computed
  get workspacesArray() {
    const arr = [];
    if (!this.pendingWorkspaces) {
      this.workspaces.forEach((ws) => {
        arr.push(ws);
      });
    }
    return arr;
  }

  @computed
  get formIsVisible() {
    return !!this.editingId || !!this.isCreating;
  }

  @computed
  get editingItem() {
    return this.workspaces.get(this.editingId);
  }

  @computed
  get editingName() {
    return this.editingItem ? this.editingItem.name : "";
  }

  @computed
  get isAutoLoginPending() {
    return this.autoLoginPending;
  }

  @computed
  get editingDescription() {
    return this.editingItem ? this.editingItem.description : "";
  }

  /**
   * Получить настройки уведомлений для пользователя 
   * 
   * @param {String} userUid  uid пользователя
   * 
   * @returns {Boolean} Признак включенности оповещения
   */
  @action
  async checkNotifications(userUid) {
    let notificationIsEnabled = false;
    try {
      this.emailsMap.clear();
      let data = await this.api.getNotificationsSettings(userUid);
      notificationIsEnabled = data.enabled;
      data = await this.api.getNotificationEmails(userUid);
      Array.isArray(data) && data.forEach((e) => {
        this.addEmail(Email.create(e));
      });
    } catch (ex) {
      this.onError(ex.message);
    }

    return notificationIsEnabled;
  }

  /**
   * Обновить настройки уведомлений для пользователя 
   * 
   * @param {String} userUid  uid пользователя
   * @param {Object} data  настройки уведомлений для пользователя 
   * @param {Boolean} data.enabled  Признак включенности оповещения
   * 
   * @returns {Boolean} Признак выолнения запроса
   */
  @action
  async updateNotifications(userUid, data) {
    let b = false;
    try {
      await this.api.setNotificationsSettings(userUid, data);
      b = true;
    } catch (ex) {
      this.onError(ex.message);
    }

    return b;
  }

  /**
   * Удалить email из списка уведомлений для пользователя 
   * 
   * @param {String} emailUid  uid email
   * 
   * @returns {Boolean}
   */
  @action
  async deleteNotificationEmail(emailUid) {
    try {
      await this.api.deleteNotificationEmail(emailUid);
      this.emailsMap.delete(emailUid);
    } catch (ex) {
      this.onError(ex.message);
      return false;
    }

    return true;
  }

  /**
   * Добавить новую запись email в список уведомлений для пользователя 
   * 
   * @param {String} userUid  uid пользователя
   * @param {String} address  адрес нового email
   * @param {Boolean} isPrimary
   * 
   * @returns {Email}
   */
  @action
  async addNotificationEmail(userUid, address, isEnabled = true) {
    try {
      const data = await this.api.addNotificationEmail(userUid, address, isEnabled);
      const email = Email.create(data);
      this.addEmail(email);
      return email;
    } catch (ex) {
      this.onError(ex.message);
    }

    return null;
  }

  /**
   * Обновить запись email в список уведомлений для пользователя 
   * 
   * @param {String} emailUid uid email записи в списке адресов
   * @param {String} address  адрес нового email
   * @param {Boolean} isEnabled признак доступности адреса для отправки
   * 
   * @returns {Email}
   */
  @action
  async updateNotificationEmail(emailUid, address, isEnabled) {
    try {
      const data = await this.api.updateNotificationEmail(emailUid, address, isEnabled);
      const email = this.getEmailByUid(emailUid);
      if (email) {
        email.update(data);
      }
      return email;
    } catch (ex) {
      this.onError(ex.message);
    }

    return null;
  }

  /**
   * Добавить email в список 
   * 
   * @param {Email} email
   */
  @action
  addEmail(email) {
    this.emailsMap.set(email.uid, email);
  }

  /**
   * Получить запись email по uid
   * 
   * @param {String} uid записи email
   * 
   * @returns {Email} email
   */
  getEmailByUid(uid) {
    return this.emailsMap.get(uid);
  }

  /**
   * Массив записей email
   * 
   * @returns {Array<Email>}
   */
  @computed
  get emails() {
    return Array.from(this.emailsMap.values());
  }

  /**
   * Обработчик UI ошибок
   * 
   * @param {String} err текст ошибки
   */
  onError(err) {
    this.rootStore.onError(err);
  }

  /**
   * Очистить данные пользователя
   */
  @action
  clearUserData() {
    this.uid = undefined;
    this.token = undefined;
    this.user = undefined;
    this.presset = undefined;
    this.permissions.clear();
    this.clearAccountData();
    this.emailsMap.clear();
    this.rootStore.uiStore.clearModulesConfig();
  }

  destroy() {
    this.clearUserData();
  }
}

export default AccountStore;
