import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import UserItem from "../user/UserItem";
import IssueStore from "../../stores/IssueStore";

/**
 * Компонет для отображения заголовка записи журнала изменений
 * 
 * @param {Object} props набор параметров
 * @param {Object} params.journalItem запись журнала изменений
 * @param {String} params.className пользовательский className
 * @param {IssueStore} params.store хранилище для работы с задачами
 */
const Header = ({ journalItem, className, store }) => {
  const { user, createdOn } = journalItem;
  return (
    <div className={`journal-item-header ${className ? className : ""}`}>
      <div className="journal-item-header-body">
        <UserItem 
          userId={user.id}
          className="journal-item-header-user"
          store={store}
          name={user.shortName}
        />
        <div className="delimiter-line" />
        <div className="date-time">
          {createdOn && moment(createdOn).format("DD MMMM YYYY, HH:mm")}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  journalItem: PropTypes.object.isRequired, 
  className:   PropTypes.string, 
  store:       PropTypes.instanceOf(IssueStore).isRequired
};

export default Header;
