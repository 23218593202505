const SIDEPANEL_RELATIONS =  "relations-sidepanel";
const SIDEPANEL_KINDS_ATTRS =  "kinds-attrs-sidepanel";
const SIDEPANEL_JOURNAL =  "journal-sidepanel";
const SIDEPANEL_VALIDATION =  "validation-sidepanel";
const SIDEPANEL_WORKFLOW = "workflow-sidepanel";
const SIDEPANEL_QA_REQ = "qa-req-sidepanel";
const SIDEPANEL_ISSUES = "issues-sidepanel";

export {
  SIDEPANEL_RELATIONS,
  SIDEPANEL_KINDS_ATTRS,
  SIDEPANEL_JOURNAL,
  SIDEPANEL_VALIDATION,
  SIDEPANEL_WORKFLOW,
  SIDEPANEL_QA_REQ,
  SIDEPANEL_ISSUES
};
