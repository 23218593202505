import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import File from "~/modules/viewer/components/File";

/**
 * Компонента автарки пользователя
 *
 * @class UserAvatar
 */
const UserAvatar = observer(({ user, userStore, image, onUploadAvatar, isRequired }) => {
  const inputImgRef = useRef();
  const [loaded, setLoaded] = useState(null);
  const onLoad = useCallback((data) => {
    setLoaded(data);
  });

  useEffect(() => {
    setLoaded(null);
  }, [image]);


  const onBrowseImg = useCallback(() => {
    inputImgRef.current.value = null;
    inputImgRef.current.click();
  }, []);

  const onSelectImage = useCallback(async(e) => {
    const file = e.target.files[0];

    const [mime] = file.type.split("/");
    if (mime === "image") {
      const src = await userStore.uploadAvatar(user.uid, file);
      if (src) {
        onUploadAvatar(src);
      }
    } else {
      userStore.onError("Выбранный файл не является рисунком!");
    }
  }, [onUploadAvatar]);

  return (
    <div
      className={"user-form-photo"}
    >
      <div
        className={classNames("user-form-photo-img-wrapper", {
          required: isRequired && !image,
          empty:    !image
        })}
      > 
        {image &&
          <File
            file={image}
            type={"image"}
            className={"user-form-photo-img"}
            onLoad={onLoad}
            loaded={loaded}
          />
        }
      </div>
      <React.Fragment>
        <input
          type="file"
          name="logo"
          accept="image/*"
          ref={inputImgRef}
          onChange={onSelectImage}
          className="user-form-photo-input"
        />
        <a
          className={"user-form-photo-browse"}
          href="#"
          onClick={onBrowseImg}
        >
          Изменить фото
        </a>
      </React.Fragment>
    </div>
  );
});

export default UserAvatar;
