import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Notification } from "@ais3p/ui-framework";
import WizardTool from "~/modules/wizard2";
import iconRender from "~/core/utils/IconRender";
import useStores from "~/core/utils/useStores";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { CLS_LIBRARY_COLLECTION } from "~/core/constants/Classes";

const CoordLetterWizard = observer(({ store, templateUid, uids, onCancel, onDone }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState(null);
  const { layoutStore } = useStores();
  const onCancelFn = useCallback(() => {
    onCancel && onCancel();
  }, [onCancel]);

  useEffect(async() => {
    // при каждом вызове визарда, необходимо каждый раз запрашивать актуальное состояние шаблона,
    // в частности последний сквозной номер шаблона 
    if (templateUid) {
      setIsLoading(true);
      try {
        const templ = await store.loadCoordLetterTemplate(templateUid);
        // задаю список uid выбранных задач
        templ.setIssueUids(uids);
        setTemplate(templ);
      } finally {
        setIsLoading(false);
      }
    } else {
      store.onError("Не задан uid шаблона для формирования КП"); 
    }
  }, [templateUid, store.trackedItem && store.trackedItem.uid, uids?.length]);

  const onDoneFn = useCallback(async(template) => {
    if (!template) {
      store.onError("Не задан template для формирования КП");
      return;
    }
    setIsLoading(true);
    try {
      const libraryNodes = await store.createCoordLetter(template);
      if (libraryNodes) {
        Notification.success(`Создано КП с номером "${template.coordLetterTitle}".`, { autoClose: 2000 });
        // ищем ноду коллекции Библиотеки
        const itemCollection = libraryNodes.filter((item) => {
          return item.class === CLS_LIBRARY_COLLECTION;
        })[0]; 
        if (itemCollection) {
          // и открываем эту коллекцию Библиотеки
          openLibraryCollection(itemCollection);  
        }
        onDone && onDone(template);
      }
    } finally {
      setIsLoading(false);
    }
  }, [onDone]);

  const openLibraryCollection = (item) => {
    const icon = iconRender(item, true);
    layoutStore.open({
      name:      item.title,
      id:        item.uid,
      icon:      icon || "app-library-M",
      component: "library",
      props:     {
        id: item.uid
      }
    });
  };

  return (
    <WizardTool
      icon="chapter-M"
      title={"Создать КП"}
      isLoading={isLoading}
      className="coord-letter-wizard"
      onCancel={onCancelFn}
      onDone={onDoneFn}
    >
      <Step1 store={store} template={template} />
      <Step2 store={store} />
    </WizardTool>
  );
});


export default CoordLetterWizard;
