import React from "react";
import { Components } from "@ais3p/ui-framework";

const Logs = () => {
  return (
    <div className="user-logs">
      <Components.Button
        icon="warning-M"
        color="negative"
        text="Сформировать js ошибку"
        onPress={true}
      />
    </div>
  );
};

export default Logs;




