import { action, computed, observable } from "mobx";
import { getClassTitle } from "~/core/constants/Classes";
/**
 * Базовый  объект
 *
 * @type {BaseObject}
 */
class RestrictionsForRole {
  @observable role = null;
  @observable configStore = null;
  @observable restrictionsForObject = null;
  @observable restrictionsForTool = null;
  @observable subjectByCriterionMap = new Map();

  constructor(role, parent, store) {
    this.role = `${role}`;
    this.restrictionsForObject = parent;
    this.restrictionsForTool = this.restrictionsForObject.restrictionsForTool;
    this.configStore = store;
  }

  @action
  addItem({ role, subjectCriterion, subjectCriterionValue }) {
    if (`${role}` !== this.role) {
      throw new Error("Переданы ограничения для другой роли");
    }
    let subjectCriterionArray = this.subjectByCriterionMap.get(
      subjectCriterion
    );
    if (!subjectCriterionArray) {
      this.subjectByCriterionMap.set(subjectCriterion, []);
      subjectCriterionArray = this.subjectByCriterionMap.get(subjectCriterion);
    }
    subjectCriterionArray.push(subjectCriterionValue);
  }

  @computed
  get classByKind() {
    const classByKind = {};
    this.subjectByCriterionMap.forEach((subjectArray, criterion) => {
      if (criterion === "kind") {
        const classString = this.restrictionsForObject.value;
        subjectArray.forEach((subject) => {
          classByKind[subject] = classString;
        });
      }
    });
    return classByKind;
  }

  @computed
  get config() {
    let config = [];
    this.subjectByCriterionMap.forEach((subjectArray, criterion) => {
      subjectArray.forEach((subject) => {
        let object = null;
        switch (criterion) {
          case "class":{
            const className = subject;
            const name = getClassTitle(className);
            if (name) {
              object = { as: className, name };
            } else {
              console.warn(`No name in configuration for class: ${className}`);
            }
            break;
          }
          case "kind":{
            const kindUid = subject;
            const classString = this.restrictionsForTool.classByKind[kindUid];
            if (classString) {
              object = { as: classString, kind: kindUid };
            } else {
              console.warn(`No class in configuration for kind: ${kindUid}`);
            }
            break;
          }

          case "link":{
            let direction;
            const link = this.restrictionsForTool.linkConfig[subject];
            if (link) {
              Array.isArray(link.from) && link.from.forEach((item) => {
                if (item.kind === this.restrictionsForObject.value) {
                  direction = "from";
                }
              });
              Array.isArray(link.to) && link.to.forEach((item) => {
                if (item.kind === this.restrictionsForObject.value) {
                  direction = "to";
                }
              });
            }

            config = {
              relationKindUid: subject,
              direction
            };  
            break;
          }
        }
        
        if (object) {
          config.push(object);
        }
      });
    });
    return config;
  }
}

export default RestrictionsForRole;
