import { observable, computed, action } from "mobx";
import {
  IssueModel,
  StatusModel,
  PriorityModel,
  AttachmentModel,
  TrackerModel,
  UserModel as RedmineUserModel,
  ProjectModel as RedmineProjectModel,
  CoordTemplateModel
} from "../models";

import { ISSUES_VIEW_TABLE } from "../constants/issuesViews";
import IssueApi from "../api/issueApi";
import moment from "moment";

import { MODULE_NAME_CFG, FILTERS_CFG } from "../constants/config";
import { DOMAIN_LIBRARY } from "../../../core/constants/Domains";
import { STATUS_CLOSED, STATUS_OPEN, statusTitle } from "../constants/statuses";
import logger from "~/core/logger";
import { processAisObject } from "~/core/utils/aisHelpers";

/**
 * Хранилище для работы с задачами
 *
 * @class IssueStore
 */
class IssueStore {
  /**
   * Cписок задач
   *
   * @type {Map <IssueModel>}
   */
  @observable
  issues = new Map(); //

  /**
   * Cписок открытых задач в кладках
   *
   * @type {Map <IssueModel>}
   */
  @observable
  // openedIssues = new Map(); // убрал map, тк начинаются проблемы когда создается новая задача без uid.
  // Когда задача создается, то уже появлется uid и нужно тогда обновлять map. Пока сделал на array
  openedIssues = []; // список открытых задач

  /**
   * Cписок приоритетов у задачи
   *
   * @type {Map <PriorityModel>}
   */
  @observable
  priorities = new Map();

  /**
   * Cписок стутусов задачи
   *
   * @type {Map <StatusModel>}
   */
  @observable
  statuses = new Map();

  /**
   * Cписок трекеров задачи
   *
   * @type {Map <TrackerModel>}
   */
  @observable
  trackers = new Map();

  /**
   * Cписок пользователей Redmine
   *
   * @type {Map <RemineUserModel>}
   */
  @observable
  users = new Map();

  /**
   * Cписок доступных проектов из Redmine
   *
   * @type {Map <RedmineProjectModel>}
   */
  @observable
  projects = new Map();

  /**
   * Флаг, указывающий, что идет обработка/загрузка данных
   *
   * @type {Boolean}
   */
  @observable
  processing = false;

  /**
   * Флаг, указывающий, что идет инициалиация хранилища - загруза небходимых данных
   *
   * @type {Boolean}
   */
  @observable
  isInitialization = false;

  /**
   * uid текущего проекта
   *
   * @type {String}
   */
  @observable
  currentProjectUid = undefined;

  /**
   * Режим только чтение
   *
   * @type {Boolean}
   */
  @observable
  readOnly = true;

  /**
   * Отображать ли панель фильтров
   *
   * @type {Boolean}
   */
  @observable
  isShownFilters = false;

  /**
   * Отображать ли панель истории
   *
   * @type {Boolean}
   */
  @observable
  isShownJournal = false;

  /**
   * Отображать ли панель со вкладками задач на все пространство
   *
   * @type {Boolean}
   */
  @observable
  isShowIssueTabsOnFullView = false;

  /**
   * Активная задача, с которой сейчас работает пользователь
   *
   * @type {IssueModel}
   */
  @observable
  activeIssue = undefined;

  /**
   * Режим отображения списка задач - Канбан (ISSUES_VIEW_KANBAN) или Таблица (ISSUES_VIEW_TABLE)
   *
   * @type {IssueModel}
   */
  @observable
  issuesViewMode = ISSUES_VIEW_TABLE;

  /**
   * Кол-во записей на странице в табличном представлением
   *
   * @type {Number}
   */
  @observable
  pageSize = 20;

  /**
   * Номер текущей страницы в табличном представлением
   *
   * @type {Number}
   */
  @observable
  currentPage = 1;

  /**
   * Общее кол-во записей
   *
   * @type {Number}
   */
  @observable
  totalIssues = 0;

  /**
   * Признак, что фильтр был применен
   *
   * @type {Boolean}
   */
  @observable
  isFilterApplied = false;

  /**
   * Карта uid выбранных задач
   *
   * @type {Map <String>}
   */
  @observable
  selectedUidsIssueMap = new Map();

  @observable
  trackedItem = undefined;

  /**
   * Последний уставновленый набор фильтров, по которому загружали список задач
   * @type {Object}
   */
  @observable
  lastFilters = {};

  /**
   * Набор uid выбранных задач в подборке
   * @type {Set<String>}
   */
  @observable
  cartSet = new Set();

  constructor({ rootStore }) {
    this.rootStore = rootStore;
    this.api = new IssueApi(rootStore);
    // this.isShownJournal = this.getItemConfig(JOURNAL_CFG)?.isShownJournal;
    this.isShownFilters = this.getItemConfig(FILTERS_CFG)?.isShownFilters;
  }

  /**
   * Список задач
   *
   * @return {Array<IssueModel>}
   */
  @computed
  get issueList() {
    return Array.from(this.issues.values());
  }

  /**
   * Список открытых задач
   *
   * @return {Array<IssueModel>}
   */
  @computed
  get openedIssueList() {
    // return Array.from(this.openedIssues.values());
    return this.openedIssues;
  }

  @computed
  get issueListGroupedByStatus() {
    const result = {};
    this.statusList.forEach((status) => {
      result[status.id] = this.issueList
        .filter((issue) => {
          return issue.statusId === status.id;
        })
        .sort((a, b) => {
          return a.order - b.order;
        });
    });

    return result;
  }

  /**
   * Список приоритетов у задачи
   *
   * @return {Array<PriorityModel>}
   */
  @computed
  get priorityList() {
    return Array.from(this.priorities.values());
  }

  /**
   * Приоритет задачи по умолчанию
   *
   * @return {PriorityModel}
   */
  @computed
  get defaultPriority() {
    return this.priorityList.filter((pr) => {
      return pr.isDefault;
    })[0];
  }

  /**
   * Список статусов задачи
   *
   * @return {Array<StatusModel>}
   */
  @computed
  get statusList() {
    return Array.from(this.statuses.values());
  }

  /**
   * Список трекеров задачи
   *
   * @return {Array<TrackerModel>}
   */
  @computed
  get trackerList() {
    return Array.from(this.trackers.values());
  }

  /**
   * Список пользователей Redmine
   *
   * @return {Array<RedmineUserModel>}
   */
  @computed
  get userList() {
    return Array.from(this.users.values());
  }

  /**
   * Список пользователей Redmine, у которых есть связь с пользователями в АИС
   *
   * @return {Array<RedmineUserModel>}
   */
  @computed
  get linkedUserList() {
    return this.userList.filter((user) => {
      return user.isLinked;
    });
  }

  /**
   * Список проектов Redmine
   *
   * @return {Array<RedmineProjectModel>}
   */
  @computed
  get projectList() {
    return Array.from(this.projects.values());
  }

  /**
   * Список проектов Redmine в виде options для выпадающего списка  Select,
   * cгруппированные по интснас и родительским проектам
   * 
   *
   * @return {Array<Option>}
   */
  @computed
  get projectOptions() {
    const instances = {};
    const calcLevel = (project, instUid) => {
      if (!project?.parent) {
        return 0;
      }
      let parentId = project.parent?.id;
      let level = 1;
      while (parentId) {
        const pr = this.getProjectById(parentId, instUid);
        parentId = pr?.parent?.id;
        if (parentId) {
          level += 1;
        }
      }

      return level;
    };

    this.projectList.forEach((pr) => {
      if (pr.available) {
        if (!instances[pr.instance.uid]) {
          instances[pr.instance?.uid] = {
            label:    pr.instance.name,
            projects: {}
          };
        }
        if (pr.parent) {
          if (!instances[pr.instance.uid].projects[pr.parent.id]) {
            instances[pr.instance.uid].projects[pr.parent.id] = {
              options: []
            };
          }
          instances[pr.instance.uid].projects[pr.parent.id].options.push({
            label:   pr.title,
            value:   pr.uid,
            group:   pr.parent.id,
            icon:    pr.icon,
            isGroup: false,
            level:   calcLevel(pr, pr.instance.uid)
          });
        } else {
          if (!instances[pr.instance.uid].projects[pr.id]) {
            instances[pr.instance?.uid].projects[pr.id] = {
              options: [{
                label:   pr.title,
                value:   pr.uid,
                icon:    pr.icon,
                isGroup: true,
                level:   0
              }]
            };
          } else {
            instances[pr.instance.uid].projects[pr.id].options.unshift({
              label:   pr.title,
              value:   pr.uid,
              icon:    pr.icon,
              isGroup: true,
              level:   0
            });
          }
        }
      }
    });
    const res = [];
    for (const i in instances) {
      const inst = instances[i];
      let options = [];
      for (const p in inst.projects) {
        const pr = inst.projects[p];
        if (pr.options.length === 1) {
          pr.options[0].isGroup = false;
        }
        options = options.concat(pr.options);
      }
      res.push({
        label: inst.label,
        options  
      });
    }
    return res;
  }

  /**
   * Список проектов  в локальном Redmine
   *
   * @return {Array<RedmineProjectModel>}
   */
  @computed
  get localProjectList() {
    return this.projectList.filter((pr) => {
      return !pr.isExternal;
    });
  }

  /**
   * Задать активную адачу
   *
   * @param {IssueModel} issue модель задачи
   */
  @action
  setActiveIssue(issue) {
    this.activeIssue = issue;
  }

  /**
   * Задать режим отображения списка задач - Канбан (ISSUES_VIEW_KANBAN) или Табличное (ISSUES_VIEW_TABLE)
   *
   * @param {String} mode режим отображения ISSUES_VIEW_KANBAN | ISSUES_VIEW_TABLE
   */
  @action
  setIssuesViewMode(mode) {
    this.issuesViewMode = mode;
  }

  @action
  setTrackedItem(trackedItem) {
    this.trackedItem = trackedItem;
  }

  /**
   * Добавить задачу в список под ее uid
   *
   * @param {IssueModel} issue модель задачи
   */
  @action
  addIssue(issue) {
    this.issues.set(issue.uid, issue);
    this.rootStore.objectStore.addVersion(issue);
  }

  /**
   * Добавить задачу в список открытых под ее uid
   *
   * @param {IssueModel} issue модель задачи
   */
  @action
  addOpenedIssue(issue) {
    this.openedIssues.push(issue);
    // this.openedIssues.set(issue.uid, issue);
    // if (issue.isNew) {
    //   const newIssues = this.openedIssues.filter((i) => {
    //     return !i.uid;
    //   });
    //   if (newIssues.length === 0) { // добавляем только одну новую задачу (без uid)
    //     this.openedIssues.push(issue);
    //   } else {
    //     this.setActiveIssue(newIssues[0]);
    //   }
    // } else {
    //   this.openedIssues.push(issue);
    // }
  }

  /**
   * Удалить задачу из списка открытых
   *
   * @param {String} uid uid задачи
   */
  @action
  deleteOpenedIssue(uid) {
    // this.openedIssues.delete(uid);
    // if (this.openedIssues.size === 0) {
    //   this.isShowIssueTabsOnFullView = false;
    // }
    this.openedIssues = this.openedIssues.filter((issue) => {
      return issue.uid !== uid;
    });

    if (this.openedIssues.length === 0) {
      this.setActiveIssue(undefined);
    }
  }

  /**
   * Добавить приоритет задачи в список под его id
   *
   * @param {PriorityModel} priority модель приоритета задачи
   */
  @action
  addPriority(priority) {
    this.priorities.set(priority.id, priority);
  }

  /**
   * Добавить сатус задачи в список под ее id
   *
   * @param {Array<PriorityModel>} status модель статуса задачи
   */
  @action
  addStatus(status) {
    this.statuses.set(status.id, status);
  }

  /**
   * Добавить трекер задачи в список под ее id
   *
   * @param {PriorityModel} tracker модель трекера задачи
   */
  @action
  addTracker(tracker) {
    this.trackers.set(tracker.id, tracker);
  }

  /**
   * Добавить пользователя в Redmine в список под его id
   *
   * @param {RedmineUserModel} user модель пользователя в Redmine
   */
  @action
  addUser(user) {
    this.users.set(user.id, user);
  }

  /**
   * Добавить проект в Redmine в список под его id
   *
   * @param {RedmineProjectModel} project модель проекта в Redmine
   */
  @action
  addProject(project) {
    // добавляем проект  по uid, тк из-за разных инстансов id мб не уникальными
    this.projects.set(project.uid, project);
  }

  /**
   * Добавить uid задачи в список выделенных задач
   *
   * @param {String} uid выделенной задача
   */
  @action
  addUidSelectedIssue(uid) {
    this.selectedUidsIssueMap.set(uid, true);
  }

  /**
   * Удалить задачу из списка выделенных задач
   *
   * @param {uid} uid выделенной задачи
   */
  @action
  removeUidSelectedIssue(uid) {
    this.selectedUidsIssueMap.delete(uid);
  }

  @action
  setLastFilters(filters = {}) {
    this.lastFilters = filters;
  }

  /**
   * Выбрать все загруженные задачи
   * 
   * @param {Boolean} selectOnAllPages выбрать записи на всех страницах - true,
   * или только на текущей - false
   */
  async selectAllIssues(selectOnAllPages) {
    logger.info(`issues.IssueStore.selectAllIssues |  Выбор всех загруженнных задач:  
      selectOnAllPage="${selectOnAllPages}"`);
    if (!selectOnAllPages) {
      // выбираем записи только на текущей странице
      this.issueList.forEach((issue) => {
        this.addUidSelectedIssue(issue.uid); 
      });
    } else {
      // получаем все записи, которые удовлетворяют текущим фильтрам
      const uids = await this.loadUidsIssueByFilter(this.lastFilters);
      uids.forEach((uid) => {
        this.addUidSelectedIssue(uid); 
      });
    }
    
    return true;
  }

  /**
   * Снять выделение у всех загруженных задач
   */
  deselectAllIssues() {
    this.clearSelectedIssues();
  }

  /**
   * Изменение значения флага, указывающий, что идет обработка/загрузка данных
   *
   * @param {Boolean} value значение
   */
  @action
  setIsProcessing(value) {
    this.processing = value;
  }

  /**
   * Изменение значения флага, указывающий, что идет инициализация хранилища
   *
   * @param {Boolean} value значение
   */
  @action
  setIsInitialization(value) {
    this.isInitialization = value;
  } 

  /**
   * Флаг, указывающий, что идет обработка/загрузка данных
   *
   * @return {Boolean} 
   */
  @computed
  get isProcessing() {
    return this.processing  || this.isInitialization;
  }

  /**
   * Устанвоить uid текущего проекта
   *
   * @param {String} value значение
   */
  @action
  setCurrentProjectUid(value) {
    this.currentProjectUid = value;
  }

  /**
   * Выставить значение readOnly
   *
   * @param {Boolean} value
   */
  @action
  updateReadOnly(value) {
    this.readOnly = value;
  }

  /**
   * Переключить отображение панели с фильтрами
   */
  @action
  toggleShowFilters() {
    logger.info(`issues.IssueStore.toggleShowFilters | Переключение отображения панели с фильтрами:  
      this.isShownFilters="${this.isShownFilters}"`);
    this.isShownFilters = !this.isShownFilters;
    const cfg = this.getItemConfig(FILTERS_CFG);
    this.setItemConfig(FILTERS_CFG, {
      ...cfg,
      isShownFilters: this.isShownFilters
    });
  }

  /**
   * Скрыть отображение панели с фильтрами
   */
  @action
  hideFilters() {
    logger.info("issues.IssueStore.hideFilters | Скрытие отображения панели с фильтрами");
    this.isShownFilters = false;
    const cfg = this.getItemConfig(FILTERS_CFG);
    this.setItemConfig(FILTERS_CFG, {
      ...cfg,
      isShownFilters: false
    });
  }

  /**
   * Задать/снять признак того, что фильтр был задан
   * 
   * @param {Boolean} value значение признака
   */
  @action
  setFilterApplied(value) {
    this.isFilterApplied = value;
  }

  /**
   * Переключить отображение панели с историей изменений
   */
  @action
  toggleShowJournal() {
    logger.info(`issues.IssueStore.toggleShowFilters | Переключение отображения панели с историей изменений:  
      this.isShownJournal="${this.isShownJournal}"`);
    this.isShownJournal = !this.isShownJournal;
    // const cfg = this.getItemConfig(JOURNAL_CFG);
    // this.setItemConfig(JOURNAL_CFG, {
    //   ...cfg,
    //   isShownJournal: this.isShownJournal,
    // });
  }

  /**
   * Переключить отображение панели со вкладками задач - во весь экран или наполовину
   */
  @action
  toggleShowIssueTabs() {
    // eslint-disable-next-line max-len
    logger.info(`issues.IssueStore.toggleShowIssueTabs | Переключение отображения панели со вкладками задач - во весь экран или наполовину:  
      this.isShowIssueTabsOnFullView="${this.isShowIssueTabsOnFullView}"`);
    this.isShowIssueTabsOnFullView = !this.isShowIssueTabsOnFullView;
  }

  /**
   * Изменить статус у задачи
   *
   * @param {String} issueUid - uid задачи,у которой нужно изменить статус
   * @param {String} afterIssueUid - uid задачи, после котрой нужно разметстить перемещаемую задачу
   * @param {String} statusId - id новго статуса
   * @param {Boolean} isSendRequest - необходимо ли отправлять запрос на сервер
   */
  @action
  async changeIssueStatus(issueUid, afterIssueUid, statusId, isSendRequest) {
    logger.info(`issues.IssueStore.changeIssueStatus | Изменение статуса у Задачи:  
      issueUid="${issueUid}"; 
      afterIssueUid="${afterIssueUid}"; 
      statusId="${statusId}"; 
      isSendRequest="${isSendRequest}"`);
    const issue = this.getIssue(issueUid);
    const targetIssue = this.getIssue(afterIssueUid);
    // создаем временный список задач
    const issues = this.issueListGroupedByStatus[statusId];
    const cardIndex = issues.indexOf(issue);

    if (targetIssue) {
      const afterIndex = issues.indexOf(targetIssue);

      // формируем новый порядок задач
      issues.splice(cardIndex, 1);
      issues.splice(afterIndex, 0, issue);

      // обновляем параметр order согласно новой сортировке
      issues.forEach((item, i) => {
        // Делаем snapshot модели перед внесением изменений. Это позволит нам потом сделать откат назад
        // если изменения не пройдут на сервер. Snapshot нужно делать один раз в начале, т.к. данные
        // изменения будут происходить несколько раз при DnD.
        if (!item.snapshot) {
          item.createSnapshot();
        }

        item.update({
          statusId,
          order: i + 1
        });
      });
    } else { // Блок задачи бросили в пустую колонку
      // Делаем snapshot модели перед внесением изменений. Это позволит нам потом сделать откат назад
      // если изменения не пройдут на сервер. Snapshot нужно делать один раз в начале, т.к. данные
      // изменения будут происходить несколько раз при DnD.
      if (!issue.snapshot) {
        issue.createSnapshot();
      }
      issue.update({
        statusId,
        order: cardIndex + 1
      });
    }

    if (isSendRequest) {
      issues.splice(0, cardIndex);
      issue.setIsPending(true);
      try {
        if (issues.length > 1) {
          // сохраняем сразу пачкой order и statusId
          const data = await this.api.saveBulkIssues(issues.map((i) => {
            return {
              uid:      i.uid,
              statusId: i.statusId,
              order:    i.order
            };
          }));
          if (data && data.length > 0) {
            data.forEach((issueData) => {
              const issue = this.getIssue(issueData.uid);
              if (issue) {
                if (issue.statusId !== issueData.statusId) {
                  const status = this.getStatus(issue.statusId);
                  this.rootStore.uiStore.setErrorText(`Статус у задачи "${issue.title}" не изменился! 
                    Скорее всего, задача не может иметь статус - "${status && status.title}".`);
                }
                issue.update(issueData);
              }
            });
          }
        } else {
          // меняем только статус у задачи
          const issueData = await this.api.changeStatusIssue(issueUid, statusId);
          const issue = this.getIssue(issueData.uid);
          if (issue) {
            if (issue.statusId !== issueData.statusId) {
              const status = this.getStatus(issue.statusId);
              this.rootStore.uiStore.setErrorText(`Статус у задачи "${issue.title}" не изменился!
                Скорее всего, задача не может иметь статус - "${status && status.title}".`);
            }
            issue.update(issueData);
          }
        }
        // фиксируем изменения у моделей
        issues.forEach((issue) => {
          issue.commit();
        });
      } catch (ex) {
        // если не получилось обновить на сервере занчения, то нужно визуально все вернуть назад
        issues.forEach((issue) => {
          issue.revert();
        });
        this.onError(ex);
      } finally {
        issue.setIsPending(false);
      }
    }
  }

  /**
   * Задать кол-во записей на странице в табличном представлении
   *
   * @param {Number} size кол-во записей
   */
  @action
  setPageSize(size) {
    logger.info(`issues.IssueStore.setPageSize | Задаем кол-во записей на странице в табличном представлении:  
      size="${size}"`);
    this.pageSize = size || 20;
    this.setCurrentPage(1);
  }

  /**
   * Задать номер текущей страницы в табличном представлении
   *
   * @param {Number} page номер текущей страницы в табличном представлении
   */
  @action
  setCurrentPage(page) {
    logger.info(`issues.IssueStore.setCurrentPage | Задаем номер текущей страницы в табличном представлении:  
      page="${page}"; this.currentPage="${this.currentPage}"`);
    if (page < 1) {
      this.currentPage =  1;
      return;
    }
    this.currentPage = page || 1;
  }

  /**
   * Задать общее кол-во записей
   *
   * @param {Number} value общее кол-во записей
   */
  @action
  setTotalIssues(value = 0) {
    logger.info(`issues.IssueStore.setTotalIssues | Задаем общее кол-во записей:  
      value="${value}";`);
    this.totalIssues = value;
  }

  /**
   * Кол-во страниц
   *
   * @return {Number}
   */

  @computed
  get pages() {
    if (this.totalIssues === 0) {
      return 1;
    }
    if (this.pageSize && this.totalIssues) {
      return Math.ceil(this.totalIssues / this.pageSize);
    }

    return -1;
  }

  /**
   * Можно ли перейти на предыдущую страницу
   *
   * @return {Boolean}
   */
  @computed
  get canPreviousPage() {
    return this.currentPage > 1;
  }

  /**
   * Можно ли перейти на следуюущую страницу
   *
   * @return {Number}
   */
  @computed
  get canNextPage() {
    return this.currentPage < this.pages;
  }

  /**
   * Информация о страницах
   *
   * @return {Number}
   */
  @computed
  get pageInfo() {
    if (this.totalIssues === 0) {
      return null;
    }

    const startNumRec = ((this.currentPage - 1) * this.pageSize) + 1;
    let endNumRec = this.currentPage * this.pageSize;
    if (this.currentPage >= this.pages) {
      endNumRec =  this.totalIssues;
    }

    return (`(${startNumRec} - ${endNumRec} / ${this.totalIssues})`);
  }

  /**
   * Получить задачу по ее uid
   *
   * @param {String} uid задачи
   *
   * @return {IssueModel}
   */
  getIssue(uid) {
    return this.issues.get(uid);
  }

  /**
   * Получить приоритет задачи по его id
   *
   * @param {String} id приоритета задачи
   *
   * @return {PriorityModel}
   */
  getPriority(id) {
    return this.priorities.get(id);
  }

  
  /**
   * Получить статус задачи по ее id
   *
   * @param {String} id статуса задачи
   *
   * @return {StatusModel}
   */
  getStatus(id) {
    return this.statuses.get(id);
  }

  /**
   * Получить трекер задачи по ее id
   *
   * @param {String} id трекера задачи
   *
   * @return {TrackerModel}
   */
  getTracker(id) {
    return this.trackers.get(id);
  }

  /**
   * Получить пользователя Redmine по его id
   *
   * @param {String} id пользователя в Redmine
   *
   * @return {RedmineUserModel}
   */
  getUser(id) {
    return this.users.get(id);
  }

  /**
   * Получить пользователя по его uid
   *
   * @param {String} uid пользователя в АИС
   *
   * @return {UserModel}
   */
  getUserByUid(uid) {
    return Array.from(this.users.values()).filter((user) => {
      return user.uid === uid;
    })[0];
  }

  /**
   * Получить проект Redmine по его uid
   *
   * @param {String} uid проекта
   *
   * @return {RedmineProjectModel}
   */
  getProject(uid) {
    return this.projects.get(uid);
  }

  /**
   * Получить проект Redmine по его id
   *
   * @param {String} id проекта в Redmine
   * @param {String} uid инстанса проекта в Redmine
   *
   * @return {RedmineProjectModel}
   */
  getProjectById(id, instUid) {
    return Array.from(this.projects.values()).filter((pr) => {
      return pr.id === id && pr.instance?.uid === instUid;
    })[0];
  }

  /**
   * Получить проект Redmine по его uid
   *
   * @param {String} uid проекта в АИС
   *
   * @return {RedmineProjectModel}
   */
  getProjectByUid(uid) {
    // return Array.from(this.projects.values()).filter((pr) => {
    //   return pr.uid === uid;
    // })[0];
    return this.getProject(uid);
  }

  async init() {
    this.setIsInitialization(true);
    logger.info("issues.IssueStore.init | Инициализация хранилища");
    try {
      await this.loadLinkedProjects();
    } finally {
      this.setIsInitialization(false);
    }
  }

  /**
   * Инициализация хранилища для выбранного проекта.
   * Необходимо вывать для загузки словарей данны - статусы задач, приоритеты задач и т.п.
   * 
   * @param {String} projectUid uid проекта, для которого загружаем данные.
   * После загрузки списка проектов, по нему поймем, из какого ресурса дальше нужно и будем получать
   * данные - внешний или внутрений
   */
  async initProject(projectUid) {
    this.setIsInitialization(true);
    try {
      logger.info(`issues.IssueStore.initProject | Инициализация хранилища для выбранного проекта:
        projectUid="${projectUid}"`);
      this.clearProjectData();
      const project = this.getProjectByUid(projectUid);
      let instanceUid;
      if (project && project.isExternal) {
        // если проект из внешнего ресурса, то нужно для него загрузить набор трекеров из внешнего ресурса
        instanceUid = project.instance?.uid;
      }
      if (projectUid) {
        await this.rootStore.kindsStore.getMemberItem(projectUid); // получаем информацию об участнике вида
      }
      await this.loadPriorities(instanceUid);
      await this.loadStatuses(instanceUid);
      await this.loadTrackers(instanceUid);
      await this.loadUsers(instanceUid);
    } finally {
      this.setIsInitialization(false);
    }
  }

  /**
   * Загрузить список приоритетов задачи
   *
   * @param {String} instanceUid uid внешнего инстнаса. Если null, то значит внутренний ресурс
   * 
   * @return {Boolean} результат загрузки true | false
   */
  async loadPriorities(instanceUid) {
    try {
      logger.info(`issues.IssueStore.loadPriorities | Загрузка списка приоритетов задачи:
        instanceUid="${instanceUid}"`);
      const data = await this.api.loadPriorities(instanceUid);
      (data || []).forEach((item) => {
        this.addPriority(PriorityModel.create(item, this));
      });
    } catch (e) {
      this.onError(e);
      return false;
    }

    return true;
  }

  /**
   * Загрузить список статусов задачи
   *
   * @param {String} instanceUid uid внешнего инстнаса. Если null, то значит внутренний ресурс
   * 
   * @return {Boolean} результат загрузки true | false
   */
  async loadStatuses(instanceUid) {
    try {
      logger.info(`issues.IssueStore.loadStatuses | Загрузка списка статусов задачи:
        instanceUid="${instanceUid}"`);
      const data = await this.api.loadStatuses(instanceUid);
      (data || []).forEach((item) => {
        this.addStatus(StatusModel.create(item, this));
      });
    } catch (e) {
      this.onError(e);
      return false;
    }

    return true;
  }

  /**
   * Загрузить список трекеров задачи
   * @param {String} instanceUid uid внешнего инстнаса. Если null, то значит внутренний ресурс
   * @return {Boolean} результат загрузки true | false
   */
  async loadTrackers(instanceUid) {
    try {
      logger.info(`issues.IssueStore.loadTrackers | Загрузка списка трекеров задачи:
        instanceUid="${instanceUid}"`);
      const data = await this.api.loadTrackers(instanceUid);
      (data && data.linked || []).forEach((item) => {
        this.addTracker(TrackerModel.create(item, this));
      });
      // if (instanceUid) {
      // обрабатываем еще и несвязанные трекеры из внешнего источника
      (data && data.nonLinked || []).forEach((item) => {
        this.addTracker(TrackerModel.create(item, this));
      });
      // }
    } catch (e) {
      this.onError(e);
      return false;
    }

    return true;
  }

  /**
   * Загрузить список пользователей Redmine
   *
   * @param {String} instanceUid uid внешнего инстнаса. Если null, то значит внутренний ресурс
   * @return {Boolean} результат загрузки true | false
   */
  async loadUsers(instanceUid) {
    try {
      logger.info(`issues.IssueStore.loadUsers | Загрузка списка пользователей Redmine:
        instanceUid="${instanceUid}"`);
      const data = await this.api.loadUsers(instanceUid);
      (data || []).forEach((item) => {
        this.addUser(RedmineUserModel.create(item, this));
      });
    } catch (e) {
      this.onError(e);
      return false;
    }

    return true;
  }

  /**
   * Загрузить список прикрепленных проектов Redmine
   *
   * @return {Boolean} результат загрузки true | false
   */
  async loadLinkedProjects() {
    try {
      logger.info("issues.IssueStore.loadLinkedProjects | Загрузка списка прикрепленных проектов Redmine");
      const data = await this.api.loadRedmineProjects();
      const uids = [];
      (data.linked || []).forEach((item) => {
        const pr = RedmineProjectModel.create(item, this);
        if (pr.available) {
          // TODO: Пока отображаем только доступные проекты (инстанс доступен) - T6387.
          // Сейчас если инстанс недоступен, то и название проекта в Redmine не возвращается.
          // Поэтому, как только появится возможность получать представления вида по uid "пачкой" - T6380, 
          // то далее будем отображать, что проект недоступен и пользователь не сможет его выбирать.
          this.addProject(pr);
          pr.uid && uids.push(pr.uid);
        }
      });
      if (uids.length > 0) {
        logger.info("issues.IssueStore.loadLinkedProjects | Загрузка представителей Видов для проектов");
        const members = await this.api.loadMembers(uids);
        const modifiedMembers = [];
        if (Array.isArray(members)) {
          await Promise.all(members.map(async(member) => {
            // преобразуем значения codeValues и values в представление данных `uid:значение`
            modifiedMembers.push({
              ...member,
              codeValues: Array.isArray(member.codeValues) ? 
                member.codeValues.map((c) => {
                  return { [c.codeUid]: c.value };
                }) : [],
              values: Array.isArray(member.values) ? 
                member.values.map((v) => {
                  return { [v.attributeUid]: v.value };
                }) : []
            });
            if (member.object?.representation) {
              return await processAisObject(member.object.representation, this.rootStore.objectStore);
            }
            return null;
          }));
          this.rootStore.kindsStore.processMembers(modifiedMembers);
        }
      }
    } catch (e) {
      this.onError(e);
      return false;
    }

    return true;
  }

  /**
  * Загрузить список задач для проекта
  *
  * @params {String} projectUid uid проекта
  *
   * @return {Boolean} результат загрузки true | false
   */
  async loadIssues(projectUid) {
    this.setIsProcessing(true);
    try {
      logger.info(`issues.IssueStore.loadIssues | Загрузка списка задач для проекта:
        projectUid="${projectUid}"`);
      this.clearIssues();
      this.setCurrentProjectUid(projectUid);
      // this.setCurrentPage(1);
      const data = await this.api.loadIssues(projectUid, this.currentPage, this.pageSize);
      const { total, issues = [] } = data;
      const uids = [];
      issues.forEach((item, i) => {
        uids.push(item.uid);
        this.addIssue(IssueModel.create({ ...item, order: item.order || i + 1 }, this));
      });
      this.setTotalIssues(total);
      await this.rootStore.kindsStore.getItems(uids);
    } catch (e) {
      this.onError(e);
      return false;
    } finally {
      this.setIsProcessing(false);
    }

    return true;
  }

  /**
  * Загрузить список задач использую набор фильтров
  *
  * @params {Object} filter набор фильтров
  * @params {Array<String>} filter.project - список uid'ов проектов
  * @params {Array<String>} filter.tracker - список uid'ов трекеров
  * @params {Array<String>} filter.status - список идентификаторов статусов в Redmine
  * @params {Array<String>} filter.priority - список идентификаторов приоритетов в Redmine
  * @params {Array<String>} filter.author - список uid'ов пользователей-авторов
  * @params {Array<String>} filter.assignedTo - список uid'ов пользователей-исполнителей
  * @params {Date} filter.createDateFrom - нижняя граница даты создания задачи
  * @params {Date} filter.createDateTo - верхняя граница даты создания задачи
  * @params {Date} filter.updateDateFrom - нижняя граница даты последнего обновления задачи
  * @params {Date} filter.updateDateTo - верхняя граница даты последнего обновления задачи
  * @params {Date} filter.startDateFrom - нижняя граница даты начала исполнения задачи
  * @params {Date} filter.startDateTo - верхняя граница даты начала исполнения задачи
  * @params {Date} filter.dueDateFrom - нижняя граница даты запланированного завершения задачи
  * @params {Date} filter.dueDateTo - верхняя граница даты запланированного завершения задачи
  * @params {String} filter.subject - поисковая строка для поиска в теме (названии) задачи
  * @params {Boolean} filter.strict - флаг, должен ли быть поиск в теме (названии) задачи строгим
  *                  (при отсутствии или null поиск является нестрогим)
  *
  * @params {Boolean} withPagination запросить с пагиниацией или без список записей
  * 
  * @return {Boolean} результат загрузки true | false
  */
  async loadIssuesByFilter(filters, withPagination = true) {
    this.setIsProcessing(true);
    try {
      logger.info(`issues.IssueStore.loadIssuesByFilter | Загрузка списка задач с фильтрами:
        filters="${JSON.stringify(filters)}"; withPagination="${withPagination}"`);
      this.clearIssues();
      // this.setCurrentPage(1);

      let data = {};
      if (this.issuesViewMode === ISSUES_VIEW_TABLE && withPagination) {
        data = await this.api.loadIssuesByFilter(filters, this.currentPage, this.pageSize);
      } else {
        data = await this.api.loadIssuesByFilter(filters);
      }

      this.setLastFilters(filters);

      let isFilterApplied = false;
      Object.keys(filters).forEach((key) => {
        if (key !== "project") { // в фильтрах всегда задан текущий проект
          const val = filters[key];
          isFilterApplied = isFilterApplied  || 
            (Array.isArray(val) ? val.length > 0 : !!val);
        }
      });
      this.setFilterApplied(isFilterApplied);
      
      const { total, issues = [], perPage } = data;
      const uids = [];
      issues.forEach((item, i) => {
        uids.push(item.uid);
        this.addIssue(IssueModel.create({ ...item, order: item.order || i + 1 }, this));
      });
      this.setTotalIssues(total);
      if (perPage && this.pageSize !== perPage) {
        this.setPageSize(perPage);
      }
      await this.rootStore.kindsStore.getItems(uids);
      if (filters.project) {
        this.setCurrentProjectUid(filters.project[0]);
      } else {
        this.setCurrentProjectUid(undefined);
      }
    } catch (e) {
      this.onError(e);
      return false;
    } finally {
      this.setIsProcessing(false);
    }

    return true;
  }

  /**
  * Загрузить список задач используя набор uid-oв
  *
  * @params {Object} filter набор фильтров
  * @params {Array<String>} filter.issue - список uid'ов задач
  * 
  * @return {Array<IssueModel>} список задач
  */
  async loadIssuesByUids(filters) {
    const res = [];
    try {
      logger.info(`issues.IssueStore.loadIssuesByUids | Загрузка списка задач используя набор uid-oв:
        filters="${JSON.stringify(filters)}";`);
      const { issues } = await this.api.loadIssuesByFilter(filters);
      issues && issues.forEach((item) => {
        res.push(IssueModel.create(item, this));
      });
    } catch (e) {
      this.onError(e);
    }

    return res;
  }

  /**
  * Загрузить список задач используя набор фильтров
  *
  * @params {Object} filter набор фильтров
  * @params {Array<String>} filter.project - список uid'ов проектов
  * @params {Array<String>} filter.tracker - список uid'ов трекеров
  * @params {Array<String>} filter.status - список идентификаторов статусов в Redmine
  * @params {Array<String>} filter.priority - список идентификаторов приоритетов в Redmine
  * @params {Array<String>} filter.author - список uid'ов пользователей-авторов
  * @params {Array<String>} filter.assignedTo - список uid'ов пользователей-исполнителей
  * @params {Date} filter.createDateFrom - нижняя граница даты создания задачи
  * @params {Date} filter.createDateTo - верхняя граница даты создания задачи
  * @params {Date} filter.updateDateFrom - нижняя граница даты последнего обновления задачи
  * @params {Date} filter.updateDateTo - верхняя граница даты последнего обновления задачи
  * @params {Date} filter.startDateFrom - нижняя граница даты начала исполнения задачи
  * @params {Date} filter.startDateTo - верхняя граница даты начала исполнения задачи
  * @params {Date} filter.dueDateFrom - нижняя граница даты запланированного завершения задачи
  * @params {Date} filter.dueDateTo - верхняя граница даты запланированного завершения задачи
  * @params {String} filter.subject - поисковая строка для поиска в теме (названии) задачи
  * @params {Boolean} filter.strict - флаг, должен ли быть поиск в теме (названии) задачи строгим
  *                  (при отсутствии или null поиск является нестрогим)
  *
  * 
  * @return {Array<String>} список uid задач, которые удовлетворяют заданным фильтрам 
  */
  async loadUidsIssueByFilter(filters) {
    this.setIsProcessing(true);
    try {
      logger.info(`issues.IssueStore.loadUidsIssueByFilter | Загрузка списка задач используя набор фильтров:
        filters="${JSON.stringify(filters)}";`);
      const { issues } = await this.api.loadUidsIssueByFilter(filters);
      return issues || [];
    } catch (e) {
      this.onError(e);
      return false;
    } finally {
      this.setIsProcessing(false);
    }

    return [];
  }

  /**
  * Загрузить список всех задач без пагинации использую набор фильтров
  * Данный метод нужен, чтобы получить все задачи, согласно зданным фильтрам. 
  * В частности нужен, чтобы сделать выделение всех задач при отображении списка задач с пагианцией
  *
  * @params {Object} filter набор фильтров
  * @params {Array<String>} filter.project - список uid'ов проектов
  * @params {Array<String>} filter.tracker - список uid'ов трекеров
  * @params {Array<String>} filter.status - список идентификаторов статусов в Redmine
  * @params {Array<String>} filter.priority - список идентификаторов приоритетов в Redmine
  * @params {Array<String>} filter.author - список uid'ов пользователей-авторов
  * @params {Array<String>} filter.assignedTo - список uid'ов пользователей-исполнителей
  * @params {Date} filter.createDateFrom - нижняя граница даты создания задачи
  * @params {Date} filter.createDateTo - верхняя граница даты создания задачи
  * @params {Date} filter.updateDateFrom - нижняя граница даты последнего обновления задачи
  * @params {Date} filter.updateDateTo - верхняя граница даты последнего обновления задачи
  * @params {Date} filter.startDateFrom - нижняя граница даты начала исполнения задачи
  * @params {Date} filter.startDateTo - верхняя граница даты начала исполнения задачи
  * @params {Date} filter.dueDateFrom - нижняя граница даты запланированного завершения задачи
  * @params {Date} filter.dueDateTo - верхняя граница даты запланированного завершения задачи
  * @params {String} filter.subject - поисковая строка для поиска в теме (названии) задачи
  * @params {Boolean} filter.strict - флаг, должен ли быть поиск в теме (названии) задачи строгим
  *                  (при отсутствии или null поиск является нестрогим)
  * 
  * @return {Array<Issue>} список всех задач, соответстующих набору фильтров
  */
  async loadAllIssuesByFilters(filters) {
    const res = [];
    this.setIsProcessing(true);
    try {
      // eslint-disable-next-line max-len
      logger.info(`issues.IssueStore.loadAllIssuesByFilters | Загрузка всех задач без пагинации использую набор фильтров:
        filters="${JSON.stringify(filters)}";`);
      const data = await this.api.loadIssuesByFilter(filters);


      const { issues = [] } = data;
      issues.forEach((item, i) => {
        res.push(IssueModel.create({ ...item, order: item.order || i + 1 }, this));
      });
    } catch (e) {
      this.onError(e);
      return [];
    } finally {
      this.setIsProcessing(false);
    }

    return res;
  }

  /**
   * Загрузить задачу по ее uid
   *
   * @param {String} uid задачи
   * @return {IssueModel}
   */
  async loadIssue(issueUid) {
    this.setIsProcessing(true);
    try {
      logger.info(`issues.IssueStore.loadIssue | Загрузка задачи по ее uid:
        issueUid="${issueUid}"`);
      const data = await this.api.loadIssue(issueUid);
      await this.rootStore.kindsStore.getItems([issueUid]);
      let issue = this.getIssue(issueUid);
      if (issue) {
        issue.update(data);
      } else {
        if (data.instance && data.instance.external) {
          // если внешний источник, то загружаем из него трекеры
          await this.loadTrackers(data.instance.uid);
        }
        issue =  IssueModel.create(data, this, this);
      }

      // Загружаем информацию о прикрепленных файлах в attachments
      await Promise.all(issue.attachmentList.map(async(attachment) => {
        if (attachment.aisId) {
          const meta = await this.loadFileMetadata(attachment.aisId);
          meta && attachment.setMetadata(meta);
        }
      }));

      // Загружаем информацию о прикрепленных файлах в пользовательских полях
      await Promise.all(issue.customAttachmentList.map(async(attachment) => {
        if (attachment.aisId) {
          const meta = await this.loadFileMetadata(attachment.aisId);
          meta && attachment.setMetadata(meta); 
        }
      }));

      this.addOpenedIssue(issue);
      return issue;
    } catch (e) {
      this.onError(e);
    } finally {
      this.setIsProcessing(false);
    }

    return undefined;
  }

  /**
   * Сохранить / создать  задачу
   *
   * @param {IssueModel} задача
   * @param {Object} data данные для изменения в задаче
   * @return {Boolean}
   */
  async saveIssue(issue, data) {
    try {
      let d = {};
      const saveData = {
        ...data,
        startDate: data.startDate ? moment(data.startDate).format("YYYY-MM-DD") : "",
        dueDate:   data.dueDate ? moment(data.dueDate).format("YYYY-MM-DD") : "",
        doneRatio: data.doneRatio ? (parseInt(data.doneRatio) || 0) : ""
      };
      if (issue.parent) {
        saveData.parentIssueUid = issue.parent.uid;
      }
      if (issue.isNew) {
        logger.info(`issues.IssueStore.saveIssue| Создание новой задачи:
        saveData="${JSON.stringify(saveData)}"`);

        d = await this.api.createIssue({
          ...saveData
        });
      } else {
        logger.info(`issues.IssueStore.saveIssue| Сохранение задачи:
        issueUid="${issue?.uid}"; saveData="${JSON.stringify(saveData)}"`);
        d = await this.api.saveIssue(issue.uid, saveData);
      }
      issue.update({
        ...d,
        startDate: d.startDate && moment(d.startDate, "DD.MM.YYYY").toDate(),
        dueDate:   d.dueDate && moment(d.dueDate, "DD.MM.YYYY").toDate()
      });

      if (issue.parent) {
        // обновляем дерево связанных задач у родительской задачи, если она открыта во вкладке открытых задач
        await this.reloadOpenedIssue(issue.parent.uid);
      }

      // загружаем данные о файлах
      await Promise.all(issue.attachmentList.map(async(attachment) => {
        if (attachment.aisId) {
          const meta = await this.loadFileMetadata(attachment.aisId);
          attachment.setMetadata(meta);
        }
      }));

      // if (this.currentProjectUid === issue.project.uid) {
      this.issues.set(issue.uid, issue);
      // }

      return true;
    } catch (e) {
      this.onError(e);
    }

    return false;
  }

  /**
   * Выгрузить файл с хранилища АИС
   * @param {String} id файла в хранилище
   */
  async downloadFile(id) {
    try {
      if (!id) {
        return null;
      }
      logger.info(`issues.IssueStore.downloadFile| Выгрузка файла с хранилища АИС:
        id="${id}"`);
      return await this.api.downloadFile(id);
    } catch (e) {
      this.onError(e);
    }
  }

  /**
   * Загрузить файл на сервер
   * @param {File} file  загружаемый файл
   * @return {AttachmentModel}
   */
  async uploadFile(file) {
    try {
      logger.info(`issues.IssueStore.uploadFile | Загрузка файла в хранилище АИС:
        file="${file?.name}"`);
      const data = await this.api.uploadFile(file);
      const fileItem = data[0] || {}; // берем только первый файл
      const newAttachment = AttachmentModel.create({
        aisId: fileItem.id
      }, this);

      newAttachment.setMetadata(fileItem);
      return newAttachment;
    } catch (e) {
      this.onError(e);
    }

    return null;
  }

  /**
   * Загрузить данные файла с хранилища АИС
   * @param {String} id файла в хранилище
   */
  async loadFileMetadata(id) {
    if (!id) {
      return;
    }
    try {
      logger.info(`issues.IssueStore.loadFileMetadata | Загрузка данных файла с хранилища АИС:
        id="${id}"`);
      return await this.api.loadFileMetadata(id);
    } catch (e) {
      this.onError(e);
    }
  }

  /**
   * Очистить список задач
   *
   */
  @action
  clearIssues() {
    this.issues.clear();
  }

  /**
   * Очистить список выбранных задач
   *
   */
  @action
  clearSelectedIssues() {
    this.selectedUidsIssueMap.clear();
  }

  /**
   * Очистить список открытых задач
   *
   */
  @action
  clearOpenedIssues() {
    // this.openedIssues.clear();
    this.openedIssues = [];
  }

  /**
   * Очистить списки и словари данных, относящихся к проекту.
   * Данный метод необхожим при смене проекта
   *
   */
  @action
  clearProjectData() {
    logger.info("issues.IssueStore.clearProjectData | Очистка списка и словарей данных, относящихся к проекту");
    this.setCurrentProjectUid(undefined);
    this.statuses.clear();
    this.priorities.clear();
    this.trackers.clear();
    this.users.clear();
    this.clearOpenedIssues();
    this.clearSelectedIssues();
    this.clearIssues();
  }

  /**
   * Очистить словарь данных - список статусов задачи, список приоритетов задачи
   *
   */
  @action
  clearDictonaryData() {
    logger.info("issues.IssueStore.clearDictonaryData | Очистка словаря данных");
    this.statuses.clear();
    this.priorities.clear();
    this.trackers.clear();
    this.users.clear();
    this.projets.clear();
  }

  /**
   * Очистить данные
   *
   */
  @action
  clear() {
    logger.info("issues.IssueStore.clear | Очистка данных");
    this.setCurrentProjectUid(undefined);
    this.clearIssues();
    this.clearOpenedIssues();
    this.clearDictonaryData();
  }

  /**
   * Открыть вкладку с задачей
   * 
   * @param {String} uid задачи
   */
  async openIssue(uid) {
    logger.info(`issues.IssueStore.openIssue | Открыть вкладку с задачей:
        uid="${uid}"`);
    // проверяем, есть ли уже открытая задача в закладках
    const index = this.openedIssueList.findIndex((i) => {
      return i.uid === uid;
    });
    // если нет, то загружаем задачу и добавляем потом в закладки
    if (index < 0) {
      const i = await this.loadIssue(uid);
      this.setActiveIssue(i);
    } else {
      // если есть, то делаем активной эту закладку
      const i = this.openedIssueList[index];
      this.setActiveIssue(i);
    }
  }

  /**
   * Создать новую задачу
   *
   * @param {TrackerModel} tracker трекер(вид) задачи
   * @param {IssueModel} parent родительская задача. если есть
   * @param {String} projectUid uid проекта, для которого создается новая Задача
   * @return {IssueModel}
   */
  createNewIssue(tracker, parent, projectUid) {
    logger.info(`issues.IssueStore.createNewIssue | Создаем новую задачу:
        trackerId="${tracker?.id}"; parentUId="${parent?.uid}"; projectUid="${projectUid}";`);
    const data = {
      tracker,
      createdOn: new Date(),
      updatedOn: new Date(),
      author:    { uid: this.rootStore.accountStore.uid, name: this.rootStore.accountStore.name },
      project:   this.getProjectByUid(projectUid || this.currentProjectUid),
      parent
    };
    const newIssues = this.openedIssues.filter((i) => {
      return i.isNew;
    });
    if (newIssues.length === 0) {  // добавляем только одну новую задачу (без uid)
      const newIssue = IssueModel.create(data, this, this);
      this.addOpenedIssue(newIssue);
      return newIssue;
    } else {
      return newIssues[0];
    }
  }

  /**
   * Проверить, есть ли связь проекта Redmine с участником вида
   * 
   * @param {String} memberUid uid участника вида
   * @returns 
   */
  async checkProjectsLinks(memberUid) {
    logger.info(`issues.IssueStore.checkProjectsLinks | Проверяем есть ли связь проекта Redmine с участником вида:
        memberUid="${memberUid}"`);
    const res = await this.api.checkProjectsLinks([memberUid]);
    return res[memberUid];
  }

  /**
   * Проверить связанные трекеры с текущим проектом по набору uid Видов или id трекеров Redmine 
   *
   * @param {String} projectUid uid текущего проекта в АИС
   * @param {Array<String>} uids массив uid'ов участниов Вида, которые нужно проверить на связь с проектом в Redmine 
   * @param {Array<String>} ids массив id'ов трекеров Redmine, которые нужно проверить на связь с проектом в Redmine
   */
  async checkProjectTrackers(projectUid, uids = [], ids = []) {
    try {
      // eslint-disable-next-line max-len
      logger.info(`issues.IssueStore.checkProjectTrackers | Проверяем связанные трекеры с текущим проектом по набору uid Видов или id трекеров Redmine:
        projectUid="${projectUid}"; uids="${JSON.stringify(uids)}"; ids="${JSON.stringify(ids)}"`);
      const data = await this.api.checkProjectTrackers(projectUid, uids, ids);
      return data;
    } catch (ex) {
      this.onError(ex);
    }
    return null;
  }

  /**
   * Создать новый проект в Redmine
   * 
   * @param {String} uid uid проекта в АИС
   * @param {String} name название проекта
   * @returns 
   */
  async createRedmineProject(uid, name) {
    this.setIsProcessing(true);
    try {
      logger.info(`issues.IssueStore.createRedmineProject | Создаем новый проект в Redmine:
        uid="${uid}"; name="${name}"`);
      const data = await this.api.createRedmineProject(uid, name);
      return data?.uid;
    } catch (e) {
      this.onError(e);
    } finally {
      this.setIsProcessing(false);
    }
    return null;
  }

  /**
   * Получить конфигурацию инструмент", onа;
   *
   * @return {Object}
   */
  @computed
  get config() {
    return this.rootStore.uiStore.getModuleConfig(MODULE_NAME_CFG);
  }

  /**
   * Получить параметр конфигурации инструмента
   *
   * @param {String} name название параметра
   * @return {Object}
   */
  getItemConfig(name) {
    return this.config && this.config[name];
  }

  /**
   * Задать параметр конфигурации инструмента
   *
   * @param {String} name название параметра
   * @param {Object} params набор параметров
   */
  setItemConfig(name, params) {
    this.rootStore.uiStore.setModuleConfig(MODULE_NAME_CFG, {
      ...this.config,
      [name]: params
    });
  }

  /**
   * Перезагрузить данные открытой Задачи
   *
   * @param {String} issueUid uid задачи
   */
  async reloadOpenedIssue(issueUid) {
    logger.info(`issues.IssueStore.reloadOpenedIssue | Перезагружаем данные открытой Задачи:
        issueUid="${issueUid}"`);
    // проверяем, есть ли уже открытая задача в закладках
    const index = this.openedIssueList.findIndex((i) => {
      return i.uid === issueUid;
    });
    // если нет, тоничего не делаем и выходим
    if (index < 0) {
      return;
    } 
    
    // если есть, то загружаем данные карточки и обновляем
    const issue = this.openedIssueList[index];
    issue.setIsPending(true);
    try {
      const data = await this.api.loadIssue(issue.uid);
      issue.update(data);
    } catch (ex) {
      this.onError(ex);
    } finally {
      issue.setIsPending(false);
    }
  }

  getIconByTracker(tracker) {
    if (this.rootStore && tracker) {
      const kind = this.rootStore.kindsStore.getKind(tracker.uid);
      if (kind) {
        return this.rootStore.accountStore.getIcon(kind.uid);
      }
    }

    return "reglament-M";
  }

  /**
   * Найти задачи по тексту
   *
   * @param {String} text текст поиска
   * 
   * @returns {Array<IssueModel>}
   */
  async searchIssuesByText(text) {
    try {
      logger.info(`issues.IssueStore.searchIssuesByText | Ищем Задачи по тексту:
        text="${text}"`);
      let projectUid =  this.currentProjectUid;
      if (this.activeIssue) {
        projectUid = this.activeIssue.project?.uid;
      }
      const issues = await this.api.searchIssuesByText(text, projectUid, 10);
      return issues.map((issue) => {
        return {
          ...issue,
          title:       issue.subject,
          titlePrefix: IssueModel.titlePrefix(issue.tracker, issue.id),
          iconString:  this.getIconByTracker(issue.tracker) 
        };
      });
    } catch (ex) {
      this.onError(ex);
    }
    return [];
  }

  /**
   * Отвязать одну задачу от родительской
   * 
   * @param {String} targetIssueUid  uid Задачи, которую нужно отвзяать от родительской
   * @param {IssueModel} currentIssue текущая открытая задача, в карточке которой происходит эта операция.
   */
  async unlinkRelatedIssue(targetIssueUid, currentIssue) {
    logger.info(`issues.IssueStore.unlinkRelatedIssue | Отвязываем задачу от родительской:
        targetIssueUid="${targetIssueUid}"; currentIssue="${currentIssue?.uid}"`);
    if (!currentIssue) {
      return;
    }
    if (!targetIssueUid) {
      currentIssue.setParent(undefined);
      return;
    }
    currentIssue.setIsPending(true);
    try {
      let data  = await this.api.saveIssue(targetIssueUid, {
        parentIssueUid: ""
      });
      if (currentIssue.uid !== targetIssueUid) {
        data = await this.api.loadIssue(currentIssue.uid);
      } 
      currentIssue.update(data);
      if (!data.parent) {
        currentIssue.setParent(null);
      }
    } catch (ex) {
      this.onError(ex);
    } finally {
      currentIssue.setIsPending(false);
    }
  }

  /**
   * Привязать одну задачу к другой задаче
   * 
   * @param {String} targetIssueUid  uid Задачи, которую нужно привязать к родителськой задаче
   * @param {String} parentIssueUid  uid родительской Задачи, к которой привязываем Задачу
   * @param {IssueModel} currentIssue текущая открытая задача, в карточке которой происходит эта операция.
   */
  async linkRelatedIssue(targetIssueUid, parentIssueUid, currentIssue) {
    currentIssue.setIsPending(true);
    try {
      logger.info(`issues.IssueStore.linkRelatedIssue | Привязываем Задачу к другой Задаче:
        targetIssueUid="${targetIssueUid}"; parentIssueUid="${parentIssueUid}"; currentIssue="${currentIssue?.uid}"`);
      let data = await this.api.saveIssue(targetIssueUid, {
        parentIssueUid
      });
      if (currentIssue.uid !== targetIssueUid) {
        data = await this.api.loadIssue(currentIssue.uid);
      } 
      currentIssue.update(data);
    } catch (ex) {
      this.onError(ex);
    } finally {
      currentIssue.setIsPending(false);
    }
  }

  /**
   * Получить текстовое значение фильтра из выпадающего списка
   * 
   * @param {String} key название(ключ) фильтра
   * @param {String} value значение фильтра (uid)
   * @returns {String} текстовое значение фильтра из выпадающего списка
   */
  getFilterTitle(key, value) {
    // logger.info(`issues.IssueStore.getFilterTitle | Получаем текстовое значение фильтра из выпадающего списка:
    //   key="${key}"; value="${value}"`);
    switch (key) {
      case "tracker":{
        const tr = this.trackerList.filter((item) => {
          return item.uid ? item.uid === value : item.id === value;
        })[0];
        return tr && tr.title;
      }
      case "priority":{
        const pr = this.priorities.get(value);
        return pr && pr.title;
      }
      case "author":
      case "assignedTo":  {
        const project = this.getProjectByUid(this.currentProjectUid);
        if (project?.isExternal) {
          const user = this.userList.filter((u) => {
            return u.id === value;
          })[0];
          return user && user.shortName;
        }

        const user = this.rootStore.userStore.list.filter((u) => {
          return u.uid === value;
        })[0];
        return user && user.shortName;
      }
      case "status":{
        if ([STATUS_OPEN, STATUS_CLOSED].includes(value)) {
          return statusTitle(value);
        }
        return this.getStatus(value)?.title;
      }
      case "project":{
        const pr = this.getProjectByUid(value);
        return pr?.title;
      }
      default:
        return value;
    }
  }

  /**
   * Получить список доп. полей из выбранных карточек задач
   * 
   * @returns {Map}
   */
  @computed
  get allCustomFieldsInSelectedIssues() {
    const customFields = new Map();
    // TODO: тк теперь при выделении сразу всех записей, у нас есть только uid выделенных задач (загружать
    // все задачи по фильтрам слишком большой объем и долго), то пока делаем поиск custom полей по уже загруженным 
    // задачам. 
    // Сейчас у нас задачи загружаются, привязанные к одному проекту. Поэтому по одной из задач мы уже получим 
    // правильный набор custom полей. Но если в дальнейшем в выборке будет несколько проектов, то тут нужно
    // будет решать вопрос, как для каждого проекта получить описание custom  полей
    this.selectedUidsIssueMap.forEach((b, uid) => {
      const issue = this.getIssue(uid);
      issue && issue.customFields.forEach((field) => {
        if (!customFields.has(field.id)) {
          customFields.set(field.id, field);
        }
      });
    });
    // this.selectedIssuesMap.forEach((issue) => {
    //   issue.customFields.forEach((field) => {
    //     if (!customFields.has(field.id)) {
    //       customFields.set(field.id, field);
    //     }
    //   });
    // });
    return customFields;
  }

  /**
   * Загрузить список шаблонов КП
   *
   * @return {Promise}
   */
  async loadCoordLetterTemplates() {
    const templates = new Map();
    // this.setIsProcessing(true);
    try {
      logger.info("issues.IssueStore.loadCoordLetterTemplates | Загружаем список шаблонов КП");
      const data = await this.api.loadCoordLetterTemplates();
      const uids = [];
      (data || []).forEach((item) => {
        const templ = CoordTemplateModel.create(item, this);
        templates.set(templ.uid, templ);
        uids.push({
          uid:     templ.uid,
          version: templ.version
        });
      });
      // Загружаю имена нод РМ
      logger.info(`issues.IssueStore.loadCoordLetterTemplates | Загружаю имена нод РМ: 
        uids="${JSON.stringify(uids)}"`);
      const objects = await this.api.getCoordLetterTemplatesName(uids);
      objects  && objects.forEach((obj) => {
        const t = templates.get(obj.uid);
        if (t) {
          t.setName(obj.title);
        }
      });
    } catch (e) {
      // this.onError(e.message);
      console.error(e.message);
    } finally {
      // this.setIsProcessing(false);
    }

    return Array.from(templates.values());
  }

  /**
   * Загрузить шаблон КП
   *
   * @param {String} uid шаблона КП
   * 
   * @return {CoordLetterTempate}
   */
  async loadCoordLetterTemplate(uid) {
    this.setIsProcessing(true);
    try {
      logger.info(`issues.IssueStore.loadCoordLetterTemplate | Загружаю шаблон КП: 
        uid="${uid}"`);
      const data = await this.api.loadCoordLetterTemplate(uid);
      const templ = CoordTemplateModel.create(data, this);
      return templ;
    } catch (e) {
      this.onError(e);
    } finally {
      this.setIsProcessing(false);
    }

    return null;
  } 

  /**
   * Создать Координационное письмо на основе шаблона
   *
   * @param {CoordLetterTemplateModel} template шаблон Координационного письма
   * @return {Promise}
   */
  async createCoordLetter(template) {
    this.setIsProcessing(true);
    try {
      const { paramsForCoordLetter } = template;
      logger.info(`issues.IssueStore.createCoordLetter | Создаю координационное письмо на основе шаблона: 
        paramsForCoordLetter="${JSON.stringify(paramsForCoordLetter)}"`);
      const data =  await this.api.createCoordLetter(paramsForCoordLetter);
      const representations = [];
      Array.isArray(data) && data.forEach((item) => {
        if (item.node) {
          representations.push(
            this.rootStore.objectStore.processLibraryItem(item.node, DOMAIN_LIBRARY, {}, { loadKinds: false }));
        }
      });
      const libraryNodes = await Promise.all(representations); 
      return libraryNodes;
    } catch (e) {
      this.onError(e);
    } finally {
      this.setIsProcessing(false);
    }
    return false;
  }

  /**
   * Экспортировать задачу 
   *
   * @param {String} issueUid uid задачи
   * @param {String} format в какой формат экчпортирвать задачу
   * 
   * @return {Promise}
   */
  async exportIssue(issueUid, format) {
    logger.info(`issues.IssueStore.createCoordLetter | Экспортирую задачу : 
        issueUid="${issueUid}"; format="${format}"`);
    if (!issueUid) {
      this.onError("Не задан uid задачи для экспорта");
    }

    if (!format) {
      this.onError("Не задан формат для экспорта");
    }

    this.setIsProcessing(true);
    try {
      switch (format) {
        case "pdf":{
          const blobFile = await this.api.exportIssueToPdf(issueUid);
          return blobFile;
        }
        case "docx":{
          const blobFile = await this.api.exportIssueToDocx(issueUid);
          return blobFile;
        }

        default:
          this.onError(`Передан неизвестный формат для экспорта - "${format}"`);
      }
    } catch (e) {
      this.onError(e);
    } finally {
      this.setIsProcessing(false);
    }

    return null;
  }

  /**
   * Добавить uid Задачи в Подборке
   * 
   * @param {String} uid uid добавляемой Задачи
   */
  @action
  addToCart(uid) {
    this.cartSet.add(uid);
  }

  /**
   * Удалить uid Задачи из Подборки
   * 
   * @param {String} uid uid удаляемой Задачи
   */
  @action
  deleteFromCart(uid) {
    this.cartSet.delete(uid);
  }

  /**
   * Очистить Подборку от списка uid Задач
   * 
   */
  @action
  clearCart() {
    this.cartSet.clear();
  }

  /**
   * Число выбранных Задач в Подборке
   * 
   * @returns {Number} Число выбранных Задач в Подборке
   */
  @computed
  get cartSize() {
    return this.cartSet.size;
  }

  /**
   * Получить массив uid задач в Подборке
   * 
   * @returns {Array<String>} массив uid задач в Подборке
   */
  @computed
  get cartUids() {
    return Array.from(this.cartSet.values());
  }

  /**
   * Функция для вызова сообщения обшибке
   *
   * @param {String} msg текст сообщения об ошибке
   */
  onError(msg) {
    let str = typeof err === "string" && msg;
    if (typeof err === "object") {
      str = JSON.stringify(msg);
    }  
    logger.error(`issues.IssueStore.onError |  ${str}`);
    this.rootStore.onError(msg);
  }


  /**
   * Метод разрушения хранилища
   *
   */
  destroy() {
    this.clear();
  }
}

export default IssueStore;
