import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import {  Field } from "@ais3p/ui-framework";
import IssueStore from "../../stores/IssueStore";
import { CustomFieldModel } from "../../models";


/**
 * Компонент для отображения поля с пользваотелями
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} props.store хранилище для работы с задачами
 * @param {CustomFieldModel} props.сustomFieldModel модель пользвоательского поля
 * @param {Any}  props.value значение поля
 * @param {Boolean} props.readOnly флаг указывающий, что пользователей в списске можно только проссматривать
 * @param {Function} props.onChange callback функция при добавлении/удалении пользователя
 */
const UserField = observer(({ store, value, customField, readOnly, onChange }) => {
  const isValid = useMemo(() => {
    return  customField.validateValue(value).isValid;
  }, [customField, value]);

  const userId = useMemo(() => {
    if (value  && value.id) {
      return value.id;
    }

    if (value && value.length) {
      return Array.from(value).map((v) => {
        if (v && v.id) {
          return v.id;
        }

        return v;
      });
    }

    return value;
  }, [value, value && value.id]);
  
  const hint = useMemo(() => {
    return  customField.validateValue(value).hint;
  }, [customField, value]);

  if (customField.multiple) {
    return (
      <Field.MultiSelect
        icon="app-usersgroups-M"
        label={customField.name}
        name={customField.id}
        isClearable={!customField.required}
        isDisabled={!customField.visible || readOnly}
        isValid={isValid === false ? false : undefined}
        hint={hint}
        value={userId}
        onChange={onChange}
        placeholder="Выберите пользователя"
        multiselect={true}
        options={store.userList.map((user) => {
          return { label: user.shortName, value: user.id };
        })}
      />
    );
  } else {
    return (
      <Field.SingleSelect
        icon="user-M"
        label={customField.name}
        name={customField.id}
        isClearable={!customField.required}
        isDisabled={!customField.visible || readOnly}
        isValid={isValid === false ? false : undefined}
        value={userId}
        onChange={onChange}
        placeholder="Выберите пользователя"
        // отображаем только связанных пользователей Redmine с АИС
        options={store.linkedUserList.map((user) => {
          return { label: user.aisUser.shortName, value: user.id };
        })}
      />
    );
  };
});

UserField.propTypes = {
  store:       PropTypes.instanceOf(IssueStore), 
  value:       PropTypes.array, 
  customField: PropTypes.instanceOf(CustomFieldModel), 
  readOnly:    PropTypes.bool, 
  onChange:    PropTypes.func
};

export default UserField;
