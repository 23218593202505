import { observable, computed, action } from "mobx";

/**
 * Модель прикрепленной задачи к АИС объекту
 *
 * @class AttachedIssueModel
 */
class AttachedIssueModel {
  /**
   * uid АИС объекта, к которому прикреплена задача
   *
   * @type String
   */
  @observable
  aisObjectUid = undefined;

  /**
   * Версния АИС объекта, к которому прикреплена задача
   *
   * @type Number
   */
  @observable
  aisObjectVersion = 0;

  /**
   * Прикрепленная задача
   *
   * @type IssueModel
   */
  @observable
  issue = undefined;

  /**
   * Направление связи - source || dest
   *
   * @type String
   */
  @observable
  direction = undefined;

  /**
   * Связь между АИС объектом и задачей
   *
   * @type Relation
   */
  @observable
  relation = undefined;

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.aisObjectUid uid АИС объекта, к которому прикреплена задача
   * @param {String} params.aisObjectVersion версия АИС объекта, к которому прикреплена задача
   * @param {IssueModel} params.issue задача
   * @param {Relation} params.relation связь
   * @param {String} params.direction Направление связи - source || dest
   * @param {AttacheIssuesStore} attachedIssuesStore хранилище прикрепленных задач
   *
   * @retrun {AttachmentModel}
   */
  static create({
    aisObjectUid,
    aisObjectVersion,
    issue,
    relation,
    direction
  }, attachedIssuesStore) {
    return new AttachedIssueModel({
      aisObjectUid,
      aisObjectVersion,
      issue,
      relation,
      direction
    }, attachedIssuesStore);
  }

  constructor(params, attachedIssuesStore) {
    this.aisObjectUid = params.aisObjectUid;
    this.aisObjectVersion = params.aisObjectVersion;
    this.issue = params.issue;
    this.relation = params.relation;
    this.direction = params.direction;

    this.attachedIssuesStore = attachedIssuesStore;
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "attachedIssueModel";
  }


  /**
   * uid записи - uid задачи
   *
   * @return {String}
   */
  @computed
  get uid() {
    return this.issue?.uid;
  }

  /**
   * Строковое представление
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.issue?.title;
  }

  /**
   * Задать прикрепленную задачу
   *
   * @param {IssueModel}
   */
  @action 
  setIssue(issue) {
    this.issue = issue;
  }
}

export default AttachedIssueModel;
