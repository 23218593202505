import { 
  DOMAIN_ISSUE, 
  DOMAIN_TEXT, 
  DOMAIN_LIBRARY,
  DOMAIN_REPO 
} from "~/core/constants/Domains";
import getDomainByClass from "./getDomainByClass";
import { CLS_LIBRARY_FILE } from "../constants/Classes";
import iconRender from "./IconRender";

/**
 * Получить набор параметров для открытия layout инструмента
 * Используется для перехода по ссылке.
 * Ф-я асснихронная, т.к. может потребоваться отдельный запрос для получения `uid` головного документа, для текстового
 * объекта, если текстовое представление самого документ не было загружено еще в нашего клиента (ObjectStore). 
 * Такое возможно если представление текстового элемента было отображено в таких инструментах как: 
 * `Связи`, `Анализ связей`.
 * 
 * @param {Object} item объект системы
 * @param {objectStore} objectStore глобальное хранилище
 * 
 * @return {Object} 
 */
const getPropsToOpenLayoutTool = async(item, objectStore) => {
  // Принадлежность к домену мы можем получить: от св-ва `domain`, от типа инструмента `tool`, от св-ва `class`  
  const domain = item.domain || item.tool || getDomainByClass(item.class);
  let component = item.tool || domain;
  // Получаем представление объекта из ObjectStore
  const itemVersion = objectStore.getVersion(item.uid, domain, item.version);
  if (!(item && itemVersion)) {
    return undefined;
  }
  let name = itemVersion.title || itemVersion.name;
  let id = item.uid;
  let props = {
    focusUid: item.uid,
    version:  item.version,
    class:    item.class || item.className
    // useGlobalTracking: false
  };

  if (item.class === CLS_LIBRARY_FILE) {
    component = "viewer";
    props = {
      type: item.fileType,
      name,
      icon: iconRender(item, true),
      file: item.fileId
    };
  }
  switch (domain) {
    case DOMAIN_LIBRARY:
      props = { 
        ...props,
        id:   item.uid,
        path: itemVersion.path || item.path
      };
      break;
    case DOMAIN_TEXT: {
      let editable = itemVersion.topmostKnownParentUid;
      // Если текстовое представление документа, в который входит наш элемент не был еще загружен, то
      // получаем uid этого текстового представления
      if (!editable) {
        editable = await objectStore.loadEditableUid(item.uid, item.version);
      }
      // получаем ноду Библиотеки
      const node = await objectStore.getLibraryNodeByEditableUid(editable, item.version);
      if (node) {
        name = node.title;
      } else {
        /** 
         * @nego: Временное решение T6255
         * Если  node не вернулась, то значит либо нет прав доступа к этой node, либо
         * она была удалена. Признака, чтобы определить причину невозврата node  пока нет.
         * В будущем, при попытке открытия какого-либо артефакта АИС (переход по ссылке или url), 
         * отвечающий сервис д.б. проверить право доступа к этому артефатку и если доступа нет или артефакт удален,
         * то соответсвенно сообщить об этом клиенту
         */ 

        return null;
      }
      id = editable;
      props = {
        ...props,
        id: editable, // необходимо, чтобы не открывать несколько раз один и тот же документ в разных вкладках
        editable
      };
      break;
    }
    case DOMAIN_ISSUE: {
      // указываем `uid` проекта, к которому относится задача
      // id = itemVersion.project.uid;
      props = {
        ...props,
        id
      };
      break;
    }
    case DOMAIN_REPO: {
      name = item.repoName || "Репозиторий";
      props = {
        ...props,
        editable: itemVersion.repositoryId || item.repositoryId,
        path:     itemVersion.path && itemVersion.path.split("/")
      };
      if (itemVersion.startLine) {
        props.focusCodeLine = itemVersion.startLine;
      }
      break;
    }
  }

  return {
    id,
    name,
    component,
    props
  };
};



export default getPropsToOpenLayoutTool;
