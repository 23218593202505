const fileTypeFromContentType = (contentType) => {
  const type = contentType && contentType.split("/")[0];
  if (contentType === "application/pdf") {
    return "pdf";
  }
  switch (type) {
    case "image":
      return "image";
    case "text":
      return "text";
    default:
      return "download";
  }
};

export default fileTypeFromContentType;
