import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import useStores from "~/core/utils/useStores";
import UserAvatar from "~/modules/users/components/avatar";
import IssueStore from "../../stores/IssueStore";

/**
 * Компонет для отображения пользователя в карточке Задача
 * 
 * @param {Object} props набор параметров
 * @param {Object} params.redmineUser данные пользователя в Redmine
 * @param {Number} params.userId id пользователя в Redmine
 * @param {String} params.userUid uid пользователя в АИС
 * @param {String} params.className пользовательский className
 * @param {String} params.name Имя пользователя по умолчанию, если его нет в АИС
 * @param {IssueStore} params.store хранилище для работы с задачами
 */
const UserItem = observer(({
  redmineUser,  //
  userId, // id в Redmine
  userUid, // uid  в АИС
  name, // Имя пользователя по умолчанию, если его нет в АИС
  className,
  store
}) => {
  const { userStore } = useStores();

  const user = useMemo(() => {
    try {
      if (redmineUser && redmineUser.aisUser) {
        return redmineUser.aisUser;
      } else if (userUid) {
        return userStore.get(userUid);
      } else if (userId) {
        const u = store.getUser(userId);
        return u && u.aisUser;
      }
    } catch (ex) {
      console.error(ex);
      return undefined;
    }

    return undefined;
  }, [redmineUser, userUid, userId, store.users.size]);
  return (
    <div className={`issue-user ${className ? className : ""}`}>
      <UserAvatar
        className="avatar"
        fileId={user && user.image}
      />
      {user &&
        <div className="user-name">
          {user && user.shortName}
        </div>
      }
      {!user && name &&
        <div className="user-name">
          {name}
        </div>
      }
      {!user && !name &&
        <div className="user-undefined">
          {`Пользователь с "${userUid || userId}" не найден!`}
        </div>
      }
    </div>
  );
});

UserItem.propTypes = {
  redmineUser: PropTypes.object,
  userId:      PropTypes.number, // id в Redmine
  userUid:     PropTypes.string, // uid  в АИС
  className:   PropTypes.string,
  store:       PropTypes.instanceOf(IssueStore)
};

export default UserItem;
