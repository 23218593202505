import { observable, action, toJS } from "mobx";
import { nanoid } from "nanoid";
import moment from "moment";
import platform from "platform";

import createCritical from "./error-helpers";

/**
 * Модель стека
 * В данной модели будут формироваться данные для отправки на сервер в случай ошибки в UI
 *
 */
class Stack {
  /**
   * Id сессии
   * 
   * @type String
   */
  @observable
  traceId = undefined;

  /**
   * Формат даты
   * 
   * @type String
   */
  dateFormat = "DD.MM.YYYY HH:mm:ss";

  /**
   * Дата начала сессии
   * 
   * @type DateTime
   */
  @observable
  startAt = undefined;

  /**
   * Код кнопки клавиатуры, которая была нажата в последний момент
   * 
   * @type String
   */
  @observable
  keyboardPressed = undefined;

  /**
   * Где была нажата кнопка мышки. Будет объект, в котром будет храниться
   *  key - номер кнопки мыши (0-левая кнопка, 1- средняя кнопка, 2-правая кнопка, 3 - назад, 4 - вперед )
   *  innerHtml - текст HTML объекта, на который кликнули
   * 
   * @type Object
   */
  @observable
  mousePressed = undefined;

  constructor(traceId, dateFormat = "DD.MM.YYYY HH:mm:ss") {
    this.traceId = traceId ||  nanoid(10); // shortUid примерного вида - "VSCCeUk70R"; 
    this.startAt = new Date();
    this.dateFormat = dateFormat;
  }
  
  /**
   * Cохраняем/сбрасываем данные о клике мышки
   * 
   * @param {Object} data данные о клике мышки
   * @param {Number} data.button номер конпкиу мышки, которая была нажата
   * @param {String} data.innerHtml текст объекта, на который было нажатие
   */
  @action
  setMousePressed(data) {
    this.mousePressed = data; 
  }

  /**
   * Cохраняем/сбрасываем данные о клике клавиатуры
   * 
   * @param {String} code код нажатой клавиши на клавиатуре
   */
  @action
  setKeyboardPressed(code) {
    this.keyboardPressed = code;
  }

  /**
   * Получить набор данных стека для дальнейшей отправки на сервер или еще куда-нибудь об ошибке
   * 
   * @param {Loogger} logger логгер событий, куда записыаются все действия пользователя
   * 
   * @returns {Object}
   */
  getData(logger) {
    const lang = Array.isArray(window?.navigator?.languages) ? window?.navigator?.languages[0] : "";
    const href = window?.location?.href || "";
    const actions = logger?.getStackCollection();
    const screen = window?.screen ?
      Object.assign(
        {},
        {
          width:      window.screen.width,
          height:     window.screen.height,
          colorDepth: window.screen.colorDepth,
          pixelDepth: window.screen.pixelDepth
        },
        window.screen.orientation ? { orientation: window.screen.orientation.type } : {}
      ) :
      {};

    const { name, os, version } = platform;

    const BROWSER = `${name} ${version}`;
    const OS = `${os.family} ${os.architecture}-bit`;
    const device = {
      type:        "pc",
      description: platform.description,
      ua:          platform.ua
    };

    return {
      actions,
      device,
      env: {
        lang,
        href,
        os:      OS,
        browser: BROWSER
      },
      screen,
      session: {
        start: moment(this.startAt).format(this.dateFormat),
        end:   moment().format(this.dateFormat)
      },
      traceId:         this.traceId,
      keyboardPressed: this.keyboardPressed,
      mousePressed:    this.mousePressed ? toJS(this.mousePressed) : null
    };
  }

  /**
   * Получить набор данных стека при возниконовении js ошибки в UI, 
   * для дальнейшей отправки на сервер или еще куда-нибудь об ошибке
   * 
   * @param {Loogger} logger логгер событий, куда записыаются все действия пользователя
   * @param {String} error текст ошибки
   * @param {lineno} lineno номер строки в коде, где произошла ошибка
   * 
   * @returns {Object}
   */
  criticalDataError(logger, error, lineno) {
    const criticalData = createCritical(error, lineno);
    return {
      ...this.getData(logger),
      error:  `${criticalData.message} \nНомер трассировки: ${this.traceId}`, 
      lineno,
      detail: criticalData 
    };
  }
}

export default Stack;
