import { action, computed, observable } from "mobx";
import Indent from "./Indent";

class Formula extends Indent {
  @observable
  captionId = null;
  @observable
  isEditingFormula = false;

  @action
  init(data, parent) {
    super.init(data, parent);
    if (data.inline) {
      this.inline = data.inline;
    } else {
      this.createInline();
    }
    if (data.caption) {
      this.captionId = data.caption;
    } else {
      this.createCaption();
    }
    if (data.isNew) {
      this.creationPromice = this.persistCreate();
    }
  }

  @action
  async createInline() {
    if (!this.store) {
      return null;
    }
    const item = this.store.createItem(
      {
        class: "text.chunk.InlineFormula",
        uid:   this.store.getUid()
      },
      this
    );
    if (item) {
      this.inline = item.uid;
    }
  }

  @action
  createCaption() {
    if (!this.store) {
      return null;
    }
    const item = this.store.createItem(
      {
        class: "text.element.Caption",
        uid:   this.store.getUid()
      },
      this
    );
    if (item) {
      this.captionId = item.uid;
    }
  }

  @action
  async setEditing(offset, noFocus = false) {
    if (!((this.isLocked && !this.isLockedByMe) || (this.isParentLocked && !this.isParentLockedByMe))) {
      const itemId = this.captionId;
      const item = this.getItemById(itemId);
      await(item && item.setEditing(offset, noFocus));
    }
  }

  @action
  setEditingFormula(isEditingFormula = false) {
    if (!((this.isLocked && !this.isLockedByMe) || (this.isParentLocked && !this.isParentLockedByMe))) {
      this.isEditingFormula = isEditingFormula;
    }
  }

  @action
  selfDestruct(stopPropagation) {
    if (this.parent) {
      this.parent.delete(this.uid, stopPropagation);
    } else {
      console.warn("no parent");
    }
  }

  @action
  setPrevChild(id, signal, offset) {
    if (id === this.captionId) {
      this.inlineItem.setEditing(offset);
    } else {
      this.parent.setPrevChild(this.uid, signal, offset);
    }
  }

  @computed
  get slug() {
    return "formulas";
  }

  @computed
  get category() {
    return "elements";
  }

  @computed
  get title() {
    if (this.caption && this.caption.title) {
      return this.caption.title;
    }
    return "Формула";
  }

  @computed
  get flatItemsArray() {
    return [this];
  }

  @computed
  get inlineItem() {
    return this.getItemById(this.inline);
  }

  @computed
  get caption() {
    return this.getItemById(this.captionId);
  }

  @computed
  get output() {
    return [
      {
        class:       this.className,
        uid:         this.uid,
        inline:      this.inline,
        caption:     this.captionId,
        "@position": this.position
      },
      this.inlineItem.output,
      this.caption.output,
      this.caption.text.output
    ];
  }
}

export default Formula;
