import React, { useCallback,  useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Field } from "@ais3p/ui-framework";

import IssueStore from "../../stores/IssueStore";
import {  CustomFieldModel } from "../../models";
// import AttachmentField from "./AttachmentField";
import UserField from "./UserField";
import VersionField from "./VersionField";

/**
 * Компонент для отображения пользвоательского поля
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} props.store хранилище для работы с задачами
 * @param {CustomFieldModel} props.customField модель пользовательского поля
 * @param {Boolean} props.readOnly флаг указывающий, что файлы в спсиске только можно просмматривать
 */
const CustomField = observer(
  ({
    customField,
    value,
    readOnly,
    store,
    onChange
  }) => {
    const type = useMemo(() => {
      switch (customField.fieldFormat) {
        case "text":
          return "textarea";
        case "int":
          return "integer";
        case "enumeration":
        case "list":
          return "enum";
        case "date":
          return "datetime";
        case "bool":
          return "boolean";
      }
      return customField.fieldFormat;
    }, [customField.fieldFormat]);

    
    const isValid = useMemo(() => {
      return  customField.validateValue(value).isValid;
    }, [customField, value]);
    
    const hint = useMemo(() => {
      if (customField.required && !value && type !== "bool") {
        return "Это поле - обязательное!";
      }
      const validate = customField.validateValue(value);
      if (!validate.isValid) {
        return  validate.hint;
      }
      return;
    }, [customField.required, value, type]);

    const onFieldChange = useCallback((value, name) => {
      // const id = Number(name);
      onChange && onChange(value, name, customField.fieldFormat);
    }, [customField && customField.fieldFormat, isValid]);

    const icon = useMemo(() => {
      if (type === "link") {
        return "link-M";
      }

      if (type === "textarea") {
        return "data-string-M";
      }

      return `data-${
        type === "enum"
          ? `${type}-${customField.multiple ? "multi" : "single"}`
          : type
      }-M`;
    }, [type, customField.multiple]);

    const opts = useMemo(() => {
      return Array.isArray(customField.possibleValues) ? customField.possibleValues.map((item) => {
        return {
          value: item.key,
          label: item.label
        };
      }) : [];
    }, [customField.possibleValues]);

    const fieldProps = useMemo(() => {
      return {
        label:       customField.name,
        name:        String(customField.id),
        value,
        isClearable: !customField.required,
        isDisabled:  !customField.visible || readOnly,
        isValid:     isValid === false ? false : undefined,
        options:     opts,
        hint,
        icon,
        onChange:    onFieldChange
      };
    }, [customField, readOnly, value, isValid, hint]);

    switch (customField.fieldFormat) {
      case "string":
      case "link":
        return <Field.String {...fieldProps}  />; 
      case "text":
        return <Field.TextArea {...fieldProps}  />; 
      case "int":
        return <Field.Number {...fieldProps} step={1} />; 
      case "float":
        return <Field.Number {...fieldProps} />;
      case "enumeration":
      case "list":{
        if (customField.multiple) {
          return <Field.MultiSelect {...fieldProps} placeholder="Выберите" />;
        } 
        return <Field.SingleSelect {...fieldProps} placeholder="Выберите" />;
      }
      case "date":
        return <Field.DateTime {...fieldProps}  />; 
      case "bool":
        return <Field.Boolean {...fieldProps}  />; 
      // case "attachment": // пока @mvsmirnov не сделал поддержку прикрепленных файлов
      //   return (
      //     <AttachmentField 
      //       store={store}
      //       customField={customField}
      //       attachment={value ? value : null}
      //       readOnly={readOnly}
      //       onChange={onFieldChange}
      //     />
      //   );
      case "user":
        return (
          <UserField
            store={store}
            customField={customField}
            value={value}
            readOnly={readOnly}
            onChange={onFieldChange}
          />
        );
      case "version":
        return (
          <VersionField
            store={store}
            customField={customField}
            value={value}
            readOnly={readOnly}
            onChange={onFieldChange}
          />
        );
    }
    return null;
  }
);

CustomField.propTypes = {
  store:       PropTypes.instanceOf(IssueStore), 
  readOnly:    PropTypes.bool,
  customField: PropTypes.instanceOf(CustomFieldModel),
  value:       PropTypes.any 
};

export default CustomField;
