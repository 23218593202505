import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { Components, Field } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";
import TrackerItem from "./TrackerItem";

/**
 * Wizard для создания задачи из под SidePanel инструмента  Задачи
 * В Wizard выбирается проект и доступные задачи для выбранного Проекта
 * 
 * @param {AttachedIssuesStore} store хранилище для работы с прикрепленным задачами
 * @param {Function} onСancel callback ф-я для скрытия Wizard
 * @param {Function} onСreate callback ф-я для создания новой Задачи
 */
const CreateIssueWiazard = observer(({ store, onCancel, onCreate }) => {
  const { kindsStore, accountStore } = useStores();
  const [projectUid, setProjectUid] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  
  const kindMember = useMemo(() => {
    return kindsStore.getKindMemberSync(projectUid);
  }, [projectUid]);

  const trackerItems = useMemo(() => {
    const project = store.getProjectByUid(projectUid);
    if (!projectUid) {
      return [];
    }
    return project.trackers
      .filter((tracker) => {
        const kind = kindsStore.getKind(tracker.uid);
        return kindMember && kindMember.allowedTasks.has(kind?.uid);
      })
      .map((tracker) => {
        const kind = kindsStore.getKind(tracker.uid);
        return (
          {
            icon: kind && accountStore.getIcon(kind.uid),
            tracker,
            projectUid
          }
        );
      });
  }, [projectUid, kindMember]);

  const onChangeProject = useCallback(async(value) => {
    setIsProcessing(true);
    try {
      await kindsStore.getMemberItem(value);
      setProjectUid(value);
    } finally {
      setIsProcessing(false);
    }
  }, []);

  return (
    <div className="create-issue-wizard">
      {isProcessing && <Components.Preloader size={3} className="preloader-center" />}
      <div className="create-issue-wizard-header">
        <Components.Button 
          icon="close-M"
          // color="negative"
          onPress={onCancel}
        />
      </div>
      <div className="create-issue-wizard-body">
        <Field.SingleSelect 
          name="project"
          icon=""
          label="Проект"
          placeholder="Выберите проект"
          value={projectUid}
          isClearable={false}
          isLoading={store.isProcessing}
          options={store.localProjectList.map((pr) => {
            return { value: pr.uid, label: pr.title, icon: pr.icon };
          })}
          onChange={onChangeProject}
        />
        <div className="create-issue-wizard-body-trackers">
          { !projectUid && 
            <div className="create-issue-wizard-body-trackers-text">
              <label>Выберите проект</label>
            </div>
          }
          { projectUid && trackerItems.length === 0 &&
            <div className="create-issue-wizard-body-trackers-text">
              <label>Нет доступных видов задач </label>
            </div>
          }
          {trackerItems.map((tr, i) => {
            return (
              <TrackerItem
                key={tr.uid}
                {...tr}
                index={i}
                store={store}
                onCreate={onCreate}
              />
            );
          })
          }
        </div>
      </div>
    </div>
  );
});

export default CreateIssueWiazard;
