import { computed, action, observable, runInAction } from "mobx";
import AboutApi from "../../api/aboutApi";
import packageJson from "~/../package.json";
import { servicesVersions } from "../../../../core/constants/ServiceVersions";
import semver from "semver";

export default class AboutStore {
  @observable uiVersion = packageJson.version;
  @observable uiBuild = packageJson.build;
  @observable frameworkVersion =
    packageJson.dependencies["@ais3p/ui-framework"];
  @observable isPending = true;
  @observable statuses = new Map();

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.api = new AboutApi(this.rootStore);
  }

  async getUiStatus() {
    let uiStatus = {};
    try {
      const version = await this.api.getUiStatus();
      uiStatus = version;
    } catch (error) {
      console.warn("No version.json, seems like you use local DEV server");
    }
    return uiStatus;
  }

  @action
  async init() {
    this.isPending = true;
    this.statuses = new Map();
    try {
      const statuses = await this.api.getStatus();
      const uiStatus = await this.getUiStatus();
      const statusObj = {
        "ui.spa": {
          version: (uiStatus && uiStatus.version) || this.uiVersion,
          commit:  uiStatus && uiStatus.commit,
          build:   (uiStatus && uiStatus.build) || this.uiBuild,
          alive:   true
        },
        "ui.framework": {
          version: this.frameworkVersion,
          alive:   true
        },
        "transport.gateway": {
          version: statuses.version,
          commit:  statuses.commit,
          build:   statuses.build,
          alive:   true
        }
      };
      const services = statuses.services;
      Object.keys(services).forEach((name) => {
        const value = services[name];
        if (!value) {
          statusObj[name] = {
            version: "",
            build:   "",
            commit:  "",
            alive:   false
          };
        } else {
          statusObj[name] = {
            version: value.version,
            build:   value.build,
            commit:  value.commit,
            alive:   true
          };
        }
      });
      runInAction(() => {
        this.statuses.replace(statusObj);
        this.isPending = false;
      });
      this.validateVersions();
    } catch (error) {
      this.isPending = false;
      console.warn(error);
    }
  }

  @action
  validateVersions() {
    const unCompatible = [];
    this.data.forEach((status) => {
      // отсекаем проверку сторонних сервисов
      if (status.name.indexOf("thirdparty") < 0) {
        const service = servicesVersions.find((service) => {
          return service.name === status.name;
        });
        
        if (service) {
          let isValidVersion;
          // если у сервиса из списка не указана версия, то не сравниваем (скорее всего сам сервис еще не возвращает 
          // еще свою версию)
          // не сравинваем версию с самим собой
          // так же не сравниваем версии со staging
          if (status.version === "staging" || service.version === "" || service.name === "ui.spa") {
            isValidVersion = true;
          } else {
            const isValid = semver.valid(status.version) && semver.valid(service.version);
            isValidVersion = isValid && semver.minor(status.version) === semver.minor(service.version);
          }
          if (!isValidVersion) {
            unCompatible.push({ name: status.name, isValid: isValidVersion });
          }
        }
      }
    });
    if (unCompatible.length > 0) {
      const incompatibleServices = unCompatible.map((service) => {
        return service.name;
      }).join(", ");
      this.rootStore.setErrorText(`Обнаружены несовместимые версии сервисов: ${incompatibleServices}. 
      Пожалуйста, обратитесь к администратору для обновления соответствующих сервисов.`);
    }
  }

  @computed
  get data() {
    const data = [];
    this.statuses.forEach((status, name) => {
      data.push({
        name,
        version: status.version,
        build:   status.build,
        commit:  status.commit,
        alive:   status.alive
      });
    });
    return data;
  }
}
