import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Components } from "@ais3p/ui-framework";
import IssueIcon from "../issues/IssueIcon";
import useStores from "~/core/utils/useStores";
import IssueStore from "../../stores/IssueStore";
import { IssueModel } from "../../models";
import { DOMAIN_ISSUE } from "../../../../core/constants/Domains";
import { CLS_ISSUE } from "../../../../core/constants/Classes";

/**
 * Компонент для отображения подзадач Задачи
 * 
 * @param {Object} props набор параметров
 * @param {Object} props.item набор данных о связанной задаче
 * @param {IssueModel} props.issue текущая задача
 * @param {IssueStore} props.store хранилище для работы с задачами
 * @param {Boolean} props.readOnly флаг указывающий, что файлы в спсиске только можно просмматривать
 * @param {String} props.className пользовательский className
 */
const RelatedIssueItem = observer(
  ({
    item,
    issue,
    subIssuesMap,
    store,
    readOnly,
    className
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { uiStore, layoutStore } = useStores();

    const isCurrentIssue = useMemo(() => {
      return issue.uid === item.uid;
    }, [item.uid, issue.uid]);

    const isParentIssue = useMemo(() => {
      return issue.parent && issue.parent.uid === item.uid;
    }, [item.uid, issue.parent]);

    const isClosed = useMemo(() => {
      if (isCurrentIssue) {
        return issue && issue.status && issue.status.isClosed;
      }

      const subIssue = subIssuesMap.get(item.uid);
      return subIssue && subIssue.status && subIssue.status.isClosed;
    }, [isCurrentIssue, subIssuesMap && subIssuesMap.size, item]);

    const onDoUnlink = useCallback(async() => {
      uiStore.setConfirmPending(true);
      try {
        const uid = isParentIssue ? issue.uid : item.uid;
        if (issue.isNew) {
          issue.setParent(null);
        } else {
          await store.unlinkRelatedIssue(uid, issue);
        }
        uiStore.hideConfirm();
      } finally {
        uiStore.setConfirmPending(false);
      }
    }, [isParentIssue, issue, item]);

    const onCancelUnlink = useCallback(() => {
      uiStore.hideConfirm();
    }, []);
    
    const onUnlinkClick = useCallback(() => {
      uiStore.setConfirm({
        icon:    "link-delete-M",
        title:   "Удаление связи с задачей",
        content: `Вы действительно хотите хотите открепить ${isParentIssue ? "родительскую задачу" : "подзадачу"} - 
        "${item.titlePrefix}" ?`, 
        buttons: [
          <Components.Button
            key="delete"
            text="Открепить"
            icon="link-delete-M"
            onPress={onDoUnlink}
            color="negative"
          />,
          <Components.Button
            key="cancel"
            text="Отмена"
            icon="cancel-M"
            onPress={onCancelUnlink}
            color="dark"
          />
        ],
        onKeyPressEsc:   onCancelUnlink,
        onKeyPressEnter: onDoUnlink
      });
    }, [item, issue, isParentIssue, store]);

    const onIssueItemClick = useCallback(async() => {
      if (isLoading) {
        // уже идет загрука задачи
        return;
      }
      setIsLoading(true);
      try {
        if (store.totalIssues === 0) {
          // открыта только карточки Задача. 
          // Например был переход по глобальной ссылке
          // Тогда задачу нужно отрыть в отдельном инструменте Задачи
          const titlePrefix = IssueModel.titlePrefix(item.tracker, item.id);
          const name = `${titlePrefix} - ${item.subject}`;
          const itemToOpen = {
            id:        item.uid,
            name,
            component: DOMAIN_ISSUE,
            props:     {
              id:        item.uid,
              focusUid:  item.uid,
              class:     CLS_ISSUE,
              component: DOMAIN_ISSUE
            }
          };
          if (store.activeIssue.uid !== item.uid) {
            // не отрываем саму себя
            layoutStore.open(itemToOpen);
          }
        } else {
          // карточка Задача открыта в одной из вкладок инструмента Задачи
          await store.openIssue(item.uid);
        }
      } finally {
        setIsLoading(false);
      }
    }, [isLoading, item, store.totalIssues]);
    
    return (
      <div 
        className={classNames("issue-related-issues-item ", className, {
          "current-issue": isCurrentIssue,
          "issue-closed":  isClosed
        })}
        style={{
          marginLeft: `${1.5 * item.level}rem`
        }}
      >
        <div 
          className={"issue-related-issues-item-title"}
        >
          <div 
            className="title-prefix"
            onClick={onIssueItemClick}
          >
            <IssueIcon 
              issue={item}
              isLoading={isLoading}
            />
            <label>{item.titlePrefix}</label>
          </div>
          <span>{item.title}</span>
        </div>
        {!readOnly &&
          <div className={"issue-related-issues-item-controls"}>
            {!isCurrentIssue &&
              <Components.Button
                onPress={onUnlinkClick}
                isDisabled={readOnly}
                icon="link-delete-M"
                tooltip={`Открепить ${isParentIssue ? "родительскую задачу" : "подзадачу"} - ${item.titlePrefix}`}
                color="negative"
              />
            }
          </div>
        }
      </div>
    );
  }
);

RelatedIssueItem.propTypes = {
  className: PropTypes.string,
  item:      PropTypes.object, 
  store:     PropTypes.instanceOf(IssueStore), 
  issue:     PropTypes.instanceOf(IssueModel), 
  readOnly:  PropTypes.bool 
};

export default RelatedIssueItem;
