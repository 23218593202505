import LoggerContainer from "./LoggerContainer";
import { logger } from "./logger";
import useLogger from "./useLogger";

export {
  LoggerContainer,
  useLogger
};

export default logger;
