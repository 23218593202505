import React from "react";

/**
 * Контекст для получения состояния Logger и возможность обрабатывать ошибку
 * 
 * @type Context
 */
const context = React.createContext({
  /**
   * Логгер для логирования событий
   * 
   * @type {Logger}
   */
  logger: undefined,

  /**
   * Ф-я для получения стека логгера
   * @type {Function}
   */
  getStackData: () => {},

  /**
   * Ф-я для обработки ошибки
   * 
   * @type {Function}
   */
  onError: () => {}
});

export default context;
