import React, { useEffect, useMemo, useCallback, useState } from "react";
import { observer } from "mobx-react";
import "react-table/react-table.css";

import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";

import RelationUIStore from "../stores/relationUIStore";
import KindGroup from "../components/KindGroup";
import AddRelation from "../components/AddRelation";

import { getPropsToOpenLayoutTool } from "~/core/utils";
import infoToolContent from "~/core/components/InfoToolWindow/infoToolContent.js";
import { Components, ContextMenu, Notification } from "@ais3p/ui-framework";

import "./css/index.scss";
import { SIDEPANEL_RELATIONS } from "~/core/constants/SidePanels";


/**
 * @component
 * 
 * Боковая панель для просмотра/редактирования связей с объектом АИС
 * 
 * @param {String} uid uid активного элемента в АИС
 * @param {Number} version версия активного элемента в АИС
 * @param {Object} trackedItem активный элемент в АИС
 * @param {Boolean} hasTarget 
 */

const ConnectionSidepanel = observer((props) => {
  const { relationStore, objectStore, uiStore, layoutStore } = useStores();
  const { uid, version, trackedItem } = props;

  const menuCollect = useCallback(
    (props) => {
      layoutStore.menuCollect(props);
    },
    [layoutStore]
  );

  const menuItems = useMemo(() => {
    const items = [{
      icon:   "open-M",
      type:   "read",
      title:  "Открыть",
      action: "open"
    }];
    // удалять связь можем только в редакции (версия > 0)
    if (trackedItem && !trackedItem.version) {
      items.push({
        icon:   "delete-M",
        type:   "delete",
        title:  "Удалить",
        action: "delete"
      });
    }
    return items;
  }, [trackedItem && trackedItem.version]);

  const [expandToAddKindId, setExpandToAddKindId] = useState(null);

  const relationUIStore = useMemo(() => {
    return new RelationUIStore(relationStore);
  }, []);

  const onOpenItem = useCallback(async(item) => {
    const tool = item.tool || item.domain;
    const toolToOpen = {
      component: tool
    };

    const itemToOpen = await getPropsToOpenLayoutTool(item, objectStore);

    if (!itemToOpen) {
      Notification.warning(
        "Вы пытаетесь перейти к артефакту, который недоступен. Возможно, у Вас нет прав доступа к нему.", 
        { autoClose: 3000 }
      );
      return;
    }

    layoutStore.open({ ...itemToOpen, ...toolToOpen });
  }, []);

  // useEffect(() => {
  //   if (!uid) return;
  //   relationUIStore.init(uid, version);
  // }, [uid, version]);

  useEffect(() => {
    if (!trackedItem) {
      return;
    }
    if (!trackedItem.uid || !trackedItem.class) {
      return;
    }
    relationUIStore.init(trackedItem.uid, trackedItem.version, trackedItem.class);
  }, [trackedItem]);

  const {
    isPending,
    hasConnections,
    relationsByKindArray,
    relationsByKindArrayLength
  } = relationUIStore;

  const plusToggle = useCallback(
    (id) => {
      setExpandToAddKindId(id);
    },
    [setExpandToAddKindId]
  );

  const onDoDeleteRelation = async(item, kind) => {
    uiStore.setConfirmPending(true);
    try {
      await relationStore.deleteHalf(item, kind);
      uiStore.hideConfirm();
    } finally {
      uiStore.setConfirmPending(false);
    }
  };

  const onCancelDeleteRelation = useCallback(() => {
    uiStore.hideConfirm();
  }, []);

  const onDeleteRelation = useCallback((item, kind) => {
    uiStore.setConfirm({
      icon:    "link-delete-M",
      title:   "Удаление связи",
      content: "Вы уверены что хотите удалить связь?", 
      buttons: [
        <Components.Button
          key="delete"
          text="Удалить"
          icon="link-delete-M"
          // eslint-disable-next-line react/jsx-no-bind
          onPress={() => {
            onDoDeleteRelation(item, kind);
          }}
          color="negative"
        />,
        <Components.Button
          key="cancel"
          text="Отмена"
          icon="cancel-M"
          onPress={onCancelDeleteRelation}
          color="dark"
        />
      ],
      onKeyPressEsc:   onCancelDeleteRelation,
      onKeyPressEnter: () => {
        onDoDeleteRelation(item, kind);
      }
    });      
  }, []);
  
  const relations = useMemo(() => {
    if (!hasConnections || !uid) {
      return (
        <div className="header">
          <span className="text-holder">Объект не имеет связей</span>
        </div>
      );
    }

    const relations = relationsByKindArray.map(({ kind, items }) => {
      return (
        <KindGroup
          targetInfo={trackedItem}
          menuCollect={menuCollect}
          relationUIStore={relationUIStore}
          key={kind.id}
          kind={kind}
          items={items}
          plusToggle={plusToggle}
          expandToAddKindId={expandToAddKindId}
          onOpenItem={onOpenItem}
          onDeleteRelation={onDeleteRelation}
        />
      );
    });
    return relations;
  }, [
    uid,
    relationsByKindArray,
    relationsByKindArrayLength,
    hasConnections,
    expandToAddKindId
  ]);

  const onMenuItemClick = useCallback((action, context) => {
    const { item, kind } = context;
    if (action === "open") {
      onOpenItem(item);
    }
    if (action === "delete") {
      onDeleteRelation(item, kind);
    }
  }, []);

  return (
    <div className={"connection-tool-holder"}>
      <div className={"connection-tracker"}>
        <Target
          id={uid} version={version} trackedItem={trackedItem}
          info={infoToolContent.relations}
        />
        <div className="list-holder">
          <div className="items-holder">
            {!!trackedItem && (
              <AddRelation
                uid={uid}
                targetInfo={trackedItem}
                relationUIStore={relationUIStore}
              />
            )}
            {isPending && (
              <div className="preload-wrapper">
                <Components.Preloader size={3} />
              </div>
            )}
            {relations}
          </div>
        </div>
      </div>
      <ContextMenu.Menu
        id={SIDEPANEL_RELATIONS} 
        items={menuItems} 
        onMenuClick={onMenuItemClick}
      />
    </div>
  );
});

export default ConnectionSidepanel;
