import React, { useCallback, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "./Button";
import PageSizeButton from "./PageSizeButton";
import { getRemSizeInPx } from "~/core/utils";
import "./css/index.scss";


/**
 * Компонент для пагинации записей
 * @class Pagination
 */
const Pagination  = (props) => {
  const {
    /**
     * С какого индекса начинается первая страница - с нулевого или первого
     * @type {Number}
     */
    startPageIndex = 0,

    /**
     * Пользовательский className
     * @type {String}
     */
    className,

    /**
     * Флаг - можно ли сделать переход на предыдущую страницу
     * @type {Boolean}
     */
    canPrevious,

    /**
     * Флаг - можно ли сделать переход на следующую страницу
     * @type {Boolean}
     */
    canNext,

    /**
     * Ф-я для перехода на указаную страницу
     * @type {Function}
     */
    onPageChange,

    /**
     * Кол-во записей  на странице
     * @type {Number}
     */
    pageSize,

    /**
     * Ф-я для задания кол-ва записей  на странице
     * @type {Function}
     */
    onPageSizeChange,

    /**
     * Ф-я для обновления записей  на странице
     * @type {Function}
     */
    reload,

    /**
     * Номер текущей страницы
     * @type {Number}
     */
    page,

    /**
     * Кол-во страниц
     * @type {Number}
     */
    pages,

    /**
     * Текст строки - страница
     * @type {String}
     */
    pageText = "Страница",

    /**
     * Текст строки - из
     * @type {String}
     */
    ofText = "из",

    /**
     * Текст строки - Группировать по
     * @type {String}
     */
    pageSizeTitle = "Группировать по",

    /**
     * Набор возможных значений для установления кол-ва записей на странице
     * @type {Array <Number>}
     */
    pageSizeOptions = [20, 50, 100],

    /**
     * Информация о страницах
     * @type {String}
     */
    pageInfo = null

  } = props;

  const elRef = useRef(null);
  let sensor;

  useEffect(() => {
    if (elRef.current) {
      // Вешаем обработчик события на изменение размеров
      sensor = new ResizeObserver((entries) => {
        // Оборачиваю в requestAnimationFrame чтобы избежать ошибки - "ResizeObserver loop limit exceeded"
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          onResize();
        });
      });
      sensor.observe(elRef.current, { box: "border-box" });
    }

    return () => {
      if (elRef.current) {
        sensor.unobserve(elRef.current);
      }
    };
  }, [elRef]);

  const [small, setSmall] = useState(false);

  const onResize = useCallback(() => {
    if (!elRef.current) {
      return;
    }
    // получаем ширину контейнера
    const rect = elRef.current.getBoundingClientRect();
    const { width } = rect;
    const widthInRem = (width / getRemSizeInPx());

    if (width === 0) {
      return;
    }

    // Если ширина пагинации меньше 31.5rem, то выставляем className=small
    setSmall(widthInRem < (reload ? 35 : 31.5));
  }, [reload, elRef]);

  const getSafePage = (pg) => {
    let p = pg;
    if (Number.isNaN(p)) {
      p = page;
    }
    const res = Math.min(Math.max(p, startPageIndex), startPageIndex === 0 ? pages - 1 : pages);
    return res;
  };

  const onFirstPage = useCallback(() => {
    if (onPageChange) {
      onPageChange(startPageIndex);
    } else {
      console.error("Метод `onPageChange` не задан в Pagination!");
    }
  }, [onPageChange]);

  const onPreviousPage = useCallback(() => {
    if (onPageChange) {
      onPageChange(getSafePage (page - 1));
    } else {
      console.error("Метод `onPageChange` не задан в Pagination!");
    }
  }, [onPageChange, page, pages]);

  const onNextPage = useCallback(() => {
    if (onPageChange) {
      onPageChange(getSafePage (page + 1));
    } else {
      console.error("Метод `onPageChange` не задан в Pagination!");
    }
  }, [onPageChange, page, pages]);

  const onLastPage = useCallback(() => {
    if (onPageChange) {
      onPageChange(startPageIndex === 0 ? pages - 1 : pages);
    } else {
      console.error("Метод `onPageChange` не задан в Pagination!");
    }
  }, [onPageChange, pages]);

  const onChangePageNumber = useCallback((e) => {
    const p = getSafePage(startPageIndex === 0 ? e.target.value - 1 : e.target.value);
    if (onPageChange) {
      onPageChange(p);
    } else {
      console.error("Метод `onPageChange` не задан в Pagination!");
    }
  }, [onPageChange, pages]);

  const onChangePageSize = useCallback((size) => {
    if (onPageSizeChange) {
      onPageSizeChange(Number(size));
    } else {
      console.error("Метод `onPageSizeChange` не задан в Pagination!");
    }
  }, [onPageSizeChange]);

  const onReloadData = useCallback(() => {
    if (reload) {
      reload();
    } else {
      console.error("Метод `onReload` не задан в Pagination!");
    }
  }, [reload, onPageSizeChange]);

  return (
    <div
      ref={elRef}
      className={classNames("pagination", className, {
        small
      })}
    >
      <div className="pagination-controls">
        <div className="pagination-buttons-group">
          <Button
            icon={"backwards-M"}
            tooltip={"Первая страница"}
            onClick={onFirstPage}
            disabled={!canPrevious}
            isOutline={true}
          />
          <Button
            icon={"arrow-left-M"}
            tooltip={"Предыдущяя страница"}
            onClick={onPreviousPage}
            disabled={!canPrevious}
          />
        </div>
        <div className="pagination-pages-info">
          <div className="page-title">
            {pageText}
          </div>
          <input
            type="number"
            // defaultValue={page}
            value={startPageIndex === 0 ? page + 1 : page}
            onChange={onChangePageNumber}
          />
          <div className="pages-title">
            {`${ofText} ${pages}`}
          </div>
          {pageInfo &&
            <div className="pages-title">
              {pageInfo}
            </div>
          }
        </div>
        <div className="pagination-buttons-group">
          <Button
            icon={"arrow-right-M"}
            tooltip={"Следующяя страница"}
            onClick={onNextPage}
            disabled={!canNext}
          />
          <Button
            icon={"forward-M"}
            tooltip={"Последняя страница"}
            onClick={onLastPage}
            disabled={!canNext}
          />
          {reload &&
            <Button
              icon={"refresh-M"}
              tooltip={"Обновить страницу"}
              onClick={onReloadData}
            />
          }
        </div>
      </div>
      <div className="pagination-page-size">
        <div className="page-size-title">
          {pageSizeTitle}
        </div>
        <div className="page-sizes">
          {pageSizeOptions.map((size) => {
            return (
              <PageSizeButton
                key={size}
                active={size === pageSize}
                size={size}
                onClick={onChangePageSize}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  startPageIndex:   PropTypes.number,
  className:        PropTypes.string,
  canPrevious:      PropTypes.bool,
  canNext:          PropTypes.bool,
  onPageChange:     PropTypes.func,
  pageSize:         PropTypes.number,
  onPageSizeChange: PropTypes.func,
  reload:           PropTypes.func,
  page:             PropTypes.number.isRequired,
  pages:            PropTypes.number.isRequired,
  pageText:         PropTypes.string,
  ofText:           PropTypes.string,
  pageSizeTitle:    PropTypes.string,
  pageSizeOptions:  PropTypes.arrayOf(Number),
  pageInfo:         PropTypes.string
};

export default Pagination;
