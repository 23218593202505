import React, { useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";
import IssueIcon from "../issues/IssueIcon";
import useStores from "~/core/utils/useStores";

/**
 * Компонент для отображения записи  найденной задачи
 * 
 * @param {AttachedIssuesStore} store хранилище для работы с прикрепленным задачами
 * @param {IssueModel} item запись найденной задачи
 * @param {Object} target активный АИС  объектом 
 */
const FoundedIssueItem = observer(({ store, item, target }) => {
  const [isPending, setIsPending] = useState(false);
  const { uiStore,  configStore } = useStores();
  const config =  configStore.getToolConfig();
  const { taskConfig } = config;
  
  const relationCfg = useMemo(() => {
    if (!item?.tracker?.uid || !taskConfig) {
      return null;
    }
    return taskConfig[item?.tracker?.uid];
  }, [taskConfig, item?.tracker?.uid]);

  const warningTooltip = useMemo(() => {
    const msg = [];
    if (!item?.tracker?.uid) {
      msg.push("Нет связи между трекером Redmine и Видом АИС!");
      return msg;
    }

    if (!relationCfg?.relationKindUid) {
      msg.push("Не задан тип связи для привязки Задачи!");
    }
    if (!relationCfg?.direction) {
      msg.push("Не задано направление связи для привязки Задачи или для вида Задачи не назначен соответсвующий класс!");
    }
    if (msg.length > 0) {
      return msg;
    }

    return null;
  }, [relationCfg, item?.tracker]);

  const onCancelUnlink = useCallback(() => {
    uiStore.hideConfirm();
  }, []);

  const onLinkIssue = useCallback(() => {
    if (!item || !target) {
      return;
    }
    doLink(item, target);
  }, [target, item, relationCfg]);

  const onUnlinkIssue = useCallback(() => {
    if (!item || !target) {
      return;
    }
    uiStore.setConfirm({
      icon:    "link-delete-M",
      title:   "Удаление связи",
      content: "Вы уверены, что хотите удалить связь c задачей?", 
      buttons: [
        <Components.Button
          key="delete"
          text="Удалить"
          icon="link-delete-M"
          onPress={doUnlink}
          color="negative"
        />,
        <Components.Button
          key="cancel"
          text="Отмена"
          icon="cancel-M"
          onPress={onCancelUnlink}
          color="dark"
        />
      ],
      onKeyPressEsc:   onCancelUnlink,
      onKeyPressEnter: doUnlink
    });
  }, [target, item]);

  const doLink = async(item, target) => {
    if (!relationCfg) {
      return;
    }
    setIsPending(true);
    try {
      const { relationKindUid, direction } = relationCfg;
      if (!relationKindUid || !direction) {
        return;
      }

      const  res = await store.linkAttachedIssue(item, target, relationKindUid, direction);
      if (res) {
        await store.loadAttachedIssues(target.uid || target.id, target.version);
      }
    } catch (ex) {
      store.onError(ex);
    } finally {
      setIsPending(false);
    }
  };

  const doUnlink = async() => {
    setIsPending(true);
    try {
      const { uid: issueUid } =  item;
      const attachedIssue = store.getAttahcedIssue(issueUid);
      const res = await store.unlinkAttachedIssue(attachedIssue);
      
      res && uiStore.hideConfirm();
    } catch (ex) {
      store.onError(ex);
    } finally {
      setIsPending(false);
    }
  };

  // const warning

  return (
    <div className="search-issue-item">
      <div className="search-issue-item-icon">
        <IssueIcon store={store} issue={item} isLoading={isPending} />
      </div>
      <div className="search-issue-item-prefix">
        {item.titlePrefix}
      </div>
      <div className="search-issue-item-title">
        {item.title}
      </div>
      {
        store.attachedIssuesMap.has(item.uid) &&
        <Components.Button
          icon="link-delete-M"
          tooltip="Отвязать задачу"
          color="negative"
          isLoading={isPending}
          isDisabled={isPending}
          onPress={onUnlinkIssue}
        />
      }
      {warningTooltip && !store.attachedIssuesMap.has(item.uid) &&
        <Components.Button
          icon="warning-M"
          tooltip={warningTooltip.map((str, i) => {
            return <div key={i}>{str}</div>;
          })}
          // color="action"
          isLoading={false}
          isDisabled={true}
        />
      }
      {
        !store.attachedIssuesMap.has(item.uid) && !warningTooltip &&
        <Components.Button
          icon="link-start-M"
          tooltip="Связать задачу"
          color="action"
          isLoading={isPending}
          isDisabled={isPending}
          onPress={onLinkIssue}
        />
      }
    </div>
  );
});

export default FoundedIssueItem;
