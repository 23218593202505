import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Components } from "@ais3p/ui-framework";
import "./css/reload-btn.scss";

const ReloadBtn = ({ onReload, errorText }) => {
  const handleClick = useCallback(() => {
    onReload();
  }, [onReload]);
  
  return (
    <div className="reload-btn-notify">
      {errorText} 
      <span>Требуется перезапуск</span>
      <Components.Button
        text="Подтвердить"
        onPress={handleClick}
      />
    </div>
  );
};

ReloadBtn.propTypes = {
  onReload:  PropTypes.func.isRequired,
  errorText: PropTypes.string.isRequired
};


export default ReloadBtn;
