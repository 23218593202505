import { action, computed, observable, toJS } from "mobx";
import { DOMAIN_KINDS, DOMAIN_TEXT } from "../../../core/constants/Domains";
import AisVersion from "../../../core/data/models/AisVersion";
import ObjectStore from "../../../core/data/stores/objectStore";
import DataStore from "../stores/DataStore";

class BaseTextObject extends AisVersion {
  @observable
  uid = null;
  @observable
  path = [];
  @observable
  parent = null;
  @observable
  className = null;
  @observable
  store = null;
  @observable
  lockPromise = null;
  @observable
  pending = false;
  @observable
  expanded = false;
  @observable
  isNew = false;
  @observable
  deletePromise = null;
  @observable
  creationPromise = null;

  @observable
  zeroHeightClassSet = new Set(["text.element.Enumeration", "text.container.EnumerationItem"]);

  @observable
  diffType = null;

  @observable
  usedInWizard = false;

  @observable
  height = 30;

  @observable
  lockData = null;

  constructor(uid, version = 0, store, validations) {
    let objStore;
    let textStore = undefined;
    if (store) {
      if (store instanceof ObjectStore) {
        objStore = store;
      }

      if (store instanceof DataStore) {
        objStore = store.objectStore;
        textStore = store;
      }
    }

    super({ uid, version, domain: DOMAIN_TEXT, validations }, objStore);
    this.setStore(textStore);
  }

  @action
  init(data, parent) {
    if (parent) {
      this.setParent(parent);
    }
    this.className = data.class;
    
    this.setOrigin(data.origin);

    this.setHeight();
  }

  @action
  update(data) {
    if (!!data.value) {
      this.value = data.value;
    }

    this.setOrigin(data.origin);
  }

  @action
  setDiffType(type) {
    const ignoredDiffTypes = new Set([
      "text.diff.version.change.child.added",
      "text.diff.version.change.child.removed",
      "text.diff.version.change.child.moved",
      "text.diff.version.change.descendant.added",
      "text.diff.version.change.descendant.removed",
      "text.diff.version.change.descendant.moved",
      "text.diff.version.change.value.changed.chunk.changed",
      "text.diff.version.change.descendant.value.changed",
      "text.diff.version.child.added",
      "text.diff.version.child.removed",
      "text.diff.version.child.moved",
      "text.diff.version.descendant.added",
      "text.diff.version.descendant.removed",
      "text.diff.version.descendant.moved",
      "text.diff.version.descendant.changed",
      "text.diff.version.chunk.added",
      "text.diff.version.chunk.removed"
    ]);
    if (!ignoredDiffTypes.has(type)) {
      this.diffType = type;
    }
  }

  @action
  setPath(path = []) {
    this.path = path;
  }

  @action
  async saveKinds() {
    if (this.kindsRepresentation) {
      await this.kindsRepresentation.save();
    }
  }
  
  @action
  async persistCreate() {
    if (this.store && this.parent && this.parent.slug && this.category) {
      await this.parent.creationPromise;
      this.creationPromise = this.store.api.addElement(
        this.parent.slug,
        this.parent.uid,
        this.category,
        this.output
      );
      const data = await this.creationPromise;
      this.setNew(false);
      this.store.updateItems(data);

      // const promises = [];
      // if (this.items && this.items.length) {
      //   this.items.forEach((item) => {
      //     promises.push(item.persistCreate());
      //   });
      //   await Promise.all(promises);
      // }
    }
  }

  @action
  persistDelete() {
    if (this.store && !this.isNew && !this.deletePromise) {
      this.deletePromise = this.store.api.deleteElement(this.uid, this.slug);
    }
    return this.deletePromise;
  }

  @action
  setNew(isNew) {
    this.isNew = isNew;
  }

  @action
  setStore(store) {
    this.store = store;
  }

  @action
  setExpanded(expanded = false) {
    this.expanded = expanded;
  }

  @action
  setParent(parent) {
    this.parent = parent;
  }

  @action
  async lock() {
    if (
      !this.lockData 
      && !this.version 
      && !this.isLocked 
      && !this.isParentLocked 
      && !this.isChildLocked 
      && !this.isParentLockedByMe 
      && !this.isChildLockedByMe
    ) {
      this.lockPromise = this.setLockPromise();
    }
  }

  @action
  async setLockPromise() {
    const lockInfo = await this.store.setLock(this.uid);
    this.setLockData(lockInfo);
  }

  @action
  async unlock() {
    await this.lockPromise;
    if (this.isLockedByMe) {
      await this.store.unsetLock(this.lockData.uid);
      this.setLockData();
    }
  }

  @action
  setLockData(data = null) {
    this.lockData = data;
    this.lockPromise = null;
  }

  @action
  async setEditing(offset, noFocus = false) {
    this.store.setEditingChunkId(this.uid, offset);    
    if (!noFocus) {
      this.store.setFocusUid(this.availableFocusUid);
    }
    this.lock();
  }

  @action
  setFocusUid() {
    if (this.isCaption && this.parent) {
      this.parent.setFocusUid();
      return;
    }
    this.store.setFocusUid(this.uid);
  }

  @action
  setDelta(delta) {
    this.store.setDelta(delta);
  }

  @action
  setOffset(offset) {
    this.store.setDelta(offset);
  }

  @action
  selfDestruct(stopPropagation = false) {
    if (this.parent) {
      this.parent.delete(this.uid, stopPropagation);
    }
  }

  @action
  async createItem(item, kind) {
    if (!item || !this.store) {
      return null;
    }
    const { ancorId, isParent, isSibling } = item;
    const id = this.store.getUid();
    let newItem = null;
    if (isParent) {
      const parent = this.getItemById(ancorId);
      newItem =
        parent &&
        parent.createAfter(null, {
          ...item,
          uid: id
        }, kind);
    } else if (!ancorId) {
      newItem = this.parent.createAfter(this.uid, {
        ...item,
        uid: id
      }, kind);
    } else if (isSibling) {
      const storeItem = this.getItemById(ancorId);
      const parent = storeItem && storeItem.parent;
      newItem =
        parent &&
        parent.createAfter(ancorId, {
          ...item,
          uid: id
        }, kind);
    }
    if (newItem) {
      await newItem.persistCreate(item.forceEmpty);
    }

    return newItem;
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  // virt list countings
  @action
  setHeight(height = 30) {
    if (this.zeroHeightClassSet.has(this.className)) {
      this.height = 0;
    } else if (!height !== this.height && height > 0) {
      this.height = height;
    }
  }

  @action
  setBoundingIndex() {
    this.store.setBoundingIndex(this.indexInFlatList);
  }
  @action
  unsetBoundingIndex() {
    this.store.unsetBoundingIndex(this.indexInFlatList);
  }

  getItemById(id) {
    if (this.store) {
      return this.store.getItemById(id);
    }

    if (this.objectStore) {
      return this.objectStore.getVersion(id, DOMAIN_TEXT, this.version);
    }

    return undefined;
  }

  @action
  scrollToItemById(id) {
    this.store && this.store.scrollToItemById(id);
  }

  @action
  setIsUsedInWizard(isUsed = false) {
    this.usedInWizard = isUsed;
  }

  @action // dummie function
  createCaption() {}

  @computed
  get isLocked() {
    return !!this.lockData;
  }

  @computed
  get plainText() {
    return this.value;
  }

  @computed
  get isLockedByMe() {
    return (!!this.lockData && !!this.store && this.lockData.subject === this.store.rootStore.accountStore.uid);
  }

  @computed
  get isParentLocked() {
    return !!this.parent && (this.parent.isLocked || this.parent.isParentLocked);
  }

  @computed
  get isChildLocked() {
    return false;
  }

  @computed
  get isChildLockedByMe() {
    return false;
  }

  @computed
  get isParentLockedByMe() {
    return !!this.parent && (this.parent.isLockedByMe || this.parent.isParentLockedByMe);
  }

  @computed
  get pathSet() {
    return new Set(toJS(this.countedPath));
  }

  @computed
  get countedPath() {
    if (!this.parent || !this.parent.countedPath) {
      return [this.uid];
    }
    return [...this.parent.countedPath, this.uid];
  }

  @computed
  get editableUid() {
    return this.store.editable;
  }

  @computed
  get styleVersion() {
    return this.store.styleVersion;
  }

  @computed
  get scrollItemId() {
    return this.store.scrollItemId;
  }

  @computed
  get additionalClasses() {
    const classArray = [`style-version-${this.styleVersion}`, `level-${this.level}`];
    if (this.parent && this.parent.isFirstRow) {
      classArray.push("top-row");
    }
    if (this.isOrderedItem) {
      classArray.push("ordered-list-item");
    }
    return classArray.join(" ");
  }

  @computed
  get diffClass() {
    if (this.parent && this.parent.diffClass) {
      return this.parent.diffClass;
    }
    if (!this.diffType) {
      return "";
    }
    if (this.diffType.indexOf("added") >= 0) {
      return "added";
    }
    if (this.diffType.indexOf("removed") >= 0) {
      return "removed";
    }
    if (this.diffType.indexOf("changed") >= 0) {
      return "changed";
    }
  }

  @computed
  get diffCompatitor() {
    if (!this.store.diffStore || this.store.diffStore.isPending) {
      return null;
    }
    return this.store.diffStore.getItemById(this.uid);
  }

  @computed
  get isUsedInWizard() {
    return this.usedInWizard;
  }

  @computed
  get paintChildrenFocused() {
    return this.parent && this.parent.paintChildrenFocused;
  }

  @computed
  get isFocusUid() {
    return this.store.focusUid === this.uid || (this.parent && this.parent.paintChildrenFocused);
  }

  @computed
  get flatParentId() {
    if (this.indexInFlatList !== undefined) {
      return this.uid;
    }
    return this.parent.flatParentId;
  }

  @computed
  get topmostKnownParentUid() {
    if (this.path && this.path.length) {
      return this.path[0];
    }
    if (!this.store) {
      // элемент был загружен в отдельном инстрменте без инициализации самого документа
      return undefined;
    }
    if (this.store.rootId) {
      return this.store.rootId;
    }
    return this.uid;
  }

  @computed
  get indexInFlatList() {
    if (!this.store) {
      return 0;
    }
    return this.store.flatItemsIndexMap[this.uid];
  }

  @computed
  get top() {
    if (!this.store || this.store.isDiffStore) {
      return 0;
    }
    if (this.store.isLongList) {
      return this.indexInFlatList * 50;
    }
    const prevItem = this.store.flatItemsArray[this.indexInFlatList - 1];
    return (prevItem && prevItem.bottom) || 0;
  }

  @computed
  get bottom() {
    if (!this.store || this.store.isDiffStore) {
      return 0;
    }
    return (this.top + this.height) || 0;
  }

  // end  virt list countings

  @computed
  get isEdiatbleChunk() {
    return (
      this.className === "text.chunk.Varchar" ||
      this.className === "text.chunk.Formula"
    );
  }

  @computed
  get canBeFocused() {
    const focusableClassSet = new Set([
      "text.container.Rubric",
      "text.element.Indent",
      "text.element.Picture",
      "text.element.Formula",
      "text.element.Caption",
      "text.element.Enumeration",
      "text.container.EnumerationItem"
    ]);
    return focusableClassSet.has(this.className) && !this.isCaption;
  }

  @computed
  get nextSiblingId() {
    if (this.isLast) {
      return null;
    }
    return (
      this.parent &&
      this.parent.idsArray &&
      this.parent.idsArray.length > this.position + 1 &&
      this.parent.idsArray[this.position + 1]
    );
  }

  @computed
  get nextSibling() {
    if (this.isLast) {
      return null;
    }
    return this.store.getItemById(this.nextSiblingId) || null;
  }

  @computed
  get prevSiblingId() {
    if (!this.store) {
      return null;
    }
    if (this.isFirst) {
      return null;
    }
    return this.position - 1 >= 0 && this.parent.idsArray[this.position - 1];
  }

  @computed
  get prevSibling() {
    if (!this.store) {
      return null;
    }
    if (this.isFirst) {
      return null;
    }
    return this.getItemById(this.prevSiblingId) || null;
  }

  @computed
  get isText() {
    return false;
  }

  @computed
  get tree() {
    return null;
  }

  @computed
  get captionTitle() {
    return this.title;
  }

  @computed
  get title() {
    return "";
  }

  @computed
  get flatTreeItemsArray() {
    return [];
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get isSplitable() {
    return false;
  }

  @computed
  get desiredFocusUid() {
    if (this.hasKinds) {
      return this.uid;
    }
    if (this.parent && this.parent.desiredFocusUid) {
      return this.parent.desiredFocusUid;
    }
    return null;
  }

  @computed
  get availableFocusUid() {
    if (this.desiredFocusUid) {
      return this.desiredFocusUid;
    }
    if (this.canBeFocused) {
      return this.uid;
    }
    if (this.parent && this.parent.availableFocusUid) {
      return this.parent.availableFocusUid;
    }
    return null;
  }

  @computed
  get level() {
    return this.parent && !this.parent.isStore ? this.parent.level : 0;
  }

  @computed
  get isFirst() {
    return (
      this.parent &&
      this.parent.idsArray.length &&
      this.uid === this.parent.idsArray[0]
    );
  }

  @computed
  get indexNumber() {
    let index = 1;
    if (!this.parent || !this.parent.idsArray) {
      return 0;
    }
    let foundItem = false;
    this.parent.idsArray.forEach((itemId) => {
      const item = this.getItemById(itemId);
      if (this.uid === itemId) {
        foundItem = true;
      }
      if (item && item.className === this.className && !foundItem) {
        index += 1;
      }
    });

    return index;
  }

  @computed
  get position() {
    let index = 0;
    if (!this.parent || !this.parent.idsArray || this.parent.childrenLength <= 0) {
      return -1;
    }
    
    index = this.parent.idsArray.findIndex((item) => {
      return item === this.uid;
    });

    return index;
  }

  @computed
  get isLast() {
    return (
      this.uid ===
      (this.parent &&
        this.parent.idsArray &&
        this.parent.idsArray.length &&
        this.parent.idsArray[this.parent.idsArray.length - 1])
    );
  }

  @computed
  get isExpanded() {
    return this.isFocusUid || this.expanded;
  }

  @computed
  get hasKinds() {
    if (this.isUsedInWizard) {
      return false;
    }

    if (
      this.object 
      && this.object.domains 
      && this.object.domains.size > 0 
      && this.kindsRepresentation 
      && this.kindsRepresentation.kindsArray
    ) {
      return !!this.kindsRepresentation.kindsArray.length;
    }


    let kindsObj = null;
    if (this.objectStore) {
      kindsObj = this.objectStore.getVersion(
        this.uid,
        DOMAIN_KINDS,
        this.version
      );
    }
    return !!kindsObj && !!kindsObj.kindsArray.length;
  }

  @computed
  get hasRelations() {
    let relationStore;
    if (this.store) {
      relationStore = this.store.rootStore.relationStore;
    } else if (this.objectStore) {
      relationStore = this.objectStore.rootStore.relationStore;
    }
    if (!relationStore) {
      return false;
    }
    if (relationStore.isPending) {
      return false;
    }
    const relsArray = relationStore.getGroupedConnections(
      this.uid,
      this.version
    );
    return !!relsArray.length;
  }

  @computed
  get nextSiblingIsRubric() {
    return false;
  }

  @computed
  get isOnlyChild() {
    return this.isFirst && this.isLast;
  }

  @computed
  get editable() {
    return this.store && this.store.editable;
  }

  @computed
  get isVersion() {
    if (!this.store) {
      return false;
    }
    return this.store.isVersion;
  }

  @computed
  get delta() {
    if (!this.store) {
      return null;
    }
    return this.store.delta;
  }

  @computed
  get offset() {
    if (!this.store) {
      return 0;
    }
    return this.store.editingChunkOffset;
  }

  @computed
  get shortClass() {
    return this.className.split(".")[this.className.split(".").length - 1];
  }

  @computed
  get allowedChildrenTypes() {
    if (this.libItem && this.libItem.kindsRepresentation && this.libItem.kindsRepresentation.allowedTypes) {
      return this.libItem.kindsRepresentation.allowedTypes;
    }
    if (this.kindsRepresentation && this.kindsRepresentation.allowedTypes) {
      return this.kindsRepresentation.allowedTypes;
    }
    if (!this.store) {
      return new Set();
    }
    const types = this.store.typeCreate[this.className];
    if (!types) {
      return new Set();
    }
    const typesSet = new Set(
      types.map((type) => {
        return type.as;
      })
    );
    return typesSet;
  }

  @computed
  get allowedChildrenKinds() {
    if (this.libItem && this.libItem.kindsRepresentation && this.libItem.kindsRepresentation.allowedKinds) {
      return this.libItem.kindsRepresentation.allowedKinds;
    }    
    if (!(this.kindsRepresentation && this.kindsRepresentation.allowedKinds)) {
      if (!this.store) {
        return new Set();
      }
      const classAllows = this.store.rootStore.configStore.classAllows(this.className);
      return new Set((classAllows && classAllows.allowedKinds) || []);
    }
    return this.kindsRepresentation.allowedKinds;
  }

  @computed
  get defaultItemsArray() {
    if (!this.parent) {
      return [];
    }
    return this.parent.defaultInnerItemsArray || [];
  }

  @computed
  get defaultInnerItemsArray() {
    return this.defaultItemsArray.map((item) => {
      const availableKindsArray = [];

      if (this.allowedChildrenTypes.has(item.class)) {
        availableKindsArray.push({ kind: "type-only", title: "", icon: null });
      }
  
      if (
        this.store.typeToKindMapping[item.class] && 
        this.store.typeToKindMapping[item.class].length
      ) {
        this.store.typeToKindMapping[item.class].forEach((kind) => {
          if (this.allowedChildrenKinds.has(kind.kind)) {
            availableKindsArray.push(kind);
          }
        });
      }

      return { ...item, availableKindsArray, isParent: true, ancorId: this.uid };
    });
  }
  
  @computed
  get parentDefaultItemsArray() {
    return this.parent.defaultInnerItemsArray.map((item) => {
      return { ...item, isParent: false, ancorId: this.uid, isSibling: true };
    });
  }

  @computed
  get hierarchyItemsArray() {
    return [];
  }

  @computed
  get innerHierarchyItemsArray() {
    return [];
  }

  @computed
  get availableItemsArray() {
    if (!this.parent) {
      return [];
    }
    if (this.isLocked && !this.isLockedByMe) {
      return [].concat(
        this.parent.hierarchyItemsArray,
      );
    }
    if (this.isParentLocked && !this.isParentLockedByMe) {
      return [];
    }
    if (this.isLast) {
      return [].concat(
        this.parent.hierarchyItemsArray,
        this.parent.innerHierarchyItemsArray,
        this.parentDefaultItemsArray
      );
    }
    if (this.nextSiblingIsRubric) {
      return [].concat(
        this.parent.innerHierarchyItemsArray,
        this.parentDefaultItemsArray
      );
    }
    return this.parentDefaultItemsArray || [];
  }

  @computed
  get removedDescendantListItemsArray() {
    return [];
  }

  @computed
  get listLevel() {
    if (this.isFirst) {
      return 0;
    }
    return this.parent && this.parent.childListLevel || 0;
  }

  @computed
  get childListLevel() {
    return this.parent && this.parent.childListLevel || 0;
  }

  @computed
  get plusMenuItems() {
    return this.availableItemsArray || [];
  }
}

export default BaseTextObject;
