import { observable, computed, action, toJS } from "mobx";

const DEF_LIMIT_STACK_COLLECTION = 25;

/**
 * Логгер для типов сообщений: log, info, warn, error, debug
 * 
 */
class Logger {
  /**
   * Включает или отключает логгер
   * 
   * @type {Boolean}
   */
  @observable
  active = true;

  /**
   * Пользовательский метод для вывода сообщения, например в консоль
   * Метод будет вызываться со слеюущими параметрами: (level: string, message: string, important?: boolean)
   * 
   * @type {Function}
   */
  @observable
  stdout = () => {};

  /**
   * Список сохраняемых последних действий пользваотелем
   * 
   * @type {Array<ActionModel>}
   */
  @observable
  stackCollection = [];

  /**
   * Лимит на количество сохраняемых последних действий пользваотелем. 
   * Если пользвоатель совершит больше действий чем лимит, то первые сохраеннные действия в массиве 
   * автоматически удалятся
   * 
   * @type {Number}
   */
  @observable
  limit = DEF_LIMIT_STACK_COLLECTION;

  constructor() {
    this.stackCollection = [];
  }

  /**
   * Установка параметров для логгера
   * 
   * @param {Object} props  набор параметров
   * @param {Boolean} props.active включает или отключает логгер
   * @param {Function} props.stdout пользовательский метод для вывода сообщения, например в консоль
   * Метод будет вызываться со слеюущими параметрами: (level: string, message: string, important?: boolean)
   * @param {Number} props.limit лимит на количество сохраняемых последних действий пользваотелем. 
   * Если пользвоатель совершит больше действий чем лимит, то первые сохраеннные действия в массиве 
   * автоматически удалятся
   */
  @action 
  init({ active, stdout, limit = DEF_LIMIT_STACK_COLLECTION }) {
    if (typeof active === "boolean") {
      this.active = Boolean(active);
    }
    if (typeof stdout === "function") {
      this.stdout = stdout;
    }

    this.limit = typeof limit === "number" ? Math.abs(limit) : DEF_LIMIT_STACK_COLLECTION;
    this.checkLimit();
  }

  /**
   * Метод для логирование сообщения 
   * 
   * @param {String} message текст сообщения
   * @param {Boolean} important признак важности
   */
  log(message, important = false) {
    this.handler(message, "log", !!important);
  }

  /**
   * Метод для информационного сообщения 
   * 
   * @param {String} message текст сообщения
   * @param {Boolean} important признак важности
   */
  info(message, important) {
    this.handler(message, "info", !!important);
  }

  /**
   * Метод для отладочного сообщения 
   * 
   * @param {String} message текст сообщения
   * @param {Boolean} important признак важности
   */
  debug(message, important) {
    this.handler(message, "debug", !!important);
  }

  /**
   * Метод для предупреждающего сообщения 
   * 
   * @param {String} message текст сообщения
   * @param {Boolean} important признак важности
   */
  warn(message, important) {
    this.handler(message, "warning", !!important);
  }

  /**
   * Метод для сообщения об ошибке
   * 
   * @param {String} message текст сообщения
   * @param {Boolean} important признак важности
   */
  error(message, important) {
    this.handler(message, "error", !!important);
  }

  /**
   * Внутренний метод логирования
   * 
   * @param {String} message текст сообщения
   * @param {String} level уровень(тип) лога
   * @param {Boolean} important признак важности
   */
  handler(message, level, important) {
    if (this.active) {
      if (typeof this.stdout === "function") {
        this.stdout(level, message, important);
      }

      const stackData = {
        [level]: message
      };
      this.addStackData({ ...stackData });
    }
  }

  /**
   * Добавить запись в стек
   * 
   * @param {Object} stackData 
   */
  @action
  addStackData(stackData) {
    this.stackCollection.push({ ...stackData });
    return this.checkLimit();
  }

  /**
   * Проверка стека на  переполненность. 
   * Если стек переполнен, то удаляются из начала более старые записи  
   * 
   * @returns {Boolean} признак, что стек был переполнен и пришлочь его "подрезать" - true,
   *  false - стек еще не весь заполнен
   */
  @action
  checkLimit() {
    const diff = this.stackCollection.length - this.limit;
    if (diff <= 0) {
      return false;
    }
    const l = Array(diff).fill(0);
    l.forEach(() => {
      this.stackCollection.shift();
    });
    return true;
  }

  /**
   * Получить запись из стека по индексу
   * 
   * @param {Number} index индекс записи в стеке
   * 
   * @returns {Object} запись в стеке
   */
  getStack(index) {
    return this.stackCollection[index];
  }

  /**
   * Вернуть стек данных
   * 
   * @returns Array<Object>
   */
  getStackCollection() {
    return this.stackCollection.map((item) => {
      return toJS(item);
    });
  }

  /**
   * Очсистить стек
   */
  @action
  clear() {
    this.stackCollection = [];
  }

  /**
   * Кол-во записей в стеке
   */
  @computed
  get count() {
    return this.stackCollection.length;
  }

  /**
   * Деструктор логгера
   */
  destroy() {
    this.clear();
  }
}

export const createLogger = () => {
  return new Logger();
};

export const logger = createLogger();
