import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Components, Field } from "@ais3p/ui-framework";
import classNames from "classnames";
import useStores from "~/core/utils/useStores";
import EmailItem from "./EmailItem";

const Notifications = observer(() => {
  const [isPending, setIsPending] = useState();
  const [enabled, setEnabled] = useState(false);
  const [isAddEmailMode, setIsAddEmailMode] = useState(false);
  
  const { accountStore } = useStores();

  useEffect(async() => {
    try {
      setIsPending(true);  
      if (accountStore.uid) {
        const enabled = await accountStore.checkNotifications(accountStore.uid);
        setEnabled(enabled);
      }
    } finally {
      setIsPending(false);
    }
  }, [accountStore.uid]);

  const onChangeEnabled = useCallback(async(val) => {
    try {
      setIsPending(true);  
      if (accountStore.uid) {
        const b = await accountStore.updateNotifications(accountStore.uid, { enabled: val });
        b && setEnabled(val);
      }
    } finally {
      setIsPending(false);
    }
  }, [accountStore.uid]);

  const onStartAddNewEmail = useCallback(() => {
    setIsAddEmailMode(true);
  }, []);

  const onAddEmail = useCallback(() => {
    setIsAddEmailMode(false);
  }, []);

  return (
    <div className="user-notifications">
      {isPending && 
        <Components.Preloader size={3} className="preloader-center" />
      }
      <div className="user-notifications-enable">
        <Field.Boolean 
          value={enabled}
          name="enabled"
          label="Оповещение через E-mail"
          onChange={onChangeEnabled}
        />
      </div>
      <div className={classNames("user-notifications-emails", {
        disabled: !enabled
      })}
      >
        <div className="user-notifications-emails-title">
          <div className="user-notifications-emails-title-header">
            <span>Список e-mail адресов:</span>
          </div>
          <div 
            className="user-notifications-emails-title-buttons"
            onClick={onStartAddNewEmail}
          >
            <Components.Icon icon="plus-M" />
            <span>Добавить</span>
          </div>
        </div>
        <div className="user-notifications-emails-list">
          {isAddEmailMode && 
            <EmailItem key="new" onAddEmail={onAddEmail} />
          }
          {accountStore.emails.map((email, i) => {
            return <EmailItem key={email.uid} index={i} email={email} />;
          })}
          {accountStore.emails.length === 0 && !isAddEmailMode &&
            <div className="user-notifications-emails-list-empty">
              Нет записей
            </div>
          }
        </div>
      </div>
    </div>
  );
});

export default Notifications; 
