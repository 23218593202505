import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import { Components } from "@ais3p/ui-framework";
import DropdownButton from "~/core/components/Dropdown/DropdownButton";
import IssueStore from "../../stores/IssueStore";
import IssueItem from "./IssueItem";
import CoordLetterWizard from "../coordLetter/CoordLetterWizard";

import "./css/index.scss";

/**
 * Компонент для работы с Подборкой выбранных задач
 */
const Cart = observer(({ store, onClose, doCreateCoordLetter }) => {
  const [isPending, setIsPending] = useState(false);
  const [issues, setIssues] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [coordLetterTemplate, setCoordLetterTemplate] = useState();

  useEffect(() => {
    loadIssues(store.cartUids);
  }, []);

  const loadIssues = async(uids) => {
    setIsPending(true);
    try {
      setIssues([]);
      setTemplates([]);
      const issues = await store.loadIssuesByUids({ issue: uids });
      if (Array.isArray(issues)) {
        setIssues(issues);
      }
      
      const templates = await store.loadCoordLetterTemplates();
      if (Array.isArray(templates)) {
        setTemplates(templates);
      }
    } finally {
      setIsPending(false);
    }
  };

  const onClearCart = useCallback(() => {
    store.clearCart();
    onClose && onClose();
  }, [store]);

  const onCreateCoordLetter = useCallback((menuItem) => {
    const { template } = menuItem;
    if (store.cartUids?.length > 0) {
      template && doCreateCoordLetter && doCreateCoordLetter(template, store.cartUids);
      onClose && onClose();
    }
  }, [doCreateCoordLetter, store.cartUids?.length, onClose]);

  const onCancelCoordLetter = useCallback(() => {
    setCoordLetterTemplate(null);
  }, []);
  
  const onDoneCoordLetter = useCallback(() => {
    setCoordLetterTemplate(null);
  }, []);

  const leftButtons = useMemo(() => {
    if (issues.length === 0) {
      return [];
    }

    return [
      <DropdownButton 
        key="selected-issues"
        text={"Создать КП"}
        // icon={"chapter-M"}
        items={templates.map((template) => {
          return {
            title:  template.name,
            template,
            action: "createCoordLetter"
          };
        })}
        onClickItem={onCreateCoordLetter}
      />
    ];
  }, [issues?.length, templates?.length,, onCreateCoordLetter]);

  const  rightButtons = useMemo(() => {
    return [
      <Components.Button
        key="clear"
        icon="clean-M"
        text="Очистить"
        tooltip="Очистить подборку от выбранных задач"
        onPress={onClearCart}
      />
    ];
  }, [onClearCart]);

  const onDeleteIssue = useCallback((index) => {
    issues.splice(index, 1);
    setIssues([...issues]);
    // setIssues((array) => {
    //   return array.splice(index, 1);
    // });
  }, [issues]);

  return (
    <div className="issues-cart">
      <Components.ToolBar right={rightButtons} >{leftButtons}</Components.ToolBar>
      <div className="issues-cart-list">
        {isPending && (
          <Components.Preloader size={3} className="issues-preloader" />
        )}
        {!isPending && issues.map((issue, i) => {
          return (
            <IssueItem
              key={issue.uid}
              index={i}
              issue={issue}
              store={store}
              onDelete={onDeleteIssue}
            />
          );
        })}
      </div>
      {coordLetterTemplate && 
        <CoordLetterWizard 
          store={store} 
          templateUid={coordLetterTemplate.uid}
          onCancel={onCancelCoordLetter}
          onDone={onDoneCoordLetter}
        />
      }
    </div>
  );
});

Cart.propTypes = {
  store: PropTypes.instanceOf(IssueStore)
};


export default Cart;
