import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";

import MathfieldComponent from "~/core/components/MathField";

import mathml2latex from "mathml2latex";
import getTimePeriod from "../../../core/utils/getTimePeriod";

const InlineFormula = observer(({ data, measure, useMathml = false }) => {
  const { 
    uid, 
    value, 
    isEditable, 
    diffClass, 
    isLocked, 
    isParentLocked, 
    isLockedByMe, 
    isParentLockedByMe, 
    originClass, 
    originTooltip 
  } = data;

  const mathFieldRef = useRef(null);

  useEffect(() => {
    measure && measure();
  }, [value, measure]);

  const setRef = useCallback((ref) => {
    mathFieldRef.current = ref;
  }, []);

  const latex = useMemo(() => {
    if (!useMathml || !value || value === "") {
      return value;
    }
    let latex = "";
    try {
      latex = mathml2latex.convert(
        `${value.replace(/<annotation.*\/annotation>/gim, "")}`
      );
    } catch (error) {
      console.warn(error);
    }
    return latex;
  }, [value, useMathml]);

  const onChangeFormula = useCallback(
    async(latex = null) => {
      if (mathFieldRef.current && useMathml) {
        const mathml = `${mathFieldRef.current.getValue("math-ml")}`;
        data.unlock();
        await data.setValue(mathml);
      } else {
        data.unlock();
        await data.setValue(latex);
      }
    },
    [data, mathFieldRef.current, useMathml]
  );

  const setEditing = useCallback((e) => {
    data.setEditing();
  }, [data]);

  const datestring = useMemo(() => {
    return getTimePeriod(data.originDatetime);
  }, [new Date(), data]);

  return (
    <span
      id={`${uid}`}
      contentEditable={false}
      data-tooltip={originTooltip && `${originTooltip} ${datestring}`}
      className={`inline-Formula  ${diffClass}  ${originClass}`}
    >
      <MathfieldComponent
        mathfieldConfig={{
          virtualKeyboardMode: "onfocus",
          readOnly:            ((isLocked && !isLockedByMe) || (isParentLocked && !isParentLockedByMe)),
          focus:               true
        }}
        setRef={setRef}
        latex={latex || value}
        disabled={!isEditable}
        onFocus={setEditing}
        onChange={onChangeFormula}
      />
    </span>
  );
});
export default InlineFormula;
