import React from "react";
import PropTypes from "prop-types";

/**
 * Компонент с сообщением, что необходимо выбрать проект
 * 
 * @param {Object} props набор параметров
 * @param {String} props.needSelectProjectText отображаемый текст
 */
const NeedSelectProject = ({ 
  needSelectProjectText = "Необходимо выбрать проект"
}) => {
  return (
    <div className="need-select-project-wrapper">
      <div className="need-select-project-wrapper-text">
        {needSelectProjectText}
      </div>
    </div>
  );
};

NeedSelectProject.propTypes = {
  needSelectProjectText: PropTypes.string
};

export default NeedSelectProject;
