import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import Indicator from "../../../core/components/Indicator";
import TextItemIcon from "./TextItemIcon";

const Table = observer(({ data, renderItem, setContextMenu }) => {
  const { 
    uid, 
    caption, 
    itemsForRender, 
    idsArray, 
    columnsCount, 
    scrollItemId, 
    isFocusUid, 
    diffClass,
    additionalClasses 
  } = data;
  const captionRender = caption && renderItem(caption);

  const itemsRender = useMemo(() => {    
    return itemsForRender.map((itemData) => {
      return renderItem(itemData);
    });
  }, [idsArray, itemsForRender]);

  const onClickIcon = useCallback(async() => {
    await data.setEditing(undefined, true);
    data.setFocusUid();
  }, [data]);

  const onMouseOut = useCallback(() => {
    // data.setHoverRect();
  }, [data]);

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current && element.current.scrollIntoView(true);
      }, 200); // set timeout= DIRTY HACK 
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  return (
    <div
      ref={element} 
      className={`table element ${additionalClasses} ${diffClass}`} 
      onMouseLeave={onMouseOut}
      id={uid}
    >
      <div className={`item-holder  ${isFocusUid ? "focus" : ""}`}>
        <Indicator data={data} />
        <TextItemIcon setContextMenu={setContextMenu} onClick={onClickIcon} data={data} />
        <div className="table-holder">
          {caption && captionRender}
          <div className="table-body-holder">
            <div
              className="table-body"
              style={{
                gridTemplateColumns: `repeat(${columnsCount}, auto)`
              }}
            >
              {itemsRender}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Table;
