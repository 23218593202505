import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";

import AisIcon from "~/core/components/AisIcon";

import AttachedIssue from "./AttachedIssue";

/**
 * Компонент для отображения записей связей, сугрппированных по типу Связи
 * 
 * @param {KindModel} kind вид связи
 * @param {Array<Object>} items список связей
 * @param {Function} plusToggle callback функция на нажатие "+" для добавления новой связи в текущей группе
 * @param {String} expandToAddKindId uid Вида группы связи, который уходил на нажатие "+" 
 * @param {Obejct} targetInfo отслежиеваемый объект АИС
 * @param {Function} onDeleteRelation callback функция на удаление записи связи
 */
const IssueGroup = observer(({
  name, items = [], target, store, onAddIssue, onDeleteRelation }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const onClickAddIssue = useCallback(() => {
    const firstIssue = items[0];
    if (firstIssue || !onAddIssue) {
      return;
    }
    onAddIssue(firstIssue.tracker);
  }, [items, onAddIssue]);

  const toggleExpanded = useCallback(() => {
    setIsExpanded((state) => {
      return !state;
    });
  }, []);

  return (
    <div className="issue-group">
      <div className="item-header">
        <div className="item-name">{name}</div>
        <AisIcon onClick={toggleExpanded} icon={isExpanded ? "chevron-up-M" : "chevron-down-M"} />
        <div className="spacer"></div>
        {onAddIssue &&
          <AisIcon onClick={onClickAddIssue} className="add-issue-button" icon={"plus-M"} />
        }
      </div>
      {isExpanded && (
        <div className="attached-issues-list">
          {items.map((item, i) => {
            return (
              <AttachedIssue
                key={i}
                target={target}
                store={store}
                attachedIssue={item}
                onDeleteRelation={onDeleteRelation}
              />
            );
          })}
        </div>
      )}
    </div>
  );
});

export default IssueGroup;
