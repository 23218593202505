import { computed } from "mobx";
import { observable } from "mobx/lib/mobx";
import { DOMAIN_LIBRARY } from "../../../core/constants/Domains";
import BaseBoxClass from "./BaseBoxClass";

class Text extends BaseBoxClass {
  @observable
  height = 30;

  @computed
  get isExpanded() {
    return !this.isVersion;
  }

  @computed
  get isText() {
    return true;
  }

  @computed
  get defaultInnerItemsArray() {
    return [];
  }

  @computed
  get hierarchyItemsArray() {
    const array = [];
    return array;
  }

  @computed
  get nextChildNumber() {
    return `${this.idsArray.length + 1}`;
  }

  @computed
  get innerHierarchyItemsArray() {
    const array = [];
    const availableKindsArray = [];

    if (this.allowedChildrenTypes.has("text.container.Rubric")) {
      availableKindsArray.push({ kind: "type-only", title: "", icon: null });
    }

    if (
      this.store.typeToKindMapping["text.container.Rubric"] && 
      this.store.typeToKindMapping["text.container.Rubric"].length
    ) {
      this.store.typeToKindMapping["text.container.Rubric"].forEach((kind) => {
        if (this.allowedChildrenKinds.has(kind.kind)) {
          availableKindsArray.push(kind);
        }
      });
    }

    if (availableKindsArray.length) {
      array.push({
        class:    "text.container.Rubric",
        availableKindsArray,
        icon:     "paragraph-M",
        title:    this.nextChildNumber,
        isParent: true,
        ancorId:  this.uid
      });
    }
    return array;
  }

  @computed
  get slug() {
    return "texts";
  }

  @computed
  get libItem() {
    if (!this.store || !this.store.libUid) {
      return null;
    }
    const item = this.objectStore.getVersion(this.store.libUid, DOMAIN_LIBRARY, 0);
    return item;
  }

  @computed
  get bottom() {
    return 0;
  }

  @computed
  get availableItemsArray() {
    if (this.hasElements) {
      return this.defaultInnerItemsArray;
    }
    if (this.hasChildren) {
      return [];
    }
    const array = [].concat(
      this.hierarchyItemsArray,
      this.innerHierarchyItemsArray,
      this.defaultInnerItemsArray
    );
    return array;
  }
}

export default Text;
