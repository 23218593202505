import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react"; 
import { Components } from "@ais3p/ui-framework";
import useStores from "~/core/utils/useStores";

/**
 * Компонент для отображения записи трекера задачи
 * 
 * @param {AttachedIssuesStore} store хранилище для работы с прикрепленным задачами
 * @param {TrackerModel} tracker трекер Задачи
 * @param {String} projectUid uid участника вида проекта
 * @param {Number} index номер записи в списке, начиная с 0
 * @param {String} icon название иконки для Вида задачи
 * @param {Function} onCreate callback ф-я для создания новой задачи для выбранного проекта и выбранного типа Задачи
 */
const TrackerItem = observer((props) => {
  const {
    index, 
    icon,
    tracker,
    projectUid,
    onCreate
  } = props;

  const { configStore } = useStores();
  const config =  configStore.getToolConfig();
  const { taskConfig } = config;
  
  const relationCfg = useMemo(() => {
    if (!tracker?.uid || !taskConfig) {
      return null;
    }
    return taskConfig[tracker?.uid];
  }, [taskConfig, tracker && tracker.uid]);

  const warningTooltip = useMemo(() => {
    const msg = [];
    if (!relationCfg?.relationKindUid) {
      msg.push("Не задан тип связи для привязки Задачи!");
    }
    if (!relationCfg?.direction) {
      msg.push("Не задано направление связи для привязки Задачи!");
    }
    if (msg.length > 0) {
      return msg;
    }

    return null;
  }, [relationCfg]);


  const onCreateIssue = useCallback(() => {
    onCreate && onCreate({ projectUid, tracker, icon, relationCfg });
  }, [projectUid, tracker, relationCfg, icon, onCreate]);

  return (
    <div className="tracker-item">
      <div className="tracker-item-num">
        {index + 1}
      </div>
      <div className="tracker-item-icon">
        <Components.Icon name={icon} />
      </div>
      <div className="tracker-item-title">
        {tracker.name}
      </div>
      {!warningTooltip &&
        <Components.Button 
          icon="ok-M"
          text={"Создать"}
          tooltip={`Создать задачу ${tracker.name}`}
          color="positive"
          onPress={onCreateIssue}
        />
      }
      {warningTooltip &&
        <Components.Button
          icon="warning-M"
          text={"Создать"}
          tooltip={warningTooltip.map((str, i) => {
            return <div key={i}>{str}</div>;
          })}
          isLoading={false}
          isDisabled={true}
        />
      }
    </div>
  );
});

export default TrackerItem;
