import { observer } from "mobx-react";
import React, { useCallback, useLayoutEffect, useRef } from "react";
import Indicator from "../../../core/components/Indicator";
import TextItemIcon from "./TextItemIcon";
import FlatItem from "./FlatItem";

const ListItem = observer(({ data, renderItem, setContextMenu }) => {
  const { id, isFocusUid, scrollItemId, uid, diffClass, additionalClasses, firstItem } = data;

  const element = useRef();

  useLayoutEffect(() => {
    if (
      uid &&
      scrollItemId &&
      uid === scrollItemId &&
      element &&
      element.current
    ) {
      setTimeout(() => {
        element.current && element.current.scrollIntoView(true);
      }, 250); // set timeout= DIRTY HACK 
      // TODO: get rid of setTimeout()
    }
  }, [uid, scrollItemId, element && element.current]);

  const onClickIcon = useCallback(
    async(e) => {
      e && e.stopPropagation && e.stopPropagation();
      await data.setEditing(undefined, true);
      data.setFocusUid();
      return false;
    },
    [data]
  );

  return (
    <div 
      ref={element} 
      id={id}
      onClick={onClickIcon}
      className={`list-item  ${additionalClasses} ${diffClass}  ${isFocusUid ? "focused" : ""}`}
    >
      <div className="list-item-content">
        <Indicator data={data} />
        <TextItemIcon onClick={onClickIcon} setContextMenu={setContextMenu} data={data} />
        <div className={`list-item-body ${isFocusUid ? "focused" : ""}`} onClick={onClickIcon}>
          {firstItem && (
            <FlatItem
              key={firstItem.uid} 
              item={firstItem} 
              renderItem={renderItem}
              dataStore={firstItem.store}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default ListItem;
