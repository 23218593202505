import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";

import { Components, Field } from "@ais3p/ui-framework";

import { DOMAIN_ISSUE } from "~/core/constants/Domains";
import Target from "~/core/components/Target";
import { generateFiltersData } from "../filters";
import IssueTabs from "./IssueTabs";
import IssuesToolbar from "../toolbar";
import IssuePanel from "./IssuePanel";
import { FILTERS_CFG } from "../../constants/config";
import { STATUS_OPEN } from "../../constants/statuses";
import IssueStore from "../../stores/IssueStore";
import NeedSelectProject from "../common/NeedSelectProject";


/**
 * Панель для работы с задачами
 * 
 * @param {Object} props набор параметров
 * @param {String} params.projectUid uid проекта
 * @param {String} params.issueUid uid задачи
 * @param {IssueStore} params.store хранилище для работы с задачами
 * @param {Boolean} params.canBeEditable признак, можно ли редактировать Задачу
 * @param {Object} params.trackedItem данные об отслеживаемом объекте в Layout
 * @param {String} params.tabId id вкладки в Layout
 * @param {LayoutStore} params.layoutStore хранилище для работы с Layout
 * @param {LayoutItem} params.layoutItem сущность в Layout
 * @param {Object} params.isSubVisible флаг отображения боковой доп панели
 */
const IssuesContent = observer(
  ({
    projectUid: initProjectUid,
    issueUid,
    store,
    canBeEditable,
    trackedItem,
    tabId,
    layoutStore,
    layoutItem,
    isSubVisible,
    issueTracker,
    onCreateIssue,
    onCancelCreateIssue
  }) => {
    const [issue, setIssue] = useState();
    const [projectUid, setProjectUid] = useState(initProjectUid);

    useEffect(async() => {
      store.setTrackedItem(trackedItem);
      if (issueUid) {
        loadIssue(issueUid, initProjectUid);
      } else if (initProjectUid) {
        await loadIssues(initProjectUid);
        setProjectUid(initProjectUid);
      }
    }, [initProjectUid, issueUid, store.issuesViewMode, trackedItem, issueTracker]);

    const loadIssues = async(projectUid) => {
      // setIsPending(true);
      try {
        const projectsFilters = store.getItemConfig(FILTERS_CFG)?.projects;
        const prFilters = projectsFilters && projectsFilters[projectUid];
        if (projectUid) {
          if (prFilters) {
            const data = generateFiltersData(prFilters, projectUid, store);
            store.loadIssuesByFilter(data);
          } else {
            const filters = {
              project: [projectUid],
              status:  [STATUS_OPEN]
            };
            await store.loadIssuesByFilter(filters);
            const cfg = store.getItemConfig(FILTERS_CFG) || {};
            const projects = cfg.projects || {};
            projects[projectUid] = filters;
            store.setItemConfig(FILTERS_CFG, {
              ...cfg,
              projects
            });
          }

          store.setItemConfig("lastProject", projectUid);
        }
      } finally {
        // setIsPending(false);
      }
    };

    const reloadIssues = useCallback(() => {
      if (projectUid) {
        loadIssues(projectUid);
      }
    }, [projectUid, trackedItem]);

    const loadIssue = async(uid, projectUid) => {
      // setIsPending(true);
      if (uid === "tmp") {
        // Создаем новую задачу
        if (!issueTracker) {
          store.onError("Не задан tracker для создания новой задачи");
          return;
        }
        const newIssue = store.createNewIssue(issueTracker, null, projectUid);
        store.setActiveIssue(newIssue);
        setIssue(newIssue);
      } else {
        try {
          const i = await store.loadIssue(uid);
          layoutItem.changeContext(i.project.uid, i.uid, {
            uid:         i.uid,
            trackedItem: {
              uid:   i.uid,
              name:  i.title,
              class: i.class,
              tool:  DOMAIN_ISSUE
            }
          });
          store.setActiveIssue(i);
          setIssue(i);
        } finally {
          // setIsPending(false);
        }
      }
    };

    const onChangeProject = useCallback(async(prUid) => {
      if (projectUid === prUid) {
        return;
      }
      const oldProjectUid = projectUid;
      try {
        await store.initProject(prUid);
        store.setCurrentPage(1);
        await loadIssues(prUid);
        setProjectUid(prUid);        
      } catch (ex) {
        setProjectUid(oldProjectUid);
        console.error(ex);
      }
    }, [projectUid, initProjectUid]);

    return (
      <div className="issues">
        {(projectUid  || issueUid) && (
          <IssuesToolbar
            store={store}
            canBeEditable={canBeEditable}
            tabId={tabId}
            layoutStore={layoutStore}
            layoutItem={layoutItem}
            isSubVisible={isSubVisible}
            issueUid={issueUid}
            projectUid={projectUid}
            onCreateIssue={onCreateIssue}
            onCancelCreateIssue={onCancelCreateIssue}
          />
        )}
        {projectUid && issueUid && trackedItem && <Target id={projectUid} trackedItem={trackedItem} />}
        {!issueUid &&
          <div className="project-holder">
            <Field.SingleSelect
              icon=""
              label="Проект"
              name="projectUid"
              value={projectUid}
              onChange={onChangeProject}
              placeholder="Выберите проект"
              options={store.projectOptions}
              // options={store.projectList.map((pr) => {
              //   return { label: pr.title, value: pr.uid, icon: pr.icon };
              // })}
            />
          </div>
        }
        {store.isProcessing && (
          <Components.Preloader size={3} className="issues-preloader" />
        )}
        <div className="issues-wrapper">
          <div className="issues-body">
            <div
              className={classNames(
                "issues-content",
                "issues-panel",
                "issue-list"
              )}
            > 
              {!projectUid && !issueUid && 
                <NeedSelectProject />
              }
              {projectUid && !issue && (
                <IssueTabs
                  store={store}
                  canBeEditable={canBeEditable}
                  projectUid={projectUid}
                  tabId={tabId}
                  layoutStore={layoutStore}
                  layoutItem={layoutItem}
                  isSubVisible={isSubVisible}
                  reloadIssues={reloadIssues}
                />
              )}
              {issue && (
                <IssuePanel
                  issue={issue}
                  store={store}
                  canBeEditable={canBeEditable}
                  tabId={tabId}
                  layoutStore={layoutStore}
                  layoutItem={layoutItem}
                  isSubVisible={isSubVisible}
                  onCreateIssue={onCreateIssue}
                  onCancel={onCancelCreateIssue}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

IssuesContent.propTypes = {
  projectUid:    PropTypes.string,
  issueUid:      PropTypes.string,
  store:         PropTypes.instanceOf(IssueStore),
  canBeEditable: PropTypes.bool,
  trackedItem:   PropTypes.object,
  tabId:         PropTypes.string,
  layoutStore:   PropTypes.object,
  layoutItem:    PropTypes.object,
  isSubVisible:  PropTypes.object
};

export default IssuesContent;
