import { observer } from "mobx-react";
import React, { useCallback } from "react";

import Chunk from "./Chunk";

const Caption = observer(({ data }) => {
  const { text } = data;
  if (!text) {
    return null;
  }
  const setEditing = useCallback((e) => {
    e.stopPropagation();
    data.setEditing();
  }, [data]);

  return (
    <div className="caption" onClick={setEditing}>
      {data.prefix && <span className="prefix">{data.prefix}</span>}
      {data.number && <span className="number">{data.number}:</span>}
      <Chunk data={text} />
    </div>
  );
});

export default Caption;
