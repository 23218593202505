import React, { Fragment, useCallback } from "react";
import { observer } from "mobx-react";
import { ContextMenu } from "@ais3p/ui-framework";

import AisIcon from "~/core/components/AisIcon";
import AisObject from "~/core/components/AisObject";
import { SIDEPANEL_ISSUES } from "~/core/constants/SidePanels";

/**
 * Компонент для отображения записи связи задачи с активным АИС  объектом
 * 
 * @param {AttachedIssue} attachedIssue запись связи задачи с активным АИС  объектом
 * @param {Object} target активный АИС  объектом
 * @param {AttachedIssuesStore} store хранилище для работы с прикрепленным задачами
 * @param {Function} onDeleteRelation callback ф-я для удлаения связи с задачей
 */
const AttachedIssue = observer(({ attachedIssue, target, store, onDeleteRelation }) => {
  const { direction, issue, relation } = attachedIssue;
  const { sourceCaption, destCaption } = relation?.kind;
  const isStart = direction === "source";

  const onHandleDeleteRelation = useCallback(() => {
    // удалять связь можем только в редакции (версия > 0)
    if (target && !target.version) {
      onDeleteRelation && onDeleteRelation(attachedIssue);
    }
  }, [attachedIssue, target, onDeleteRelation]);

  return (
    <Fragment>
      <ContextMenu.Trigger
        menuId={SIDEPANEL_ISSUES}
        context={{ attachedIssue }}
      >
        <div className="item" data-tooltip={isStart ? sourceCaption : destCaption}>
          <AisIcon 
            icon={isStart ? "arrow-left-M" : "arrow-right-M"} 
          />
          <AisObject
            object={issue} 
            withVersion={true} 
            store={store}
            className={"linked-object"}
          />
          {// удалять связь можем только в редакции (версия > 0)
            target && !target.version &&
            <AisIcon
              icon={"delete-M"}
              className="delete"
              data-tooltip="Удалить связь"
              onClick={onHandleDeleteRelation}
            />
          }
        </div>
      </ContextMenu.Trigger>
    </Fragment>
  );
});

export default AttachedIssue;
