import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import moment from "moment";

import IssueStore from "../../stores/IssueStore";
import { IssueModel } from "../../models";
import CustomField from "./CustomField";

/**
 * Компонент для отображения списка пользовательских полей
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} props.store хранилище для работы с задачами
 * @param {IssueModel} props.issue текущая задача
 * @param {String} props.className пользовательский className
 * @param {Boolean} props.readOnly флаг указывающий, что файлы в спсиске только можно просмматривать
 */
const CustomFields = observer(
  ({
    store,
    issue,
    className,
    values,
    readOnly
  }) => {
    if (!issue) {
      return null;
    }

    if (issue.customFields.length === 0) {
      return null;
    }

    const fieldsInPanels = useMemo(() => {
      if (issue.customFields.length === 0) {
        return [[], []];
      }
      if (issue.customFields.length === 1) {
        return [[issue.customFields[0]], []];
      }
      // const indexToSplit = Math.floor(issue.customFields.length / 2);
      const indexToSplit = Math.ceil(issue.customFields.length / 2);
      const leftFields = issue.customFields.slice(0, indexToSplit);
      const rightFields = issue.customFields.slice(indexToSplit);
      return [leftFields, rightFields];
    }, [issue.customFields && issue.customFields.length]);

    /**
     * Callback на изменение поля значения
     * 
     * @params {Any} value значение переменной
     * @params {String} name имя переменной
     * @params {String} type тип переменной
     */
    const onFieldChange = useCallback((value, name, type) => {
      let val = value;
      switch (type) {
        case "date":{
          if (value) {
            val = moment(value).toDate();  
          } 
          break;
        }
      }
      issue && issue.setTmpValue(Number(name), val, true);
    }, [issue, values]);

    if (!fieldsInPanels[0] || fieldsInPanels[0]?.length === 0) {
      return null;
    }

    return (
      <div className={`block-divider flex-gap ${className ? className : ""}`}>
        {fieldsInPanels[0]?.length > 0 &&
          <div className="issue-panel-timedata">
            {fieldsInPanels[0].map((customField) => {
              let value = customField.value;
              if (values !== issue) {
                value = values && values.customFieldsMap && values.customFieldsMap.get(customField.id);
              }
              return (
                <CustomField
                  key={customField.id}
                  customField={customField}
                  value={value}
                  store={store}
                  issue={issue}
                  readOnly={readOnly}
                  onChange={onFieldChange}
                />
              );
            })}
          </div>
        }
        {fieldsInPanels[1]?.length > 0 &&
          <div className="issue-panel-users">
            {fieldsInPanels[1].map((customField) => {
              let value = customField.value;
              if (values !== issue) {
                value = values && values.customFieldsMap && values.customFieldsMap.get(customField.id);
              }
              return (
                <CustomField
                  key={customField.id}
                  customField={customField}
                  value={value}
                  store={store}
                  issue={issue}
                  readOnly={readOnly}
                  onChange={onFieldChange}
                />
              );
            })}
          </div>
        }
      </div>
    );
  }
);

CustomFields.propTypes = {
  className: PropTypes.string,
  store:     PropTypes.instanceOf(IssueStore), 
  issue:     PropTypes.instanceOf(IssueModel), 
  readOnly:  PropTypes.bool 
};

export default CustomFields;
