import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import {
  statusTitle,
  STATUS_OPEN,
  STATUS_CLOSED
} from "../../constants/statuses";
import IssueStore from "../../stores/IssueStore";
import { Field } from "@ais3p/ui-framework";



/**
 * Контрл для отображения статуса задачи в форме выпадающего списка
 * 
 * @param {Object} props набор параметров
 * @param {String} params.className  пользовательский className
 * @param {IssueStore} params.store хранилизе для работы с задачами
 * @param {String} params.label заголовок поля
 * @param {String} params.name название поля
 * @param {String} params.value значение поля - статус задачи
 * @param {Function} params.oChange callback ф-я на изменение значения поля
 */
const StatusSelect = ({
  label = "Статус",
  name = "status",
  value = [],
  store,
  onChange
}) => {
  const options = useMemo(() => {
    const res = [
      { label: statusTitle(STATUS_OPEN), value: STATUS_OPEN, icon: "collection-open-M" },
      { label: statusTitle(STATUS_CLOSED), value: STATUS_CLOSED, icon: "collection-closed-M" }
    ];
    return res.concat(store.statusList.map((st) => {
      const title = `${st.title} ${st.isClosed ? "(задача закрыта)" : ""} `;
      return { label: title, value: st.id, icon: st.isClosed ? "close-M" : "ok-M" };
    }));
  }, [store.statusList && store.statusList.length]);

  const onChangeStatuses = useCallback(
    (val) => {
      let values = val;
      if (val.includes(STATUS_OPEN) || val.includes(STATUS_CLOSED)) {
        values = [val[val.length - 1]];
      } 
      // setStatuses(values);
      onChange && onChange(values, name);
    },
    [onChange]
  );

  
  return (
    <Field.MultiSelect
      label={label}
      placeholder="Выберите статус(ы) задачи"
      icon="data-enum-multi-M"
      name={name}
      value={value}
      onChange={onChangeStatuses}
      isClearable={true}
      isRequired={false}
      readOnly={false}
      options={options}
    />
  );
};

StatusSelect.propTypes = {
  className: PropTypes.string,
  label:     PropTypes.string,
  name:      PropTypes.string,
  value:     PropTypes.any,
  store:     PropTypes.instanceOf(IssueStore).isRequired,
  onChange:  PropTypes.func
};

export default StatusSelect;
