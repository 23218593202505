import React, { useMemo, Fragment, useCallback } from "react";
import { observer } from "mobx-react";
import { ContextMenu } from "@ais3p/ui-framework";

import useStores from "~/core/utils/useStores";
import AisIcon from "~/core/components/AisIcon";
import AisObject from "~/core/components/AisObject";
import { SIDEPANEL_RELATIONS } from "~/core/constants/SidePanels";

/**
 * Компонент для отображения записи связи в списке уже имеющихся связей
 * 
 * @param {Object} item запись связи
 * @param {KindModel} kind Вид связи
 * @param {Function} onDeleteRelation
 */
const RelationItem = observer(({ item, target, kind, onDeleteRelation }) => {
  const { relationStore, objectStore } = useStores();
  const { isStart } = item;
  const { sourceCaption, destCaption } = kind;

  const object = useMemo(() => {
    const { uid, domain, version } = item;
    return objectStore.getVersion(uid, domain, version);
  }, [item]);

  const onHandleDeleteRelation = useCallback(() => {
    // удалять связь можем только в редакции (версия > 0)
    if (target && !target.version) {
      onDeleteRelation && onDeleteRelation(item, kind);
    }
  }, [kind, item, target, onDeleteRelation]);

  // if (!object) {
  //   <div className="item" data-tooltip={isStart ? sourceCaption : destCaption}>
  //     <AisIcon icon={isStart ? "arrow-left-M" : "arrow-right-M"} />
  //     <AisObject
  //       object={object} withVersion={true} store={relationStore}
  //       className={"linked-object"}
  //     />
  //     {// удалять связь можем только в редакции
  //       target && target.version === 0  &&
  //       <AisIcon
  //         icon={"delete-M"} className="delete" data-tooltip="Удалить связь"
  //         onClick={onHandleDeleteRelation}
  //       />
  //     }
  //   </div>;
  // }
  return (
    <Fragment>
      <ContextMenu.Trigger
        menuId={SIDEPANEL_RELATIONS}
        context={{ item, kind }}
      >
        <div className="item" data-tooltip={isStart ? sourceCaption : destCaption}>
          <AisIcon 
            icon={isStart ? "arrow-left-M" : "arrow-right-M"} 
          />
          <AisObject
            object={object} 
            withVersion={true} 
            store={relationStore}
            className={"linked-object"}
          />
          {// удалять связь можем только в редакции (версия >0)
            target && !target.version  &&
            <AisIcon
              icon={"delete-M"}
              className="delete"
              data-tooltip="Удалить связь"
              onClick={onHandleDeleteRelation}
            />
          }
        </div>
      </ContextMenu.Trigger>
    </Fragment>
  );
});

export default RelationItem;
