import Server from "~/core/api/server";

/**
 * Api запросов к сервису для работы с Видами и Аттрибутами
 */
class KindsApi extends Server {
  methods = {
    createKind: {
      url:         this.getServiceUrl("kinds", "api/v1/kinds"),
      method:      "POST",
      textProcess: "Создание Вида...",
      textSuccess: null,
      textError:   this.template`Во время создания Вида произошла ошибка: ${0}`
    },
    getLimitations: {
      url:         this.getServiceUrl("admin", "api/v1/restrictions"),
      method:      "GET",
      textProcess: "Получение ограничений...",
      textSuccess: null,
      textError:   this
        .template`Во время получения ограничений произошла ошибка: ${0}`
    },
    getKind: {
      url: (params) => {
        return this.getServiceUrl(
          "kinds",
          this.template`api/v1/kinds/${0}`,
          params
        );
      },
      method:      "GET",
      textProcess: "Запрос получения Вида...",
      textSuccess: null,
      timeout:     30000,
      textError:   this.template`Во время запроса Вида произошла ошибка: ${0}`
    },
    getAttr: {
      url: (params) => {
        return this.getServiceUrl(
          "kinds",
          this.template`api/v1/attributes/${0}`,
          params
        );
      },
      method:      "GET",
      timeout:     300000,
      textProcess: "Запрос получения Атрибута...",
      textSuccess: null,
      textError:   this
        .template`Во время запроса получения Атрибута произошла ошибка: ${0}`
    },
    updateKind: {
      url: (params) => {
        return this.getServiceUrl(
          "kinds",
          this.template`api/v1/kinds/${0}`,
          params
        );
      },
      method:      "PATCH",
      textProcess: "Запрос обновления Вида...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время запроса обновления Вида произошла ошибка: ${0}`
    },

    getAllKinds: {
      url:         this.getServiceUrl("kinds", "api/v2/kinds"),
      method:      "GET",
      textProcess: "Запрос получения всех доступных Видов...",
      textSuccess: null,
      textError:   this.template`Во время запроса Видов произошла ошибка: ${0}`,
      timeout:     300000
    },

    getAllAttrs: {
      url:         this.getServiceUrl("kinds", "api/v1/attributes"),
      method:      "GET",
      textProcess: "Запрос получения всех доступных Атрибутов...",
      textSuccess: null,
      textError:   this
        .template`Во время запроса Атрибутов произошла ошибка: ${0}`,
      timeout: 300000
    },
    getMember: {
      url: (params) => {
        return this.getServiceUrl(
          "kinds",
          this.template`api/v2/objects/${0}/members?version=${1}`,
          params
        );
      },
      method:      "GET",
      textProcess: "Запрос получения всех участников Вида...",
      textSuccess: null,
      textError:   this.template`Во время запроса участников Вида произошла ошибка: ${0}`,
      timeout:     300000
    },
    addKindMember: {
      url: (params) => {
        return this.getServiceUrl(
          "kinds",
          this.template`api/v2/kinds/${0}/members`,
          params
        );
      },
      method:      "POST",
      textProcess: "Запрос добавления элемента в Вид...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время запроса добавления элемента в Вид произошла ошибка: ${0}`
    },
    getKindMembers: {
      url: (params) => {
        return this.getServiceUrl(
          "kinds",
          this.template`api/v1/kinds/${0}/members`,
          params
        );
      },
      method:      "GET",
      textProcess: "Запрос всех элементов Вида...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время запроса элементов Вида произошла ошибка: ${0}`
    },
    getMemberItem: {
      url: (params) => {
        return this.getServiceUrl(
          "kinds",
          this.template`api/v2/members/${0}`,
          params
        );
      },
      method:      "GET",
      textProcess: "Получение представителя Вида...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время получения представителя Вида произошла ошибка: ${0}`
    },
    changeAttributes: {
      url: (params) => {
        return this.getServiceUrl(
          "kinds",
          this.template`api/v2/members/${0}`,
          params
        );
      },
      method:      "PUT",
      textProcess: "Обновить значения атрибутов для объекта...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время Обновления значения атрибутов для объекта произошла ошибка: ${0}`
    },

    deleteKindMember: {
      url: (params) => {
        return this.getServiceUrl(
          "kinds",
          this.template`api/v2/members/${0}`,
          params
        );
      },
      method:      "DELETE",
      textProcess: "Запрос удаления элемента из Вида...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время запроса удаления элемента из Вида произошла ошибка: ${0}`
    },
    deleteKind: {
      url: (params) => {
        return this.getServiceUrl(
          "kinds",
          this.template`api/v1/kinds/${0}`,
          params
        );
      },
      method:      "DELETE",
      textProcess: "Запрос удаления элемента  Вида...",
      textSuccess: null,
      textError:   this
        .template`Во время запроса удаления элемента Вида произошла ошибка: ${0}`,
      timeout: 30000
    },
    getMembers: {
      url:         this.getServiceUrl("kinds", "api/v2/members/found-members"),
      method:      "POST",
      textProcess: "Запрос получения представителей Видов...",
      textSuccess: null,
      textError:   this.template`Во время запроса представителей Видов произошла ошибка: ${0}`,
      timeout:     120000
    },
    getTraceSchemas: {
      url:         this.getServiceUrl("tracer", "api/v2/schemes"),
      method:      "GET",
      textProcess: "Запрос списка доступных схем трассировок...",
      textSuccess: null,
      textError:   this.template`Во время запроса списка доступных схем трассировок  произошла ошибка: ${0}`,
      timeout:     120000
    }
  };

  /**
   * Получить представителя Вида
   * 
   * @param {String} memberUid uid представителя Вида
   * @returns 
   */
  async getMemberItem(memberUid) {
    const reqData = {
      params: [memberUid]
    };
    try {
      return await this.request("getMemberItem", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  /**
   * Загрузить список участников вида
   * 
   * @param {Array<String>} uidsArray массив uid'ов
   * @returns 
   */
  async getMembers(uidsArray) {
    try {
      const promises = [];
      const window = 5000; // размер окна запроса, чтобы не превысить размер хедера в GET
      const data = [...uidsArray];
      while (data.length) {
        promises.push(
          this.request("getMembers", data.splice(0, window))
        );
      }

      const allPromises = await Promise.all(promises);
      const chunkData = [].concat(...allPromises);
      return chunkData;
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async getLimitations() {
    try {
      return await this.request(
        "getLimitations",
        undefined,
        false,
        true,
        false
      );
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async createKind(data) {
    try {
      return await this.request("createKind", data);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async getKind(uid) {
    const reqData = {
      params: [uid]
    };
    try {
      return await this.request("getKind", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async getAttr(uid) {
    const reqData = {
      params: [uid]
    };
    try {
      return await this.request("getAttr", reqData, false, true, false);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async updateKind(uid, params) {
    const reqData = {
      params: [uid],
      ...params
    };
    try {
      return await this.request("updateKind", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async getAllKinds() {
    try {
      return await this.request("getAllKinds");
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async getAllAttrs() {
    try {
      return await this.request("getAllAttrs");
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async addKindMember(kindUid, data) {
    const reqData = {
      params: [kindUid],
      data
    };
    try {
      return await this.request("addKindMember", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async getMember(uid, version = 0) {
    if (uid) {
      const reqData = {
        params: [uid, version]
      };
      try {
        return await this.request("getMember", reqData);
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  async getKindMembers(uid) {
    const reqData = {
      params: [uid]
    };

    try {
      return await this.request("getKindMembers", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async deleteKindMember(memberUid) {
    const reqData = {
      params: [memberUid]
    };

    try {
      return await this.request("deleteKindMember", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async deleteKind(uid) {
    const reqData = {
      params: [uid]
    };

    try {
      return await this.request("deleteKind", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async changeAttributes(memberUid, data) {
    const reqData = {
      params: [memberUid],
      data
    };

    try {
      return await this.request("changeAttributes", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async loadTraceSchemas() {
    return await this.request("getTraceSchemas");
  }
}

export default KindsApi;
