import { observable, computed, action } from "mobx";
import { declOfNum } from "~/core/utils";
import AttachmentModel from "./attachmentModel";
import RedmineUserModel from "./userModel";
import moment from "moment";

/**
 * Модель пользовательского поля в Задаче.
 *
 * @class CustomFieldModel
 */
class CustomFieldModel {
  /**
   * customizedType тип поля
   *
   * @type String
   */
  customizedType = undefined;

  /**
   * defaultValue значение по-умолчанию
   *
   * @type Any
   */
  defaultValue = undefined;

  /**
   * fieldFormat тип поля: string | text | enumeration | list | link | attachment| int | date
   *
   * @type String
   */
  fieldFormat = undefined;

  /**
   * filter Используется в качестве фильтра
   *
   * @type Boolean
   */
  filter = false;
   
  /**
   * id Идентификатор поля в Redmine
   *
   * @type Number
   */
  id = undefined;

  /**
   * maxLength Максимальная длина значения в поле
   *
   * @type Number
   */
  maxLength = null;

  /**
   * minLength Минимальная длина значения в поле
   *
   * @type Number
   */
  minLength = null;

  /**
   * multiple Признак возможности множественного значения в поле
   *
   * @type Boolean
   */
  multiple = false;

  /**
   * name Название поля
   *
   * @type String
   */
  name = false;

  /**
   * possibleValues Возможные занчения в выпадающем списке
   *
   * @type Array<Object>
   */
  possibleValues = [];

  /**
   * regexp Регулярное выражение. Например: ^[A-Z0-9]+$
   *
   * @type String
   */
  regexp = "";

  /**
   * required Обязательное
   *
   * @type Boolean
   */
  required = false;

  /**
   * searchable Доступно для поиска
   *
   * @type Boolean
   */
  searchable = false;

  /**
   * visible Видимое ли поле
   *
   * @type Boolean
   */
  visible = true;

  /**
   * val Значение поля, заданное  пользователем
   *
   * @type Any
   */
  @observable
  val = undefined;

  static isInt(value = "") {
    // Регулярное выражение допускает:
    // 1. Необязательный знак (+/-)
    // 2. Цифры (одна или более)
    // 3. Необязательную экспоненциальную часть (e/E с цифрами)
    return /^[+-]?\d+(\d+)?$/.test(String(value).trim());
  }

  static isFloat(value = "") {
    const str = String(value).trim();
    
    // Проверка регулярным выражением
    if (!/^[+-]?(?:\d+\.?\d*|\.\d+)(?:[eE][+-]?\d+)?$/.test(str)) {
      return false;
    }
    
    // Дополнительная проверка преобразованием
    const num = parseFloat(str);
    return !isNaN(num) && isFinite(num);
  }

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   *
   * @retrun {CustomFieldModel}
   */
  static create({
    customizedType,
    defaultValue,
    fieldFormat,
    filter,
    id,
    maxLength,
    minLength,
    multiple,
    name,
    possibleValues,
    regexp,
    required,
    searchable,
    visible
  }, issueStore) {
    return new CustomFieldModel({
      customizedType,
      defaultValue,
      fieldFormat,
      filter,
      id,
      maxLength,
      minLength,
      multiple,
      name,
      possibleValues,
      regexp,
      required,
      searchable,
      visible
    }, issueStore);
  }
  
  constructor(params, issueStore) {
    this.customizedType = params.customizedType;
    this.defaultValue = params.defaultValue;
    this.fieldFormat = params.fieldFormat;
    this.filter = params.filter;
    this.id = params.id;
    this.maxLength = params.maxLength;
    this.minLength = params.minLength;
    this.multiple = params.multiple;
    this.name = params.name;
    this.possibleValues = params.possibleValues || [];
    this.regexp = params.regexp;
    this.required = params.required;
    this.searchable = params.searchable;
    this.visible = params.visible;

    this.issueStore = issueStore;

    switch (this.fieldFormat) {
      case "bool":
        this.val = !!this.defaultValue;  
        break;
      case "version":
      case "list":
      case "enumeration":  
        if (params.multiple) {
          if (this.defaultValue) {
            this.val = Array.isArray(this.defaultValue) ? this.defaultValue : [this.defaultValue];  
          }
        } else {
          this.val = this.defaultValue;  
        }
        
        break;
      default: {
        this.val = this.defaultValue;
      }
    }
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "customFieldModel";
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  get config() {
    return {
      customizedType: this.customizedType,
      defaultValue:   this.defaultValue,
      fieldFormat:    this.fieldFormat,
      filter:         this.filter,
      id:             this.id,
      maxLength:      this.maxLength,
      minLength:      this.minLength,
      multiple:       this.multiple,
      name:           this.name,
      possibleValues: this.possibleValues || [],
      regexp:         this.regexp,
      required:       this.required,
      searchable:     this.searchable,
      visible:        this.visible
    };
  }

  /**
   * Установить значение у поля
   * 
   * @param {Any} value 
   */
  @action
  setValue(value, isMultiple) {
    switch (this.fieldFormat) {
      case "date":{
        if (value) {
          this.val = value instanceof Date  ? value : moment(value).toDate();
        } else {
          this.val = null;
        }        
        break;
      }
      case "attachment":{
        this.val = AttachmentModel.create(value);
        break;
      }
      case "bool":{
        this.val = !!value;
        break;
      } 
      case "user":{
        if (Array.isArray(value)) {
          this.val = value.map((v) => {
            if (typeof v === "string" || typeof v === "number") {
              // Пришел uid или id пользователя. 
              return v;
            } else {
              return RedmineUserModel.create(v, this.issueStore);
            }
          });
        } else {
          if (!value) {
            this.val = undefined;
          } else {
            if (typeof value === "string" || typeof v === "number") {
              this.val = value;
            } else {
              this.val = RedmineUserModel.create(value, this.issueStore);
            }
          }
        }  
        break;
      }

      default: {
        if (isMultiple) {
          if (Array.isArray(value)) {
            this.val = value;  
          } else {
            this.val = [value];
          }
        } else {
          this.val = value;
        }
      }
    }
  }

  @computed
  get value() {
    switch (this.fieldFormat) {
      case "user":
      case "version":
      case "list":
      case "enumeration": {
        if (this.multiple) {
          if (this.val) {
            return Array.isArray(this.val) ? this.val : [this.val];
          }
          return Array.isArray(this.val) ? this.val : [];
        } else {
          return Array.isArray(this.val) ? this.val[0] : this.val;
        }
      }

      default:
        return this.val;  
    }
  }

  /**
   * Сделать валидацию значения `value`. Валидация будет происходить согласно типу поля.
   * 
   * @param {Any} value 
   * 
   * @returns {Object} резульат валидации {suсcess: true} || {success: false, hint: 'Some text'}
   */
  validateValue(value) {
    switch (this.fieldFormat) {
      case "string":
      case "text":
        return this.validateString(value);
      case "enumeration":
        return this.validateEnum(value);
      case "list": 
        return this.validateList(value);
      case "link":
        return this.validateLink(value);  
      case "attachment":
        return this.validateAttachment(value);
      case "int": 
        return this.validateInt(value);
      case "float": 
        return this.validateFloat(value);
      case  "date": 
        return this.validateDate(value);
      case  "version": 
        return this.validateVersion(value);
      case  "user": 
        return this.validateUser(value);
      case "coordLetterNumber":
        return this.validateCoordLetterNumber(value);
    }
    return { isValid: true };
  }

  /**
   * Сделать валидацию типа - строка (string)
   * 
   * @param {Any} value 
   * 
   * @returns {Object} резульат валидации {suсcess: true} || {isValid: false, hint: 'Some text'}
   */
  validateString(value = "") {
    if (this.required && !value) {
      return {
        isValid: false,
        hint:    "Необходимо установить значение"
      };
    }

    if (this.minLength && value.length < this.minLength) {
      return {
        isValid: false,
        // eslint-disable-next-line max-len
        hint:    `Минимальная длина строки д.б. ${this.minLength} ${declOfNum(this.minLength, ["символ", "символа", "символов"])}`
      };
    }

    if (this.maxLength && value.length > this.maxLength) {
      return {
        isValid: false,
        // eslint-disable-next-line max-len
        hint:    `Максимальная длина строки д.б. ${this.maxLength} ${declOfNum(this.maxLength, ["символ", "символа", "символов"])}`
      };
    }

    if (this.regexp) {
      return {
        isValid: new RegExp(this.regexp, "g").test(this.value),
        hint:    `Не прошла валидация регулярного выражения "${this.regexp}"`
      };
    }

    return { isValid: true };
  }

  /**
   * Сделать валидацию значения типа - список ключ/занчение (enumeration).
   * 
   * @param {Any} value 
   * 
   * @returns {Object} резульат валидации {suсcess: true} || {isValid: false, hint: 'Some text'}
   */
  validateEnum(value) {
    if (this.required && !value) {
      return {
        isValid: false,
        hint:    "Необходимо установить значение"
      };
    }
    return { isValid: true };
  }

  /**
   * Сделать валидацию значения типа - список (list).
   * 
   * @param {Any} value 
   * 
   * @returns {Object} резульат валидации {suсcess: true} || {isValid: false, hint: 'Some text'}
   */
  validateList(value) {
    if ((this.required && !this.multiple && !value) ||
        (this.required && this.multiple && (value?.length === 0 || !value))
    ) {
      return {
        isValid: false,
        hint:    "Необходимо установить значение"
      };
    }
    return { isValid: true };
  }

  /**
   * Сделать валидацию значения типа -  ссылка (link).
   * 
   * @param {Any} value 
   * 
   * @returns {Object} резульат валидации {suсcess: true} || {isValid: false, hint: 'Some text'}
   */
  validateLink(value) {
    if (this.required && !value) {
      return {
        isValid: false,
        hint:    "Необходимо установить значение"
      };
    }
    if (!value) {
      return { 
        isValid: true
      };
    }
    const regex = /[(http(s)?)://(www.)?a-zA-Z0-9:._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9:%_+.~#?&//=]*)/gi;
    const isValid = value.match(regex);
    if (!isValid) {
      return {
        isValid: false,
        hint:    "Неверный формат ссылки"
      };
    }
    return { isValid: true };
  }

  /**
   * Сделать валидацию значения типа - прикерпленный файл (attachment).
   * 
   * @param {Any} value 
   * 
   * @returns {Object} резульат валидации {suсcess: true} || {isValid: false, hint: 'Some text'}
   */
  validateAttachment(value) {
    if (this.required && !value) {
      return {
        isValid: false,
        hint:    "Необходимо загрузить файл."
      };
    }
    return { isValid: true };
  }

  /**
   * Сделать валидацию значения типа - целочиленное число (integer).
   * 
   * @param {Any} value 
   * 
   * @returns {Object} резульат валидации {suсcess: true} || {isValid: false, hint: 'Some text'}
   */
  validateInt(value) {
    if (this.required && !value) {
      return {
        isValid: false,
        hint:    "Необходимо установить значение"
      };
    }
    if (!value) {
      return { 
        isValid: true
      };
    }
    // if (!isNaN(value) && 
    //     parseInt(Number(value)) === Number(value) &&
    //     !isNaN(parseInt(value, 10))) {
    //   return { 
    //     isValid: true
    //   };
    // }

    if (CustomFieldModel.isInt(value)) {
      return { 
        isValid: true
      };
    }

    return { 
      isValid: false,
      hint:    "Значение д.б. целочисленным"
    };
  }

  /**
   * Сделать валидацию значения типа - число c плавающей точкой (float).
   * 
   * @param {Any} value 
   * 
   * @returns {Object} резульат валидации {suсcess: true} || {isValid: false, hint: 'Some text'}
   */
  validateFloat(value) {
    if (this.required && !value) {
      return {
        isValid: false,
        hint:    "Необходимо установить значение"
      };
    }
    if (!value) {
      return { 
        isValid: true
      };
    }
    
    // if (!isNaN(value) && 
    //      parseFloat(Number(value)) === Number(value) &&
    //      !isNaN(parseFloat(value))) {
    //   return { 
    //     isValid: true
    //   };
    // }

    if (CustomFieldModel.isFloat(value)) {
      return { 
        isValid: true
      };
    }
    return { 
      isValid: false,
      hint:    "Значение д.б. с плавающей точкой"
    };
  }

  /**
   * Сделать валидацию значения типа - дата (date).
   * 
   * @param {Any} value 
   * 
   * @returns {Object} резульат валидации {suсcess: true} || {isValid: false, hint: 'Some text'}
   */
  validateDate(value) {
    if (this.required && !value) {
      return {
        isValid: false,
        hint:    "Необходимо установить значение"
      };
    }
    if (!value) {
      return { 
        isValid: true
      };
    }

    if (!isNaN(new Date(value))) {
      return { 
        isValid: true
      };
    }
    return { 
      isValid: false,
      hint:    "Неверное значение даты"
    };
  }

  /**
   * Сделать валидацию значения типа - версия (version).
   * 
   * @param {Any} value 
   * 
   * @returns {Object} резульат валидации {suсcess: true} || {isValid: false, hint: 'Some text'}
   */
  validateVersion(value) {
    if (this.required && !value) {
      return {
        isValid: false,
        hint:    "Необходимо установить значение"
      };
    }

    return { 
      isValid: true
    };
  }

  /**
   * Сделать валидацию значения типа - пользователь (user).
   * 
   * @param {Any} value 
   * 
   * @returns {Object} резульат валидации {suсcess: true} || {isValid: false, hint: 'Some text'}
   */
  validateUser(value) {
    if (this.required && !value) {
      return {
        isValid: false,
        hint:    "Необходимо выбрать пользователя"
      };
    }

    if (this.required && 
        this.multiple &&
        !value?.length) {
      return {
        isValid: false,
        hint:    "Необходимо установить значение"
      };
    }
    return { isValid: true };
  }

  /**
   * Сделать валидацию номера КП.
   * 
   * @param {Any} value 
   * 
   * @returns {Object} резульат валидации {suсcess: true} || {isValid: false, hint: 'Some text'}
   */
  validateCoordLetterNumber(value) {
    if (this.required && !value) {
      return {
        isValid: false,
        hint:    "Необходимо установить значение"
      };
    }
    return { 
      isValid: true
    };
  }

  /**
   * Валидно ли значение поля
   * 
   * @returns {Boolean} валидно ли значение поля
   */
  @computed
  get isValid() {
    return this.validateField(this.value).isValid;
  }
}

export default CustomFieldModel;
