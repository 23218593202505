import { observable, computed, action, toJS } from "mobx";
import moment from "moment";
import CustomFieldModel from "./customFieldModel";
/**
 * Модель шаблона Координационного письма
 *
 * @class CoordTemplateModel
 */
class CoordTemplateModel {
  /**
   * UID текстового представления шаблона
   *
   * @type String
   */
  @observable
  uid = undefined;

  /**
   * Номер версии текстового предсавтления шаблона
   *
   * @type Number
   */
  @observable
  version = 0;

  /**
   * Название шаблона в Библиотеке
   * Название будет запрашиваться отдельным запросом по uid РМ шаблона
   *
   * @type String
   */
  @observable
  name = undefined;

  
  /**
   * JSON представление схемы шаблона Координационного письма
   *
   * @type String
   */
  @observable
  schema = {};

  /**
   * Последний сквозной номер шаблона
   *
   * @type Number
   */
  @observable
  lastNumber = 0;


  /**
   * Набор выбранных колонок для сводной таблицы полей
   *
   * @type {Array<Object>}
   */
  @observable
  memoCols = [];

  /**
   * Набор выбранных колонок для таблицы карточки Задачи
   *
   * @type {Array<Object>}
   */
  @observable
  exportCols = [];

  @observable
  fields = [];

  /**
   * Валидатор uid РМ шаблона КП
   *
   * @param {String} uid РМ шаблона КП
   *
   * @return {Object} { isValids: true|false, hint: ""}
   */
  static validateUid(uid = "") {
    if (uid.length === 0) {
      return {
        isValid: false,
        hint:    "Необходимо задать UID шаблона"
      };
    }
    
    const res = uid.length === 32;
    if (!res) {
      return {
        isValid: false,
        hint:    "UID текстового представления шаблона схемы дб 32 символа"
      };
    }
    return { isValid: true };
  }

  /**
   * Валидатор версии РМ шаблона КП
   *
   * @param {Number} version версия РМ шаблона КП
   *
   * @return {Object} { isValids: true|false, hint: ""}
   */
  static validateVersion(version) {
    if (isNaN(Number(version)) ||
        parseInt(Number(version)) !== Number(version) || 
        isNaN(parseInt(version, 10))) {
      return {
        isValid: false,
        hint:    "Версия д.б. целочисленная"
      };
    }

    if (Number(version) < 0) {
      return {
        isValid: false,
        hint:    "Версия д.б. больше или равна нулю"
      };
    }
    
    return { isValid: true };
  }

  /**
   * Валидатор названия РМ шаблона КП
   * Точнее если по uid РМ шаблона нашлась нода в Библиотеке,то все хорошо.
   * Если не нашлась, то значит такого шаблона не существует. 
   * Поиск названия шаблона будет происходить отдельным запросом 
   *
   * @param {String} uid РМ шаблона КП
   *
   * @return {Object} { isValid: true|false, hint: ""}
   */
  static validateName(name) {
    if (!name) {
      return {
        isValid: false,
        hint:    "Не найдено текстовое представление шаблона КП в Библиотеке"
      };
    }
    
    return { isValid: true, hint: name };
  }

  /**
   * Валидатор данных JSON схемы
   *
   * @param {String} data данные схемы
   *
   * @return {Object} { isValid: true|false, hint: ""}
   */
  static validateSchema(schema = "") {
    if (typeof schema === "object" && schema !== null) {
      return { isValid: true };
    }

    if (schema.length === 0) {
      return { isValid: true };
    }

    return { isValid: true };
  }


  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.uid uid РМ шаблона КП
   * @param {JSON} params.schema json представление схемы
   *
   * @retrun {CoordTemplateModel}
   */
  static create({
    uid,
    version = 0,
    lastNumber,
    schema
  }, issueStore) {
    return new CoordTemplateModel({
      uid,
      version,
      lastNumber,
      schema
    }, issueStore);
  }

  constructor(params, issueStore) {
    this.uid = params.uid;
    this.version = params.version || 0;
    this.schema = params.schema;
    this.lastNumber = params.lastNumber;

    this.issueStore = issueStore;

    this.generateFields();
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "CoordTemplateModel";
  }

  /**
   * @action
   *
   * Обновить св-ва модели
   * @param {Object} params параметры модели
   * @param {String} params.uid uid  РМ шаблона
   */
  @action
  update(data) {
    this.uid = data.uid || this.uid;
    this.version = data.version || this.version;
    this.lastNumber = data.lastNumber || this.lastNumber;
    this.schema = data.shema || this.schema;
  }

  /**
   * @action
   *
   * Задать uid РМ шаблона КП
   * @param {String} uid РМ шаблона КП
   */
  @action
  setUid(uid) {
    this.uid = uid;
  }

  /**
   * @action
   *
   * Задать версию РМ шаблона КП
   * @param {Number} version версия РМ шаблона КП
   */
  @action
  setVersion(version) {
    this.version = version;
  }

  /**
   * @action
   *
   * Задать uid РМ шаблона КП
   * @param {JSON} shema схема шаблона
   */
  @action
  setSchema(schema) {
    this.schema = schema;
    this.generateFields();
  }

  /**
   * @action
   *
   * Задать название РМ шаблона КП
   * @param {String} name названия шаблона в Библиотеке
   */
  @action
  setName(name) {
    this.name = name;
  }

  /**
   * @computed
   *
   * Строковое представление названия шаблона в Библиотеке
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.name || this.uid;
  }

  /**
   * @computed
   *
   * Новый следующий номер
   *
   * @return {Number}
   */
  @computed
  get nextNumber() {
    return this.lastNumber + 1;
  }

  /**
   * @computed
   *
   * Получить префикс номера
   *
   * @return {String}
   */
  @computed
  get numPrefix() {
    return this.schema && this.schema.numPrefix;
  }

  /**
   * Cформирвоать список полей по schema
   * 
   */
  generateFields() {
    const res = [];
    if (this.schema && Array.isArray(this.schema.fields)) {
      this.schema.fields.forEach((cfg) => {
        res.push(CustomFieldModel.create(cfg, this.issueStore));
      });
    }
    this.fields = res;
  }

  /**
   * @computed
   *
   * Получить список полей, прописанных в схеме
   *
   * @return {Array<CustomFieldModel>}
   */
  // @computed
  // get fields() {
  //   const res = [];
  //   if (this.schema && Array.isArray(this.schema.fields)) {
  //     this.schema.fields.forEach((cfg) => {
  //       res.push(CustomFieldModel.create(cfg, this.issueStore));
  //     });
  //   }
  //   return res;
  // }

  /**
   * @action
   *
   * Обновить значения в полях
   *
   * @param {Object} значения полей, раположенные под ключем - id  поля
   */
  @action
  updateFieldsValue(values) {
    this.fields.forEach((field) => {
      field.setValue(values[field.id]);
    });
  }

  /**
   * @computed
   *
   * Идентификатор кастомного поля задачи, куда вервис Tasks будет записать номер КП.
   * Это значение дб записано в shema шаблона
   *
   * @return {String}
   */
  @computed
  get cfIdForMemoNumber() {
    return this.schema && this.schema.cfIdForMemoNumber;
  }

  /**
   * Задать список выбранных полей для сводной таблицы КП
   * 
   * @param {Array<Object>} cols список выбранных полей
   */
  @action
  setMemoCols(cols = []) {
    this.memoCols = cols;
  }

  /**
   * Задать список выбранных полей для таблицы карточки Задача
   * 
   * @param {Array<Object>} cols список выбранных полей
   */
  @action
  setExportCols(cols = []) {
    this.exportCols = cols;
  }

  /**
   * Задать список uid'ов, выбранных задач
   * 
   * @param {Array<String>} uids список uid'ов, выбранных задач
   */
  @action
  setIssueUids(uids = []) {
    this.issueUids = uids;
  }

  /**
   * Получить строковое представление номера КП на основе заданного префикса в schema,
   * сквозного номера и заданной даты
   */
  @computed
  get coordLetterTitle() {
    const numField = this.fields.filter((f) => {
      return f.fieldFormat === "coordLetterNumber";
    })[0];

    if (!numField) {
      return "";
    }

    return `${this.numPrefix}${this.nextNumber}-${moment(numField.value).format("DD.MM.YYYY")}`;
  }

  /**
   * Сформировать набор параметров для запроса создания КП
   * 
   * @params {}
   * @return {String}
   */
  @computed
  get paramsForCoordLetter() {
    const templateFields = this.fields.map((f) => {
      let value = f.value;
      switch (f.fieldFormat) {
        case "coordLetterNumber":{
          value = this.coordLetterTitle;
          break;
        }
        case "date":{
          value = value && moment(value).format("DD.MM.YYYY");
          break;
        }
        case "user":{
          if (value) {
            if (Array.isArray(value)) {
              const res = [];
              value.forEach((id) => {
                // const user = this.issueStore.rootStore.userStore.get(id);
                const user = this.issueStore.getUser(id);
                if (user) {
                  res.push(user.shortName);
                }  
              });
              value = res;
            } else {
              // const user = this.issueStore.rootStore.userStore.get(value);
              const user = this.issueStore.getUser(value);
              value = user && user.shortName;
            }
          }
          break;
        }
      }
      const fieldType = f.fieldFormat === "text" ? "text" : "string";
      
      return {
        fieldName:   f.id,
        fieldValues: Array.isArray(value) ? value : [value],
        fieldType
      };
    });
    return {
      title:                 this.coordLetterTitle,
      issueUids:             toJS(this.issueUids),
      templateTextUid:       this.uid,
      templateTextVersion:   this.version,
      issuesExportFormats:   [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf"
      ],
      zipSubfolders:     true,
      cfIdForMemoNumber: this.cfIdForMemoNumber,
      templateFields,
      memoCols:          toJS(this.memoCols),
      exportCols:        toJS(this.exportCols)
    };
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  @computed
  get config() {
    return {
      uid:        this.uid,
      version:    this.version,
      schema:     this.schema,
      lastNumber: this.lastNumber
    };
  }

  /**
   * Создание клона модели
   */
  clone() {
    return CoordTemplateModel.create(this.config);
  }
}

export default CoordTemplateModel;
