import { action, computed, observable } from "mobx";
import RestrictionsForObject from "./RestrictionsForObject";

/**
 * Базовый  объект
 *
 * @type {BaseObject}
 */
class RestrictionsForTool {
  @observable tool = null;
  @observable objectByCriterionMap = new Map();
  @observable configStore = null;

  constructor(tool, store) {
    this.tool = `${tool}`;
    this.configStore = store;
  }

  @action
  addItem({
    tool,
    objectCriterion,
    objectCriterionValue,
    role,
    subjectCriterion,
    subjectCriterionValue
  }) {
    if (`${tool}` !== this.tool) {
      throw new Error("Переданы ограничения для другого инструмента");
    }
    const key = `${objectCriterion}-${objectCriterionValue}`;
    let object = this.objectByCriterionMap.get(key);
    if (!object) {
      object = new RestrictionsForObject(
        objectCriterion,
        objectCriterionValue,
        this,
        this.configStore
      );
      this.objectByCriterionMap.set(object.key, object);
    }
    object.addItem({
      key,
      role,
      subjectCriterion,
      subjectCriterionValue
    });
  }

  @computed
  get config() {
    return {
      typeCreate: this.typeCreate,
      kindCreate: this.kindCreate,
      linkConfig: this.linkConfig,
      taskConfig: this.taskConfig
    };
  }

  @computed
  get classByKind() {
    let classByKind = {};
    if (this.tool !== "*") {
      classByKind = { ...this.configStore.classByKindCommon };
    }
    this.objectByCriterionMap.forEach((restrictionsForObject) => {
      Object.assign(classByKind, restrictionsForObject.classByKind);
    });
    return classByKind;
  }

  @computed
  get typeCreate() {
    const typeCreate = {};
    this.objectByCriterionMap.forEach((object) => {
      if (object.criterion === "class") {
        typeCreate[object.value] = object.config;
      }
    });

    return typeCreate;
  }

  @computed
  get kindCreate() {
    const kindCreate = [];
    Object.entries(this.classByKind).forEach(([kind, as]) => {
      kindCreate.push({ as, kind });
    });

    return kindCreate;
  }

  @computed
  get linkConfig() {
    const linkConfig = {};
    this.objectByCriterionMap.forEach((object) => {
      if (object.criterion === "link") {
        linkConfig[object.value] = object.config;
      }
    });

    return linkConfig;
  }

  @computed
  get taskConfig() {
    const taskConfig = {};
    this.objectByCriterionMap.forEach((object) => {
      const role = object.subjectsByRoleMap.get("tasks");
      if (object.criterion === "kind" && role) {
        taskConfig[object.value] = role.config;
      }
    });

    return taskConfig;
  }
}

export default RestrictionsForTool;
