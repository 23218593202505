/**
 * Парсер js ошибка в UI
 * @param {Object} stack  ошибки
 * @param {Number} lineNumber  номер строки, где произошла ошибка
 * 
 * @returns {Object} разобранная ошибка
 */
const serializeError = (stack, lineNumber) => {
  const alt = {
    line:    lineNumber,
    message: "",
    stack:   []
  };

  Object.getOwnPropertyNames(stack).forEach((key) => {
    if (key === "stack") {
      alt[key] = stack[key].split("\n");
    } else {
      alt[key] = stack[key];
    }
  }, stack);

  return alt;
};

/**
 * Разобрать js ошибку в UI
 * 
 * @param {Object} stack  ошибки
 * @param {Number} lineNumber  номер строки, где произошла ошибка
 * 
 * @returns {Object} разобранная ошибка
 */
const createCritical = (stack, lineNumber) => {
  return serializeError(stack, lineNumber);
};

export default createCritical;
