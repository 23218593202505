import React from "react";
import ReactDOM from "react-dom";
import "@ais3p/ui-framework/bundle.css";
import "./index.scss";
import registerServiceWorker from "./registerServiceWorker";
import App from "./core/components/App";

document.oncontextmenu = function(e) {
  e.preventDefault();
  e.stopPropagation();
  return false;
};

ReactDOM.render(<App />, document.getElementById("ais-root"));
registerServiceWorker();
