import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { Components } from "@ais3p/ui-framework";

import IssueIcon from "../issues/IssueIcon";


const IssueItem = observer(({ issue, index, store, onDelete }) => {
  const onClickIssue = useCallback(() => {

  }, [issue]);

  const onDeleteItem = useCallback(() => {
    store.deleteFromCart(issue.uid);
    onDelete && onDelete(index);
  }, [issue?.uid, index, onDelete]);

  return (
    <div className="issues-cart-list-item">
      <div className="issues-cart-list-item-num">
        {index + 1}
      </div>
      <div 
        className="issues-cart-list-item-title"
        onClick={onClickIssue}
      >
        <IssueIcon store={store} issue={issue} />
        <span>{issue.title}</span>
      </div>
      <div 
        className="issues-cart-list-item-buttons"
      >
        <Components.Button
          onPress={onDeleteItem}
          icon="delete-M"
          tooltip="Удалить задачу из подборки"
          color="negative"
        />
      </div>
    </div>
  );
});

export default IssueItem;
