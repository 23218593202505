import BaseRedmineModel from "./baseRedmineModel";
import { observable, computed, action, toJS } from "mobx";

import { DOMAIN_LIBRARY } from "~/core/constants/Domains";
import CategoryModel from "./categoryModel";
import InstanceModel from "./instanceModel";
import TrackerModel from "./trackerModel";
import CustomFieldModel from "./customFieldModel";

/**
 * Модель проекта. Должна быть связь с Проектом в АИС по его uid
 *
 * @class ProjectModel
 */
class ProjectModel extends BaseRedmineModel {
  /**
   * uid проекта в АИС
   *
   * @type {String}
   */
  @observable
  uid = undefined;

  /**
   * key проекта в АИС
   *
   * @type {String}
   */
  @observable
  key = undefined;

  /**
   * Название проекта
   *
   * @type {String}
   */
  name = undefined;

  /**
   * Признак доступности инстанса, в котором находится проект
   *
   * @type {String}
   */
  available = undefined;

  /**
   * Список id пользовательских полей, описание которых находится в модели трекера (trackerModel)
   *
   * @type {Array<Number>}
   */
  customFieldIds = [];

  /**
   * Список категорий
   *
   * @type {Array<CategoryModel>}
   */
  categories = [];

  /**
   * Даныне об источнике проекта
   * 
   * @type {InstanceModel}
   */
  instance = undefined;

  /**
   * Список доступных трекеров для текущего проекта
   * 
   * @type {Array<TrackerModel>}
   */
  trackers = [];

  /**
   * Информация о головном проекте
   * 
   * @type {Object}
   */
  parent = undefined;
  
  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.id id проекта в Redmine
   * @param {String} params.uid uid проекта в АИС
   * @param {String} params.name название проекта
   * @param {Booleam} params.available признак доступности инстанса,в котомро находитяс проект
   * @param {Object} params.instance данные об инстансе Redmine
   * @param {Array<Object>} params.trackers массив трекеров, доступнях для текущего проекта
   * @param {Array<Number>} params.customFieldIds cписок id пользовательскиз полей, 
   * описание которых находится в модели трекера (trackerModel)
   * @param {Array<Object>} params.issueCategories список возможных категорий у проекта
   * @param {Object} params.parent Информация о головном проекте
   * @param {IssueStore} issueStore хранилище задач
   *
   * @retrun {ProjectModel}
   */
  static create({
    id,
    uid,
    key,
    name,
    available,
    instance,
    trackers,
    customFieldIds,
    issueCategories,
    parent
  }, issueStore) {
    return new ProjectModel({
      id,
      uid,
      key,
      name,
      available,
      instance,
      trackers,
      customFieldIds,
      issueCategories,
      parent
    }, issueStore);
  }

  constructor(params, issueStore) {
    super(params, issueStore);

    this.uid = params.uid;
    this.key = params.key;
    this.name = params.name;
    this.available = params.available;
    this.parent = params.parent;
    this.instance = params.instance && InstanceModel.create(params.instance);
    this.trackers = Array.isArray(params.trackers) ? params.trackers.map((tr) => {
      return TrackerModel.create(tr, issueStore);
    }) : [];
    this.customFieldIds = Array.isArray(params.customFieldIds) ?  params.customFieldIds : [];
    if (Array.isArray(params.issueCategories)) {
      params.issueCategories.forEach((cat) => {
        this.categories.push(CategoryModel.create(cat, issueStore));
      });
    }    
  }

  /**
   * Вид модели
   * @return {String}
   */
  get kindModel() {
    return "projectModel";
  }

  /**
   * Обновить св-ва модели
   * @param {Object} params параметры модели
   * @param {String} params.name название проекта
   */
  @action
  update(data) {
    this.name = data.name || this.name;
  }

  /**
   * Строковое представление проекта
   *
   * @return {String}
   */
  @computed
  get title() {
    if (this.issueStore) {
      const kindMember = this.issueStore.rootStore.kindsStore.getKindMemberSync(this.uid);
      if (kindMember && kindMember.item) {
        const object = this.issueStore.rootStore.objectStore.getVersion(kindMember.item.uid, DOMAIN_LIBRARY);
        if (object) {
          return object.title;
        }
      }
    }
    return this.name || this.uid;
  }

  /**
   * Строковое представление иконки проекта
   *
   * @return {String}
   */
  @computed
  get icon() {
    if (this.issueStore) {
      const kindMember = this.issueStore.rootStore.kindsStore.getKindMemberSync(this.uid);
      if (kindMember) {
        return  kindMember.iconString;
      }
    }

    return "project-M";
  }

  /**
   * Флаг, указывающий если связь проекта с проектом в АИС
   *
   * @return {Boolean}
   */
  @computed
  get isLinked() {
    return !!this.uid;
  }

  /**
   * Признак, что данные из проекта можно только читать
   *
   * @return {Boolean}
   */
  @computed
  get readOnly() {
    return this.instance?.readonly || this.instance?.external;
  }

  /**
   * Признак, что данные проект находится на внешнем источнике
   *
   * @return {Boolean}
   */
  @computed
  get isExternal() {
    return this.instance?.external;
  }

  /**
   * Список костомных полей, возмодных у карточек Задач в проекте
   *
   * @return {Array<CustomField>}
   */
  @computed
  get customFields() {
    if (!this.issueStore) {
      return [];
    }
    if (this.customFieldIds.length === 0) {
      return [];
    }
    
    const fieldsMap = new Map(this.customFieldIds.map((id) => {
      return [id, false];
    }));
    this.issueStore.trackerList.forEach((tracker) => {
      tracker?.customFields.forEach((field) => {
        if (fieldsMap.get(field.id) === false) {
          fieldsMap.set(field.id, field);
        }
      });
    });
    
    return Array.from(fieldsMap.values());
  }

  /**
   * Список костомных полей, возможных у карточек Задач в проекте по которым можно фильтровать
   *
   * @return {Array<CustomField>}
   */
  @computed
  get filterCustomFields() {
    return this.customFields.filter((field) => {
      return field.filter;
    }).map((field) => {
      // возвращаем модель без валидации
      return CustomFieldModel.create(
        { 
          ...field.config, 
          required:  false,
          minLength: 0,
          maxLength: 0
        }, this.issueStore);
    });
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  get config() {
    const cfg = {
      id:             this.id,
      uid:            this.uid,
      key:            this.key,
      name:           this.name,
      available:      this.available,
      instance:       this.instance?.config,
      customFieldIds: toJS(this.customFieldIds)
    };
    if (this.categories && this.categories.length > 0) {
      cfg.issueCategories = this.categories.map((cat) => {
        return cat.config;
      });
    }
    return cfg;
  }
}

export default ProjectModel;
