import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import moment from "moment";

import IssueStore from "../../stores/IssueStore";
import { IssueModel } from "../../models";
import CustomField from "../customFields/CustomField";

/**
 * Компонент для отображения списка пользовательских полей
 * 
 * @param {Object} props набор параметров
 * @param {IssueStore} props.store хранилище для работы с задачами
 * @param {ProjectModel} props.project текущий проект
 * @param {String} props.className пользовательский className
 * @param {Boolean} props.readOnly флаг указывающий, что файлы в спсиске только можно просмматривать
 */
const CustomFields = observer(
  ({
    store,
    project,
    className,
    values,
    onChange
  }) => {
    if (!project) {
      return null;
    }

    if (project.filterCustomFields.length === 0) {
      return null;
    }

    const fieldsInPanels = useMemo(() => {
      if (project.filterCustomFields.length === 0) {
        return [[], []];
      }
      if (project.filterCustomFields.length === 1) {
        return [[project.filterCustomFields[0]], []];
      }
      // const indexToSplit = Math.floor(issue.customFields.length / 2);
      const indexToSplit = Math.ceil(project.filterCustomFields.length / 2);
      const leftFields = project.filterCustomFields.slice(0, indexToSplit);
      const rightFields = project.filterCustomFields.slice(indexToSplit);
      return [leftFields, rightFields];
    }, [project.filterCustomFields && project.filterCustomFields.length]);

    /**
     * Callback на изменение поля значения
     * 
     * @params {Any} value значение переменной
     * @params {String} name имя переменной
     * @params {String} type тип переменной
     */
    const onFieldChange = useCallback((value, name, type) => {
      let val = value;
      switch (type) {
        case "date":{
          if (value) {
            val = moment(value).toDate();  
          } 
          break;
        }
      }
      // onChange && onChange(val, Number(name));
      onChange && onChange(val, String(name));
    }, [onChange]);

    if (!fieldsInPanels[0] || fieldsInPanels[0]?.length === 0) {
      return null;
    }

    return (
      <div className={`filters-content-parameters ${className ? className : ""}`}>
        {fieldsInPanels[0]?.length > 0 &&
          <div className="filters-content-parameters-fields">
            {fieldsInPanels[0].map((customField) => {
              return (
                <CustomField
                  key={customField.id}
                  customField={customField}
                  value={values[customField.id]}
                  store={store}
                  checkRequired={false}
                  onChange={onFieldChange}
                />
              );
            })}
          </div>
        }
        {fieldsInPanels[1]?.length > 0 &&
          <>
            <div className="vertical-divider" />
            <div className="filters-content-parameters-fields">
              {fieldsInPanels[1].map((customField) => {
                return (
                  <CustomField
                    key={customField.id}
                    customField={customField}
                    value={values[customField.id]}
                    store={store}
                    checkRequired={false}
                    onChange={onFieldChange}
                  />
                );
              })}
            </div>
          </>
        }
      </div>
    );
  }
);

CustomFields.propTypes = {
  className: PropTypes.string,
  store:     PropTypes.instanceOf(IssueStore), 
  issue:     PropTypes.instanceOf(IssueModel), 
  readOnly:  PropTypes.bool 
};

export default CustomFields;
