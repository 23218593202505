import React, { useCallback, useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Field, Components } from "@ais3p/ui-framework";

import AisIcon from "~/core/components/AisIcon";
import CodeForm from "./CodeForm";
import AttrsForm from "./AttrsForm";
import FieldItem from "./FieldItem";
import { FILTERS_CFG } from "../../constants/config";
import { CONDITION_EQUAL, CONDITION_NOT_EQUAL } from "../../constants/condtions";

import ConditionPicker from "../condition/ConditionPicker";
import SearchStore from "../../stores/searchStore";

import { useStores } from "~/core/utils";

/**
 * @component
 * Панель с фильтрами для поиска артефактов
 * 
 * @param {Object} params набор параметров
 * @param {String} params.className пользовательский className контрола
 * @param {SearchStore} params.store хранилизе для поиска
 */
const FilterPanel = observer(({ className, store }) => {
  const { kindsStore } = useStores();
  const k = store.getItemConfig(FILTERS_CFG)?.kind;
  const [codes, setCodes] = useState(
    store.getItemConfig(FILTERS_CFG)?.codes || {}
  );
  const [attributes, setAttributes] = useState(
    store.getItemConfig(FILTERS_CFG)?.attributes || {}
  );
  // const [kind, setKind] = useState(k && k.value && kindsStore.getKind(k.value));
  const [version, setVersion] = useState(
    store.getItemConfig(FILTERS_CFG)?.version || {
      value:     "0",
      condition: CONDITION_EQUAL
    }
  );
  const [kindUid, setKindUid] = useState(k);

  const filtersPanelRef = useRef();

  const currentKind = useMemo(() => {
    return kindsStore.getKind(kindUid);
  }, [kindUid, kindsStore]);

  useEffect(() => {
    if (isValid) {
      // onSetFilters();
    }
  }, []);

  const onChangeCode = useCallback((name, params) => {
    setCodes((state) => {
      return {
        ...state,
        [name]: params
      };
    });
  }, []);

  const onChangeAttribute = useCallback((name, params) => {
    setAttributes((state) => {
      return {
        ...state,
        [name]: params
      };
    });
  }, []);

  const onResetFilters = useCallback(() => {
    setKindUid(null);
    // setKind(null);
    setCodes({});
    setAttributes({});
    setVersion({ value: "0", condition: CONDITION_EQUAL });
    store.setItemConfig(FILTERS_CFG, undefined);
  }, []);

  const onSetFilters = useCallback(async() => {
    if (store.isProcessing) {
      return;
    }
    const cfg = store.getItemConfig(FILTERS_CFG);
    store.setItemConfig(FILTERS_CFG, {
      ...cfg,
      version,
      kind: kindUid,
      codes,
      attributes
    });
    store.setCurrentPage(1);
    await store.searchEntities(codes, attributes, version, kindUid);
  }, [codes, attributes, version, kindUid]);

  const onChangeKind = useCallback((value) => {
    setKindUid(value);
    if (value) {
      if (
        !(
          store.getItemConfig(FILTERS_CFG).kind &&
          store.getItemConfig(FILTERS_CFG).kind === value
        )
      ) {
        setCodes({});
        setAttributes({});
      }
    }
  }, []);

  const onClearKind = useCallback(() => {
    setKindUid(null);
    // setKind(null);
    setCodes({});
    setAttributes({});
  }, []);

  const onChangeVersion = useCallback((uid, value) => {
    const val = isNaN(parseInt(value.value)) ? 0 : parseInt(value.value);
    setVersion({ ...value, value: String(val) });
  }, []);

  const isValid = useMemo(() => {
    let b = !!kindUid && (version && version.value >= 0);
    if (Object.keys(codes).length > 0) {
      Object.keys(codes).forEach((key) => {
        if (codes[key].type === "boolean") {
          b = true;
        } else {
          b = b || !!codes[key].value;
        }
      });
    }

    if (Object.keys(attributes).length > 0) {
      Object.keys(attributes).forEach((key) => {
        if (attributes[key].type === "boolean") {
          b = true;
        } else {
          b = b || !!attributes[key].value;
        }
      });
    }

    return b;
  }, [kindUid, codes, attributes, version]);

  const onClick = useCallback(() => {
    store.setConditionPickerData(null);
  }, []);

  return (
    <div
      className={classNames("search-entites-filters", className)}
      ref={filtersPanelRef}
      onClick={onClick}
    >
      <div className="filters-header">
        <AisIcon icon="filiter-M" />
        <span>Набор фильтров</span>
      </div>
      <div className="filters-body">
        <div className="filter-kind">
          <Field.SingleSelect
            // icon={"kinds-M"}
            icon=""
            size={2}
            label="Вид"
            name={"kind"}
            labelOnTop={true}
            placeholder="Выберите вид"
            value={kindUid}
            onChange={onChangeKind}
            isMulti={false}
            isClearable={false}
            isRequired={false}
            options={kindsStore.kindsForSelect}
          />
          <Components.Icon
            {...{
              "data-tooltip": "Очистить поле"
            }}
            onClick={onClearKind}
            name={"clean-M"}
          />
        </div>
        <div className="filter-version">
          <FieldItem
            label={"Версия (0-текущая)"}
            uid={"version"}
            value={version}
            defaultValue={"0"}
            // type={"integer"}
            type={"string"}
            conditions={[CONDITION_EQUAL, CONDITION_NOT_EQUAL]}
            store={store}
            onChange={onChangeVersion}
          />
        </div>
        <CodeForm
          kind={currentKind}
          values={codes}
          store={store}
          onChange={onChangeCode}
        />
        <AttrsForm
          kind={currentKind}
          values={attributes}
          store={store}
          onChange={onChangeAttribute}
        />
      </div>
      <div className="filters-footer">
        <Components.Button
          onPress={onResetFilters}
          icon="clean-M"
          text="Сбросить"
          color="light"
        />
        <Components.Button
          onPress={onSetFilters}
          theme="success"
          icon="ok-M"
          isLoading={store.isProcessing}
          isDisabled={!isValid}
          text="Найти"
          color="positive"
        />
      </div>
      {store.conditionPickerData && (
        <ConditionPicker
          {...store.conditionPickerData}
          filtersPanelRef={filtersPanelRef}
          store={store}
        />
      )}
    </div>
  );
});

FilterPanel.propTypes = {
  className: PropTypes.string,
  store:     PropTypes.instanceOf(SearchStore) 
};
export default FilterPanel;
