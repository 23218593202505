import { observable, computed, action } from "mobx";

/**
 * Модель экземпляра Redmine
 *
 * @class InstanceModel
 */
class InstanceModel {
  /**
   * uid экземпляра Redmine
   *
   * @type {String}
   */
  @observable
  uid = undefined;

  /**
   * Название экземпляра Redmine
   *
   * @type {String}
   */
  @observable
  name = undefined;

  /**
   * url до экземпляра Redmine
   *
   * @type {String}
   */
  @observable
  url = undefined;

  /**
   * Признак того, что мы можем только читать данные с экземпляра Redmine
   *
   * @type {Boolean}
   */
  @observable
  readonly = true;

  /**
   * Признак активности экземпляра Redmine
   *
   * @type {Boolean}
   */
  @observable
  active = undefined;

  /**
   * Внешний ли экземпляра Redmine
   *
   * @type {Boolean}
   */
  @observable
  external = undefined;

  
  /**
   * Валидатор названия экземпляра Redmine
   *
   * @param {String} name название экземпляра Redmine
   *
   * @return {Object} { isValid: true|false, hint: ""}
   */
  static validateName(name = "") {
    const res = name.length >= 2;
    if (!res) {
      return {
        isValid: false,
        hint:    "Название должно быть больше одного символа"
      };
    }
    return { isValid: true };
  }

  /**
   * Валидатор url экземпляра Redmine
   *
   * @param {String} url экземпляра Redmine
   *
   * @return {Object} { isValid: true|false, hint: ""}
   */
  static validateUrl(url = "") {
    // const regex = /[(http(s)?)://(www.)?a-zA-Z0-9:._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9:%_+.~#?&//=]*)/gi;
    // eslint-disable-next-line max-len
    // const  regex = /^(https?|ftp):\/\/([a-z0-9-.]+\.[a-z]{2,}|[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})?(:\d+)?\/?[^\s]*$|^([a-z0-9-.]+)(?::(\d+))?\/?[^\s]*$/i;
    
    // const isValid = url.match(regex);
    // const isValid = regex.test(url);
    // if (!isValid) {
    //   return {
    //     isValid: false,
    //     hint:    "Неверный формат url",
    //   };
    // }
    // return { isValid: true };

    const res = url.length >= 4;
    if (!res) {
      return {
        isValid: false,
        hint:    "URL должен быть больше четырех символов"
      };
    }
    return { isValid: true };
  }

  /**
   * Валидатор аторизационнного токена к экземпляру Redmine
   *
   * @param {String} token авторизационный токен к экземпляру Redmine
   *
   * @return {Object} { isValid: true|false, hint: ""}
   */
  static validateToken(token = "") {
    const res = token.length >= 2;
    if (!res) {
      return {
        isValid: false,
        hint:    "Авторизационный токен должен быть больше одного символа"
      };
    }
    return { isValid: true };
  }

  /**
   * Cоздание модели
   *
   * @param {Object} params параметры модели
   * @param {String} params.uid uid экземпляра Redmine
   * @param {String} params.name название экземпляра Redmine
   * @param {String} params.accessToken аторизационный токен к экземпляру Redmine
   * @param {String} params.readonly признак того, что мы можем только читать данные с экземпляра Redmine
   * @param {String} params.active признак активности экземпляра Redmine
   * @param {String} params.external внешний ли экземпляра Redmine
   *
   * @retrun {InstanceModel}
   */
  static create({
    uid,
    name,
    url,
    readonly,
    active,
    external
  }) {
    return new InstanceModel({
      uid,
      name,
      url,
      readonly,
      active,
      external
    });
  }

  constructor(params) {
    this.uid = params.uid;
    this.name = params.name;
    this.url = params.url;
    this.readonly = params.readonly;
    this.active = params.active;
    this.external = params.external;
  }

  /**
   * Вид модели
   * @return {String}
   */
  @computed
  get kindModel() {
    return "InstanceModel";
  }

  /**
   * @action
   *
   * Обновить св-ва модели
   * @param {Object} params параметры модели
   * @param {String} params.uid uid экземпляра Redmine
   * @param {String} params.name название экземпляра Redmine
   * @param {String} params.accessToken аторизационный токен к экземпляру Redmine
   * @param {String} params.readonly признак того, что мы можем только читать данные с экземпляра Redmine
   */
  @action
  update(data) {
    if (data.hasOwnProperty("name")) {
      this.name = data.name;
    }
    if (data.hasOwnProperty("url")) {
      this.url = data.url;
    }
    if (data.hasOwnProperty("accessToken")) {
      this.accessToken = data.accessToken;
    }
    if (data.hasOwnProperty("readonly")) {
      this.readonly = data.readonly;
    }
    if (data.hasOwnProperty("readonly")) {
      this.active = data.active;
    }
    if (data.hasOwnProperty("readonly")) {
      this.external = data.external;
    }
  }

  /**
   * @computed
   *
   * Строковое представление проекта
   *
   * @return {String}
   */
  @computed
  get title() {
    return this.name;
  }

  /**
   * @computed
   * Значение для конфигурационного файла.
   *
   * @return {Object} данные
   */
  @computed
  get config() {
    return {
      uid:      this.uid,
      name:     this.name,
      url:      this.url,
      readonly: this.readonly,
      active:   this.active
    };
  }
}

export default InstanceModel;
