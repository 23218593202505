import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react";
import { Components, Modal } from "@ais3p/ui-framework";

import Target from "~/core/components/Target";
import useStores from "~/core/utils/useStores";

import infoToolContent from "~/core/components/InfoToolWindow/infoToolContent.js";
import AttachedIssueStore from "../stores/AttachedIssueStore";
import { AttachedIssues, CreateIssueWiazard, SearchIssueForm } from "../components/attachedIssues";

import { SIDEPANEL_ISSUES } from "~/core/constants/SidePanels";

import logger from "~/core/logger";

import "./css/sidepanel.scss";

/**
 * @component
 * 
 * Боковая панель для связывания артефактов с карточками Задача
 * 
 * @param {Object} trackedItem активный элемент в АИС
 */
const IssuesSidepanel = observer((props) => {
  const { trackedItem } = props;
  
  const [isShowLinkModal, setIsShowLinkModal] = useState(false);
  const [isShowCreateWizard, setIsShowCreateWizard] = useState(false);

  const { rootStore, layoutStore } = useStores();

  const attachedIssueStore = useMemo(() => {
    return new AttachedIssueStore({ rootStore });
  }, [rootStore]);

  useEffect(() => {
    logger.info("issues.IssuesSidepanel | Инициализация боковой панели Задачи");
    attachedIssueStore.init();
  }, []);

  const { isPending } = attachedIssueStore;

  useEffect(() => {
    if (trackedItem?.uid) {
      attachedIssueStore.loadAttachedIssues(trackedItem.uid, trackedItem.version);
    }
  }, [trackedItem?.uid, attachedIssueStore]);

  const onCloseModal = useCallback(() => {
    setIsShowLinkModal(false);
  }, []);

  const onFormSubmit = useCallback(() => {
    setIsShowLinkModal(false);
  }, []);

  const modalButtons = useMemo(() => {
    return ([
      <Components.Button
        key="close"
        icon="close-M"
        text="Закрыть"
        color="negative"
        onPress={onCloseModal}
      />
    ]);
  }, []);

  const onStartCreateIssue = useCallback(() => {
    setIsShowCreateWizard((state) => {
      return !state;
    });
  }, []);

  const onCancelCreateIssue = useCallback(() => {
    setIsShowCreateWizard(false);
  }, []);

  const onCreatedIssue = useCallback(async(relationCfg, issue) => {
    if (!relationCfg || !issue) {
      return;
    }
    try {
      const { relationKindUid, direction } = relationCfg;
      if (!relationKindUid || !direction) {
        return;
      }

      const  res = await attachedIssueStore.linkAttachedIssue(issue, trackedItem, relationKindUid, direction);
      if (res) {
        if (trackedItem?.uid) {
          attachedIssueStore.loadAttachedIssues(trackedItem.uid, trackedItem.version);
        }
      }
    } catch (ex) {
      attachedIssueStore.onError(ex);
    }
  }, [trackedItem]);

  const onShowTmpIssue = useCallback(({ projectUid, tracker, icon, relationCfg }) => {
    setIsShowCreateWizard(false);
    if (!projectUid || !tracker) {
      return;
    }

    layoutStore.open({
      name:      tracker.name,
      id:        projectUid,
      icon,
      component: "tasks",
      props:     {
        id:            projectUid,
        focusUid:      "tmp",
        issueTracker:  tracker,
        relationCfg,
        onCreateIssue: onCreatedIssue.bind(this, relationCfg)
      }
    });
  }, [trackedItem, layoutStore, onCreatedIssue]);

  const onLinkIssue = useCallback(() => {
    attachedIssueStore.clearFoundedIssues();
    setIsShowLinkModal(true);
  }, [trackedItem, attachedIssueStore]);

  return (
    <div className="issues-sidepanel">
      <Target trackedItem={trackedItem} info={infoToolContent[SIDEPANEL_ISSUES]} />
      <div className="issues-sidepanel-body">
        {isPending && (
          <Components.Preloader size={3} className="preloader-center" />
        )}
        {trackedItem && 
          <div className="issues-sidepanel-toolbar">
            {attachedIssueStore.localProjectList.length > 0 &&
              <Components.Button 
                icon="plus-M"
                text="Создать"
                tooltip="Создать новую задачу"
                isDisabled={isPending}
                onPress={onStartCreateIssue}
              />
            }
            <div />
            <Components.Button 
              icon="link-M"
              text="Связать"
              tooltip="Связать с существующей задачей"
              isDisabled={isPending}
              onPress={onLinkIssue}
            />
            {isShowCreateWizard &&
              <CreateIssueWiazard 
                store={attachedIssueStore} 
                onCancel={onCancelCreateIssue}
                onCreate={onShowTmpIssue}
              />
            }
          </div>
        }
        <AttachedIssues 
          target={trackedItem}
          store={attachedIssueStore}
        />
      </div>
      <Modal.Window
        name="workspace"
        icon={"link-M"}
        show={isShowLinkModal}
        title={"Привязка задачи"}
        buttons={modalButtons}
        onKeyPressEsc={onCloseModal}
        onKeyPressEnter={onFormSubmit}
      >
        <SearchIssueForm
          target={trackedItem}
          store={attachedIssueStore}
        />
      </Modal.Window>
    </div>
  );
});

IssuesSidepanel.propTypes = {
  trackedItem: PropTypes.object,   
  hasTarget:   PropTypes.bool
};


export default IssuesSidepanel;
