import React, { useCallback, useEffect, useState } from "react";
import { Provider, observer } from "mobx-react";
import {  Dnd, Notification } from "@ais3p/ui-framework";
import process from "process";

import AppContainer from "~/core/components/AppContainer";
import Confirm from "./Confirm";
import ReloadBtn from "./ReloadBtn";
import WizardTool from "../../modules/wizard/components/WizardTool";
import { errorNotify } from "../utils";
import RootStore from "~/core/data/stores/rootStore";
import { LoggerContainer } from "../logger";

import "@ais3p/ui-framework/bundle.css";
import "~/App.scss";
import packageJson from "~/../package.json";
/**
 * Приложение АИС ППП
 * 
 * @param {Boolean} hasError признак наличия системной ошибки
 * @param {Error} error системная ошибка
 */
const App = observer(() => {
  const [rootStore] = useState(new RootStore());
  
  useEffect(() => {
    return (() => {
      rootStore.destroy();  
    });
  }, [rootStore]);

  const onReload = useCallback(() => {
    const { accountStore } = rootStore;
    accountStore.clearUserData();
    window.location.reload(true);
  }, [rootStore]);

  const {
    userStore,
    accountStore,
    relationStore,
    marksStore,
    objectStore,
    kindsStore,
    groupsStore,
    uiStore,
    configStore,
    workflowStore,
    aboutStore,
    wsStore,
    textStore
  } = rootStore;

  const { showError, errorText, errorIsCritical, errorStatus, successText, errorDetail } = uiStore;

  const onDismissError = useCallback(() => {
    uiStore.dismissError();
  }, [rootStore]);

  const onDismissSuccess = useCallback(() => {
    uiStore.dismissSuccess();
  }, [rootStore]);

  useEffect(() => {
    if (showError && errorText) {
      const contentToShow = errorIsCritical ? <ReloadBtn onReload={onReload} errorText={errorText} /> : errorText;
      if (errorStatus === 400 || errorStatus === 404 || errorStatus ===  408 || errorStatus === 403) {
        Notification.warning(errorText, { onClose: () => {
          onDismissError(); 
        } });
      } else {
        errorNotify(contentToShow, errorDetail, onDismissError, errorIsCritical);
      }
    }
    if (successText) {
      Notification.success(successText, { onClose: () => {
        onDismissSuccess(); 
      } });
    }
  }, [showError, errorText, errorIsCritical, errorStatus, successText, errorDetail]);  

  // Отображаем сообщения в tooltip, полученные через loggers, согласно его типу (level) 
  const showMessage = useCallback((level, message, important) => {
    if (important) {
      switch (level) {
        case "info":{
          typeof message === "string" && Notification.info(message, 3000);
          break; 
        }
        case "warning":{
          typeof message === "string" && Notification.warning(message, 5000);
          break; 
        }

        case "error":{
          if (typeof message === "string") {
            Notification.error(message);
          } else if (typeof message === "object") {
            console.error("onError", { message });
          }
          
          break; 
        }
      }
    }
  }, []);

  /**
   *  Добавляем доп информацю в стек данных -  информацию о текущем пользователе
   */ 
  const onPrepareStack = useCallback((stackData) => {
    return {
      ...stackData,
      user: {
        uid:  accountStore.uid,
        name: accountStore.user?.shortName
      },
      version: packageJson.version
    };
  }, [packageJson.version]);

  /**
   * Обрабатываем критическую js ошибку в UI
   */
  const onError = useCallback((stackData) => {
    if (!stackData) {
      return;
    }
    rootStore.setError(stackData.error, true, 500, stackData.detail);

    if (process?.env?.NODE_ENV === "production") {
      try {
        uiStore.saveErrors(stackData);
      } catch (ex) {
        console.error(ex);
      }
    } else {
      console.error(stackData);
    }
  }, []);

  return (
    <LoggerContainer
      limit={25} 
      stdout={showMessage} 
      onError={onError}
      onPrepareStack={onPrepareStack}
    >
      <Dnd.Provider>
        <Provider
          rootStore={rootStore}
          uiStore={uiStore}
          userStore={userStore}
          accountStore={accountStore}
          relationStore={relationStore}
          marksStore={marksStore}
          objectStore={objectStore}
          kindsStore={kindsStore}
          groupsStore={groupsStore}
          configStore={configStore}
          workflowStore={workflowStore}
          wsStore={wsStore}
          aboutStore={aboutStore}
          textStore={textStore}
        >
          <div className={`App ${uiStore.showConfirm ? "confirm" : ""}`}>
            <AppContainer />
            <div className="overlay-blur"></div>
            <Notification.Area />
            <Confirm />
            <WizardTool />
          </div>
        </Provider>
      </Dnd.Provider>
    </LoggerContainer>
  );
});

export default App;
