import React, { useState, useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { Components, Field } from "@ais3p/ui-framework";
import FoundedIssueItem  from "./FoundedIssueItem";

/**
 * Форма для поиска существующих Задач, с возмодностью в дальнейшем их привязать к активному артефакту АИС
 * 
 * @param {AttachedIssuesStore} store хранилище для работы с прикрепленным задачами
 * @param {Object} target активный АИС  объектом
 * @param {String} currentProjectUid uid текущего проекта
 */
const SearchIssueForm = observer(({ store, target, currentProjectUid }) => {
  let timer = 0; // таймер для принятия решения - закончено ли изменение ширины столбца
  const delay = 1000; // задержка мс для принятия решения - закончено ли изменение ширины столбца

  const [projectUid, setProjectUid] = useState(currentProjectUid || "all");
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    store.init();
  }, []);

  const projects = useMemo(() => {
    const res = [{ value: "all", label: "Все проекты" }];
    store.projectList.forEach((pr) => {
      res.push({ value: pr.uid, label: pr.title, icon: pr.icon });
    });
    return res;
  }, [store.projectList?.length]);

  const onChangeProject = useCallback((value) => {
    setProjectUid(value);
    if (!!text) {
      searchIssues(text, value === "all" ? undefined : value);
    }
  }, [text]);

  const onChangeText = useCallback((value) => {
    clearTimeout(timer);
    setText(value);
    // сделал такую задержку, чтобы исключить множественные запросы при наборе
    timer = setTimeout(() => {
      if (value) {
        searchIssues(value, projectUid === "all" ? null : projectUid);
      } else {
        store.clearFoundedIssues();
      }
    }, delay);    
  }, [delay, projectUid]);

  const searchIssues = async(text, projectUid) => {
    setIsLoading(true);
    try {
      await store.searchIssuesByText(text, projectUid);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="search-issue-form">
      {(store.isPending || isLoading) && (
        <Components.Preloader size={3} className="preloader-center" />
      )}
      <div className="search-issue-form-header">
        <div className="search-issue-form-header-project">
          <Field.SingleSelect
            icon=""
            value={projectUid}
            label="Проект"
            placeholder="Выберите проект"
            onChange={onChangeProject}
            isClearable={false}
            options={projects}
          />
        </div>
        <div className="search-issue-form-header-text">
          <Field.String 
            icon=""
            label="Номер задачи или текст для поиска"
            placeholder="Задайте текст для поиска задач"
            name="text"
            onChange={onChangeText}
            value={text}
          />
        </div>
      </div>
      <div className="search-issue-form-body">
        {store.foundedIssues.length === 0 && !text &&
          <div className="not-found">Наберите текст для поиска задач</div>
        }
        {store.foundedIssues.length === 0 && !!text &&
          <div className="not-found">Задачи не найдены</div>
        }
        {store.foundedIssues.map((item) => {
          return  (
            <FoundedIssueItem 
              key={item.uid}
              item={item} 
              store={store} 
              target={target}
            /> 
          );
        })}
      </div>
    </div>
  );
});

export default SearchIssueForm;
