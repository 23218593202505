import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import moment from "moment";
import classNames from "classnames";
import { FILTERS_CFG } from "../../constants/config";
import  { statusTitle } from "../../constants/statuses";
import IssueStore from "../../stores/IssueStore";

/**
 * Текстовые заголовки параметров задачи
 */
const FILTER_TITLES = {
  tracker:            "Трекер",
  status:             "Статус",
  priority:           "Приоритет",
  author:             "Автор",
  assignedTo:         "Исполнитель",
  project:            "Проект",
  subject:            "Заголовок задачи",
  createDate:         "Дата создания",
  updateDate:         "Дата изменения",
  startDate:          "Дата начала",
  dueDate:            "Дата окончания",
  includeSubprojects: "Включать подпроекты"
};

/**
 * Поля, которые отвечают за период жизни задачи
 */
const FILTER_PERIODS = [
  "createDateFrom", "createDateTo",
  "updateDateFrom", "updateDateTo",
  "startDateFrom", "startDateTo",
  "dueDateFrom", "dueDateTo"
];

/**
 * Панель с для отображения информаци о выстваленных фильтрах
 * 
 * @param {Object} props набор параметров
 * @param {String} params.className  пользовательский className
 * @param {IssueStore} params.store хранилизе для работы с задачами
 */
const FilterInfo = observer(({ className, store, projectUid }) => {
  const projectsFilters = store.getItemConfig(FILTERS_CFG)?.projects || {};
  const filters = projectsFilters[projectUid] || {};
  const data = {};
  const project = store.getProjectByUid(projectUid);
  Array.from(Object.keys(filters)).forEach((key) => {
    if (key === "project") {
      return;
    }
    if (FILTER_PERIODS.includes(key)) {
      const dateKey = key.replace("From", "").replace("To", "");
      const from = filters[`${dateKey}From`];
      const to = filters[`${dateKey}To`];
      data[FILTER_TITLES[dateKey]] = [
        from && `с ${moment(from).format("YYYY-MM-DD")}`,
        to && `по ${moment(to).format("YYYY-MM-DD")}`
      ].join(" ");
    } else {
      let val = filters[key];
      if (key === "customFields") {
        if (typeof val === "object") {
          for (const id in val) {
            const field = project.filterCustomFields.filter((f) => {
              return Number(f.id) === Number(id);
            })[0];
            const name = field?.name || id;
            switch (field?.fieldFormat) {
              case "user":{
                const ids = Array.isArray(val[id]) ? val[id] : [val[id]];
                data[name] = ids.map((id) => {
                  const u = store.userList.filter((user) => {
                    return id === user.id;
                  })[0];
                  return u ? u.shortName : id;
                });
                break;
              }
              case "list":{
                const keys = Array.isArray(val[id]) ? val[id] : [val[id]];
                data[name] = keys.map((key) => {
                  const v = field.possibleValues.filter((item) => {
                    return key === item.key;
                  })[0];
                  return v ? v.label : key;
                });
                break;
              }
              default:{
                data[name] = val[id];
                break;
              }
            }
          }
        }
      } else {
        if (key === "status" && !Array.isArray(val)) {
          val = statusTitle(val);
        }

        if (key === "includeSubprojects") {
          val = val ? "Да" : "Нет";
        }
        data[FILTER_TITLES[key]] = Array.isArray(filters[key]) ?
          filters[key].map((val) => {
            return store.getFilterTitle(key, val);
          }).join(", ")
          : val;
      }
    }
  });

  return (
    <div className={classNames("filters-info", className)}>
      {
        Array.from(Object.keys(data)).map((key, i) => {
          return (
            <div key={i} className="filters-info-item">
              <div className="label">{`${String(key).trim()}:`}</div>
              <div className="value">{String(data[key]).trim()}</div>
            </div>
          );
        })
      }
    </div>
  );
});

FilterInfo.propTypes = {
  className: PropTypes.string,
  store:     PropTypes.instanceOf(IssueStore)
};
export default FilterInfo;
