import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { Components, ContextMenu, Notification } from "@ais3p/ui-framework";
import { SIDEPANEL_ISSUES } from "~/core/constants/SidePanels";
import useStores from "~/core/utils/useStores";
import { getPropsToOpenLayoutTool } from "~/core/utils";
import IssueGroup from "./IssueGroup";

/**
 * Список прикрепленных задач к артефакту АИС
 */
const AttachedIssues = observer(({ store, target }) => {
  const { objectStore, uiStore, layoutStore, onAddIssue } = useStores();
  
  const menuItems = useMemo(() => {
    const items = [{
      icon:   "open-M",
      type:   "read",
      title:  "Открыть",
      action: "open"
    }];
    // удалять связь можем только в редакции (версия >0)
    if (target && !target.version) {
      items.push({
        icon:   "delete-M",
        type:   "delete",
        title:  "Удалить",
        action: "delete"
      });
    }
    return items;
  }, [target && target.version]);

  const onOpenItem = useCallback(async(item) => {
    const tool = item.tool || item.domain;
    const toolToOpen = {
      component: tool
    };

    const itemToOpen = await getPropsToOpenLayoutTool(item, objectStore);

    if (!itemToOpen) {
      Notification.warning(
        "Вы пытаетесь перейти к артефакту, который недоступен. Возможно, у Вас нет прав доступа к нему.", 
        { autoClose: 3000 }
      );
      return;
    }

    layoutStore.open({ ...itemToOpen, ...toolToOpen });
  }, []);

  const onDoDeleteRelation = async(attachedIssue) => {
    uiStore.setConfirmPending(true);
    try {
      const res = await store.unlinkAttachedIssue(attachedIssue);
      res && uiStore.hideConfirm();
    } finally {
      uiStore.setConfirmPending(false);
    }
  };

  const onCancelDeleteRelation = useCallback(() => {
    uiStore.hideConfirm();
  }, []);

  const onDeleteRelation = useCallback((attachedIssue) => {
    const { issue, relation } =  attachedIssue;

    if (!issue || !relation) {
      return;
    }
    uiStore.setConfirm({
      icon:    "link-delete-M",
      title:   "Удаление связи",
      content: "Вы уверены, что хотите удалить связь c задачей?", 
      buttons: [
        <Components.Button
          key="delete"
          text="Удалить"
          icon="link-delete-M"
          // eslint-disable-next-line react/jsx-no-bind
          onPress={() => {
            onDoDeleteRelation(attachedIssue);
          }}
          color="negative"
        />,
        <Components.Button
          key="cancel"
          text="Отмена"
          icon="cancel-M"
          onPress={onCancelDeleteRelation}
          color="dark"
        />
      ],
      onKeyPressEsc:   onCancelDeleteRelation,
      onKeyPressEnter: () => {
        onDoDeleteRelation(attachedIssue);
      }
    });      
  }, [store]);

  const onMenuItemClick = useCallback((action, context) => {
    const { attachedIssue } = context;
    const { issue }  = attachedIssue;
    if (action === "open") {
      onOpenItem(issue);
    }
    if (action === "delete") {
      onDeleteRelation(attachedIssue);
    }
  }, []);
  
  return (
    <div className="attached-issues">
      {store.isProcessing &&
        <Components.Preloader size={3} className="preloader-center" /> 
      }
      {store.attachedIssueList.length === 0 &&
        <div className="header">
          <span className="text-holder">
            У объекта нет прикрепленных задач
          </span>
        </div>
      }
      {target && Object.keys((store.groupedAttachedIssues)).map((groupName) => {
        return (
          <IssueGroup 
            key={groupName}
            name={groupName}
            target={target}
            store={store}
            onAddIssue={onAddIssue}
            onDeleteRelation={onDeleteRelation}
            items={store.groupedAttachedIssues[groupName]}
          />
        );
      })}
      <ContextMenu.Menu
        id={SIDEPANEL_ISSUES} 
        items={menuItems} 
        onMenuClick={onMenuItemClick}
      />
    </div>
  );
});

export default AttachedIssues;
