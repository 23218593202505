import Server from "./server";

class UiApi extends Server {
  methods = {
    saveErrors: {
      url:         this.getServiceUrl("ui", "js/errors"),
      method:      "POST",
      textProcess: "Сохранение UI лога...",
      textSuccess: null,
      textError:   this
        .template`Во время сохранения UI лога произошла ошибка: ${0}`
    }
  };

  async saveErrors(data) {
    try {
      const resp = await this.request("saveErrors", data);
      return resp;
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }
}

export default UiApi;
