import { action } from "mobx";
import Server from "~/core/api/server";

class TextApi extends Server {
  methods = {
    getText: {
      url:         this.getServiceUrl("composer", ""),
      method:      "POST",
      accept:      "application/json",
      textProcess: "Получаю текст...",
      textSuccess: null,
      timeout:     60000,
      textError:   this
        .template`Во время получения текста произошла ошибка: ${0}`
    },
    getStyleVersion: {
      url: (params) => {
        return this.getServiceUrl(
          "styles",
          this.template`api/v1/styles/${0}/version/${1}`,
          params
        );
      },
      method:      "GET",
      accept:      "application/json",
      textProcess: "Получаю стиль...",
      textSuccess: null,
      timeout:     10000,
      textError:   this
        .template`Во время получения стиля произошла ошибка: ${0}`
    },
    getStyle: {
      url: (params) => {
        return this.getServiceUrl(
          "styles",
          this.template`api/v1/styles/${0}/latest`,
          params
        );
      },
      method:      "GET",
      accept:      "application/json",
      textProcess: "Получаю стиль...",
      textSuccess: null,
      timeout:     10000,
      textError:   this
        .template`Во время получения стиля произошла ошибка: ${0}`
    },
    createStyleLink: {
      url: () => {
        return this.getServiceUrl(
          "styles",
          "api/v1/links",
        );
      },
      method:      "POST",
      accept:      "application/json",
      textProcess: "Создаю связь стиля...",
      textSuccess: null,
      timeout:     10000,
      textError:   this
        .template`Во время создания связи стиля произошла ошибка: ${0}`
    },
    getStyleLink: {
      url: (params) => {
        return this.getServiceUrl(
          "styles",
          this.template`api/v1/links/text/${0}/version/${1}`,
          params
        );
      },
      method:      "GET",
      accept:      "application/json",
      textProcess: "Получаю связь стиля...",
      textSuccess: null,
      timeout:     10000,
      textError:   this
        .template`Во время получения связи стиля произошла ошибка: ${0}`
    },
    getTextObject: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this.template`api/v0/text-objects/${0}/versions/${1}`,
          params
        );
      },
      method:      "GET",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      textProcess: "Получаю текстовый объект...",
      textSuccess: null,
      timeout:     60000,
      textError:   this
        .template`Во время получения текстового объекта произошла ошибка: ${0}`
    },
    getDiff: {
      url: (params) => {  
        return this.getServiceUrl(
          "text",
          this.template`api/v0/text-objects/${0}/version-diff/${1}/${2}`,
          params
        );
      },
      method:      "GET",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      textProcess: "Получаю DIFF...",
      textSuccess: null,
      timeout:     60000,
      textError:   this
        .template`Во время получения DIFF произошла ошибка: ${0}`
    },
    getObjectPath: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this.template`api/v0/text-objects/${0}/versions/${1}/path`,
          params
        );
      },
      method:      "GET",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      textProcess: "Получаю путь до текстового объекта...",
      textSuccess: null,
      timeout:     60000,
      textError:   this
        .template`Во время получения пути до текстового объекта произошла ошибка: ${0}`
    },
    getTree: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this.template`api/v0/texts/${0}/structure`,
          params
        );
      },
      method:      "GET",
      textProcess: "Получаю дерево...",
      textSuccess: null,
      timeout:     30000,
      textError:   this
        .template`Во время получения дерева произошла ошибка: ${0}`
    },
    editChunk: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this.template`api/v0/chunks/${0}`,
          params
        );
      },
      method:      "PUT",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      contentType: "application/vnd.ais3p.app.text[ver.d]+json",
      textProcess: "Добавляю chunk...",
      textSuccess: null,
      timeout:     4000,
      textError:   this
        .template`Во время изменения chunk'а произошла ошибка: ${0}`
    },
    addElement: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this.template`api/v0/${0}/${1}/${2}`,
          // this.template`api/v0/${slug}/${parentUid}/${category}`,
          params
        );
      },
      method:      "POST",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      contentType: "application/vnd.ais3p.app.text[ver.d]+json",
      textProcess: "Добавляю enumeration...",
      textSuccess: null,
      timeout:     4000,
      textError:   this
        .template`Во время добавления enumeration произошла ошибка: ${0}`
    },
    deleteElement: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this.template`api/v0/${0}/${1}`,
          params
        );
      },
      method:      "DELETE",
      textProcess: "Удаляю element...",
      textSuccess: null,
      timeout:     4000,
      textError:   this
        .template`Во время удаления element произошла ошибка: ${0}`
    },
    uploadFile: {
      // Загрузка файлов
      url:         this.getServiceUrl("files", "api/v1/file"),
      method:      "POST",
      textProcess: "Загрузка файла в хранилище...",
      timeout:     60000,
      textSuccess: null,
      textError:   this
        .template`Во время загрузки файла в хранилище произошла ошибка: ${0}`
    },
    saveVersion: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this.template`api/v2/text-materials/${0}/versions`,
          params
        );
      },
      method:      "POST",
      textProcess: "Сохранение версии...",
      textSuccess: null,
      textError:   this
        .template`Во время сохранения версии произошла ошибка: ${0}`,
      timeout: 20000
    },
    file: {
      url: (params) => {
        return this.getServiceUrl(
          "files",
          this.template`api/v1/file/${0}`,
          params
        );
      },
      method:      "GET",
      textProcess: "Получение файла из хранилища...",
      textSuccess: null,
      textError:   this
        .template`Во время получения файла из харнилища произошла ошибка: ${0}`,
      timeout: 20000
    },
    insertRow: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this.template`api/v0/tables/${0}/rows`,
          params
        );
      },
      method:      "POST",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      contentType: "application/vnd.ais3p.app.text[ver.d]+json",

      textProcess: "Вставка строки...",
      textSuccess: null,
      textError:   this.template`Во время вставки строки произошла ошибка: ${0}`,
      timeout:     20000
    },
    insertColumn: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this.template`api/v0/tables/${0}/columns`,
          params
        );
      },
      method:      "POST",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      contentType: "application/vnd.ais3p.app.text[ver.d]+json",

      textProcess: "Вставка столбца...",
      textSuccess: null,
      textError:   this.template`Во время вставки столбца произошла ошибка: ${0}`,
      timeout:     20000
    },
    mergeCells: {
      url: () => {
        return this.getServiceUrl("text", "api/v0/table-cells/merge");
      },
      method:      "POST",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      contentType: "application/json",

      textProcess: "Слияние ячеек...",
      textSuccess: null,
      textError:   this.template`Во слияния ячеек произошла ошибка: ${0}`,
      timeout:     20000
    },
    splitCell: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this.template`api/v0/table-cells/${0}/split?direction=${1}`,
          params
        );
      },
      method:      "POST",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      contentType: "application/json",

      textProcess: "Разбиение ячеек...",
      textSuccess: null,
      textError:   this.template`Во разбиения ячеек произошла ошибка: ${0}`,
      timeout:     20000
    },
    moveElement: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this.template`api/v0/text-objects/${0}`,
          params
        );
      },
      method:      "PATCH",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      contentType: "application/json",
      textProcess: "Move element...",
      textSuccess: null,
      textError:   this.template`Во перемещения элемента текста произошла ошибка: ${0}`,
      timeout:     20000
    },
    deleteRow: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this.template`api/v0/tables/${0}/rows`,
          params
        );
      },
      method:      "DELETE",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      contentType: "application/json",

      textProcess: "Удаление строки...",
      textSuccess: null,
      textError:   this.template`При удалении строки произошла ошибка: ${0}`,
      timeout:     20000
    },
    deleteColumn: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this.template`api/v0/tables/${0}/columns`,
          params
        );
      },
      method:      "DELETE",
      accept:      "application/vnd.ais3p.app.text[ver.d]+json",
      contentType: "application/json",

      textProcess: "Удаление столбца...",
      textSuccess: null,
      textError:   this.template`При удалении столбца произошла ошибка: ${0}`,
      timeout:     20000
    },
    getLocks: {
      url: (params) => {
        return this.getServiceUrl(
          "admin",
          this.template`api/v2/locks?object=${0}`,
          params
        );
      },
      method:      "GET",
      accept:      "application/json",
      textProcess: "Получение блокировок...",
      textSuccess: null,
      textError:   this.template`Во время получения блокировок произошла ошибка: ${0}`,
      timeout:     20000
      // /api/v2/locks?object=817ee20853a5436ca06efca23ff69963
    },
    setLock: {
      url: () => {
        return this.getServiceUrl(
          "admin",
          "api/v2/locks",
        );
      },
      method:      "POST",
      accept:      "application/json",
      textProcess: "Блокировка...",
      textSuccess: null,
      textError:   this.template`При блокировке произошла ошибка: ${0}`,
      timeout:     20000
      // /api/v2/locks?object=817ee20853a5436ca06efca23ff69963
    },
    unsetLock: {
      url: (params) => {
        return this.getServiceUrl(
          "admin",
          this.template`api/v2/locks/${0}`,
          params
        );
      },
      method:      "DELETE",
      accept:      "application/json",
      textProcess: "Разблокировка...",
      textSuccess: null,
      textError:   this.template`При разблокировке произошла ошибка: ${0}`,
      timeout:     20000
      // /api/v2/locks?object=817ee20853a5436ca06efca23ff69963
    },
    export: {
      url: (params) => {
        return this.getServiceUrl(
          "library",
          this
            .template`api/v2/text-materials/${0}/versions/${1}/export`,
          params
        );
      },
      timeout:     300000,
      method:      "POST",
      textProcess: "Экспорт...",
      textSuccess: null,
      textError:   this.template`Во время экспорта произошла ошибка: ${0}`
    },
    moveElements: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this
            .template`api/v0/text-objects/${0}`,
          params
        );
      },
      timeout:     300000,
      method:      "PATCH",
      textProcess: "Перенос...",
      textSuccess: null,
      textError:   this.template`Во время переноса строк произошла ошибка: ${0}`
    },
    setEnumerationVariant: {
      url: (params) => {
        return this.getServiceUrl(
          "text",
          this.template`api/v0/enumerations/${0}/variant`,
          params
        );
      },
      method:      "PUT",
      accept:      "application/json",
      textProcess: "Изменение варианта отображения для списка...",
      textSuccess: null,
      textError:   this.template`При изменении варианта отображения для списка произошла ошибка: ${0}`,
      timeout:     20000
      // /api/v2/locks?object=817ee20853a5436ca06efca23ff69963
    }
  };
  // api/v0/table-cells/<cell:uid>/merge
  /**
   * [login description]
   *
   * @param  {String} uid    [description]
   * @param  {String} isLocal [description]
   */
  async text(uid, version) {
    const reqData = {
      operationName: null,
      query:         `{
        Text(uid_and_version: {uid: "${uid}", version: ${version}}) {
          uid
          class
          version
          objects {
            representation
            uid
            class
            version
            links {
              uid
              class
              type {
                uid
                class
                name
                sourceCaption
                destCaption
              }
              source {
                uid
                class
                version
              }
              dest {
                uid
                class
                version
              }
            }
            members {
              uid
              kind {
                uid
                class
                name
              }
              codeValues {
                value
                code {
                  uid
                  name
                  template
                }
              }
              values {
                attribute {
                  uid
                  name
                }
                value
              }
            }
          }
        }
      }`,
      variables: {}
    };
    return await this.request("getText", reqData);
  }

  /**
   * Загрузить тектстовый объект по uid и версии
   *
   * @param  {String} uid    uid текстового объекта
   * @param  {String} version версия тектового объекта
   */
  async loadTextObject(uid, version) {
    const reqData = {
      params: [uid, version]
    };
    return await this.request("getTextObject", reqData);
  }

  async setEnumerationVariant(uid, variant) {
    const reqData = {
      params: [uid],
      data:   {
        variant
      }
    };
    return await this.request("setEnumerationVariant", reqData);
  }

  async getStyleVersion({ name, version = 0 }) {
    const reqData = {
      params: [name, version]
    };
    return await this.request("getStyleVersion", reqData);
  }

  async getLocks({ uid }) {
    const reqData = {
      params: [uid]
    };
    return await this.request("getLocks", reqData);
  }

  async setLock({ uid }) {
    const reqData = {
      data: { object: uid, action: "lock" }
    };
    return await this.request("setLock", reqData);
  }

  async unsetLock({ uid }) {
    const reqData = {
      params: [uid]
    };
    return await this.request("unsetLock", reqData);
  }

  async getStyle({ name }) {
    const reqData = {
      params: [name]
    };
    return await this.request("getStyle", reqData);
  }
  async createStyleLink(data) {
    const reqData = {
      params: [],
      data
    };
    return await this.request("createStyleLink", reqData);
  }

  async getStyleLink({ uid, version = 0 }) {
    const reqData = {
      params: [uid, version]
    };
    return await this.request("getStyleLink", reqData);
  }

  async getDiff(uid, version, diffVersion) {
    let originVersion = Math.min(version, diffVersion);
    let comparedVersion = Math.max(version, diffVersion);
    if (version === 0) {
      originVersion = diffVersion;
      comparedVersion = version;
    }
    const reqData = {
      params: [uid, originVersion, comparedVersion]
    };
    return await this.request("getDiff", reqData);
  }
  /**
   * Загрузить путь, состоящий из uid'ов  до тектстового объект по uid и версии
   *
   * @param  {String} uid    uid текстового объекта
   * @param  {String} version версия тектового объекта
   */
  async loadObjectPath(uid, version) {
    const reqData = {
      params: [uid, version]
    };
    return await this.request("getObjectPath", reqData);
  }

  async saveVersion(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("saveVersion", reqData);
  }

  async getFile(uid) {
    const reqData = {
      params: [uid]
    };
    return await this.request("file", reqData);
  }

  async mergeCells(data) {
    const reqData = { params: [], data };
    return await this.request("mergeCells", reqData);
  }

  async splitCell(uid, direction) {
    const reqData = { params: [uid, direction], data: { direction } };
    return await this.request("splitCell", reqData);
  }

  async deleteRow(number, uid) {
    const reqData = { params: [uid], data: { "@position": number } };
    return await this.request("deleteRow", reqData);
  }

  async deleteColumn(number, uid) {
    const reqData = { params: [uid], data: { "@position": number } };
    return await this.request("deleteColumn", reqData);
  }
  
  async uploadFile(file) {
    const reqData = {
      user_uid: this.rootStore.accountStore.uid,
      file:     [file]
    };
    return await this.request("uploadFile", reqData, true);
  }

  async insertRow({ uid, position }) {
    const reqData = {
      params: [uid],
      data:   {
        "@position": position
      }
    };
    return await this.request("insertRow", reqData);
  }

  async insertColumn({ uid, position }) {
    const reqData = {
      params: [uid],
      data:   {
        "@position": position
      }
    };
    return await this.request("insertColumn", reqData);
  }

  async addElement(slug, parent, category, data) {
    const reqData = {
      params: [slug, parent, category],
      data
    };
    return await this.request("addElement", reqData);
  }

  async moveElement(elementUid, fromUid, toUid, position = 0) {
    const reqData = {
      params: [elementUid],
      data:   {
        op:   "move",
        from: fromUid,
        to:   toUid,
        position
      }
    };
    return await this.request("moveElement", reqData);
  }

  async deleteElement(uid, slug) {
    const reqData = {
      params: [slug, uid]
    };
    return await this.request("deleteElement", reqData);
  }

  async editChunk(uid, data) {
    const reqData = {
      params: [uid],
      data
    };
    return await this.request("editChunk", reqData);
  }

  async export(format, materialUID, versionNumber) {
    const reqData = {
      params: [materialUID, versionNumber],
      data:   { format }
    };
    try {
      return await this.request("export", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }

  async moveElements(targetUid, arrayOfSplitedUids, position = 0) {
    const reqData = {
      params: [targetUid],
      data:   {
        op:       "move_subtrees",
        subtrees: arrayOfSplitedUids,
        position
      }
    };
    try {
      return await this.request("moveElements", reqData);
    } catch (error) {
      this.rootStore.uiStore.setErrorText(error.message);
    }
  }
}


export default TextApi;
