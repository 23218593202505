import { Components } from "@ais3p/ui-framework";

/**
 * Значения переменных в задаче
 */
const ISSUE_VALUE_SELECTABLE = "selection";
const ISSUE_VALUE_ID = "id";
const ISSUE_VALUE_UID = "uid";
const ISSUE_VALUE_AUTHOR = "author";
const ISSUE_VALUE_ASSIGNED_TO = "assignedTo";
const ISSUE_VALUE_CREATED_ON = "createdOn";
const ISSUE_VALUE_DESCRIPTION = "description";
const ISSUE_VALUE_DONE_RATIO = "doneRatio";
const ISSUE_VALUE_DUE_DATE = "dueDate";
const ISSUE_VALUE_ESTIMATED_HOURS = "estimatedHours";
const ISSUE_VALUE_PRIORITY_ID = "priorityId";
const ISSUE_VALUE_PROJECT = "project";
const ISSUE_VALUE_START_DATE = "startDate";
const ISSUE_VALUE_STATUS_ID = "statusId";
const ISSUE_VALUE_SUBJECT = "subject";
const ISSUE_VALUE_TRACKER = "tracker";
const ISSUE_VALUE_UPDATED_ON = "updatedOn";
const ISSUE_VALUE_WATCHERS = "wathers";
const ISSUE_VALUE_JOURNAL = "journal";
const ISSUE_VALUE_RELATIONS = "relations";

/**
 * Получить `label` значения переменной в задаче
 *
 * @param {String} name название переменной
 * @return {String}
 */
const getIssueValueLabel = (name) => {
  switch (name) {
    case ISSUE_VALUE_SELECTABLE:
      return "Выбрать все";
    case ISSUE_VALUE_ID:
      return "ID";
    case ISSUE_VALUE_UID:
      return "uid";
    case ISSUE_VALUE_AUTHOR:
      return "Автор";
    case ISSUE_VALUE_ASSIGNED_TO:
      return "Исполнитель";
    case ISSUE_VALUE_CREATED_ON:
      return "Дата создания";
    case ISSUE_VALUE_DESCRIPTION:
      return "Описание";
    case ISSUE_VALUE_DONE_RATIO:
      return "Готовность, %";
    case ISSUE_VALUE_DUE_DATE:
      return "Завершить до";
    case ISSUE_VALUE_ESTIMATED_HOURS:
      return "Оценка временных затрат, ч";
    case ISSUE_VALUE_PRIORITY_ID:
      return "Приоритет";
    case ISSUE_VALUE_PROJECT:
      return "Проект";
    case ISSUE_VALUE_START_DATE:
      return "Дата начала";
    case ISSUE_VALUE_STATUS_ID:
      return "Статус";
    case ISSUE_VALUE_SUBJECT:
      return "Тема задачи";
    case ISSUE_VALUE_TRACKER:
    // return "Вид задачи";
      // eslint-disable-next-line react/react-in-jsx-scope
      return <Components.Icon name="pick-M" />;
    case ISSUE_VALUE_UPDATED_ON:
      return "Дата обновления";
    case ISSUE_VALUE_WATCHERS:
      return "Подписчики";
    case ISSUE_VALUE_JOURNAL:
      return "Журнал изменений";
    case ISSUE_VALUE_RELATIONS:
      return "Связи";
    default:
      return name;
  }
};

/**
 * Доступный набор колонок в табличном представлении
 *
 * @return {Object}
 */
const ISSUE_TABLE_COLUMNS = {
  [ISSUE_VALUE_SELECTABLE]:      { label: getIssueValueLabel(ISSUE_VALUE_TRACKER), defaultActive: true, order: 0 },
  [ISSUE_VALUE_TRACKER]:         { label: getIssueValueLabel(ISSUE_VALUE_TRACKER), defaultActive: true, order: 1 },
  [ISSUE_VALUE_ID]:              { label: getIssueValueLabel(ISSUE_VALUE_ID), defaultActive: true, order: 2 },
  [ISSUE_VALUE_SUBJECT]:         { label: getIssueValueLabel(ISSUE_VALUE_SUBJECT), defaultActive: true, order: 3 },
  [ISSUE_VALUE_STATUS_ID]:       { label: getIssueValueLabel(ISSUE_VALUE_STATUS_ID), defaultActive: true, order: 4 },
  [ISSUE_VALUE_PRIORITY_ID]:     { label: getIssueValueLabel(ISSUE_VALUE_PRIORITY_ID), defaultActive: true, order: 5 },
  [ISSUE_VALUE_START_DATE]:      { label: getIssueValueLabel(ISSUE_VALUE_START_DATE), defaultActive: true, order: 6 },
  [ISSUE_VALUE_DUE_DATE]:        { label: getIssueValueLabel(ISSUE_VALUE_DUE_DATE), defaultActive: true, order: 7 },
  [ISSUE_VALUE_ASSIGNED_TO]:     { label: getIssueValueLabel(ISSUE_VALUE_ASSIGNED_TO), defaultActive: true, order: 8 },
  [ISSUE_VALUE_AUTHOR]:          { label: getIssueValueLabel(ISSUE_VALUE_AUTHOR), defaultActive: true, order: 9 },
  [ISSUE_VALUE_ESTIMATED_HOURS]: { label:         getIssueValueLabel(ISSUE_VALUE_ESTIMATED_HOURS),
    defaultActive: false, order:         10 },
  [ISSUE_VALUE_DONE_RATIO]: { label: getIssueValueLabel(ISSUE_VALUE_DONE_RATIO), defaultActive: false, order: 11 },
  [ISSUE_VALUE_CREATED_ON]: { label: getIssueValueLabel(ISSUE_VALUE_CREATED_ON), defaultActive: false, order: 12 },
  [ISSUE_VALUE_UPDATED_ON]: { label: getIssueValueLabel(ISSUE_VALUE_UPDATED_ON), defaultActive: false, order: 13 },
  [ISSUE_VALUE_PROJECT]:    { label: getIssueValueLabel(ISSUE_VALUE_PROJECT), defaultActive: false, order: 14 }
  // [ISSUE_VALUE_WATCHERS]:   { label: getIssueValueLabel(ISSUE_VALUE_WATCHERS), defaultActive: false, order: 15 },
  // [ISSUE_VALUE_DESCRIPTION]: { label: getIssueValueLabel(ISSUE_VALUE_DESCRIPTION), defaultActive: true, order: 16 },
};

/**
 * Группировка набора колонок в табличном представлении
 *
 * @return {Object}
 */
const ISSUE_TABLE_COLUMN_GROUPS = {
  Идентификаторы:     [[ISSUE_VALUE_SELECTABLE, ISSUE_VALUE_ID], [ISSUE_VALUE_SUBJECT, ISSUE_VALUE_PROJECT]],
  Статусы:            [[ISSUE_VALUE_PRIORITY_ID, ISSUE_VALUE_STATUS_ID]],
  Пользователи:       [[ISSUE_VALUE_ASSIGNED_TO, ISSUE_VALUE_AUTHOR]],
  "Временные данные": [
    [ISSUE_VALUE_START_DATE, ISSUE_VALUE_DUE_DATE],
    [ISSUE_VALUE_CREATED_ON, ISSUE_VALUE_UPDATED_ON],
    [ISSUE_VALUE_ESTIMATED_HOURS, ISSUE_VALUE_DONE_RATIO]
  ]
};

export {
  ISSUE_VALUE_SELECTABLE,
  ISSUE_VALUE_ID,
  ISSUE_VALUE_UID,
  ISSUE_VALUE_AUTHOR,
  ISSUE_VALUE_ASSIGNED_TO,
  ISSUE_VALUE_CREATED_ON,
  ISSUE_VALUE_DESCRIPTION,
  ISSUE_VALUE_DONE_RATIO,
  ISSUE_VALUE_DUE_DATE,
  ISSUE_VALUE_ESTIMATED_HOURS,
  ISSUE_VALUE_PRIORITY_ID,
  ISSUE_VALUE_PROJECT,
  ISSUE_VALUE_START_DATE,
  ISSUE_VALUE_STATUS_ID,
  ISSUE_VALUE_SUBJECT,
  ISSUE_VALUE_TRACKER,
  ISSUE_VALUE_UPDATED_ON,
  ISSUE_VALUE_WATCHERS,
  ISSUE_VALUE_JOURNAL,
  ISSUE_VALUE_RELATIONS,

  ISSUE_TABLE_COLUMNS,
  ISSUE_TABLE_COLUMN_GROUPS,

  getIssueValueLabel
};
