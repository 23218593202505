import { computed, action, observable } from "mobx";
import BaseBoxClass from "./BaseBoxClass";

class TableCell extends BaseBoxClass {
  @observable
  creationPromice = null;
  @observable
  colspan = 1;
  @observable
  rowspan = 1;

  @action
  init(data, parent) {
    super.init(data, parent);
    if (data.isNew) {
      this.creationPromice = this.persistCreate();
    }
    if (data.colspan) {
      this.colspan = data.colspan;
    }
    if (data.rowspan) {
      this.rowspan = data.rowspan;
    }
  }

  @action
  setPrevChild(id, signal) {
    const prevId = this.getPrevId(id);
    if (prevId) {
      const prevItem = this.getItemById(prevId);
      prevItem && prevItem.setEditing();
    } else {
      if (signal === "ArrowUp") {
        this.parent.setUpCell(this.uid);
      } else {
        this.parent.setPrevChild(this.uid, signal);
      }
    }
  }

  @action
  setNextChild(id, signal) {
    const nextId = this.getNextId(id);
    if (nextId) {
      const nextItem = this.getItemById(nextId);
      nextItem && nextItem.setEditing();
    } else {
      if (signal === "ArrowDown") {
        this.parent.setDownCell(this.uid);
      } else {
        this.parent.setNextChild(this.uid, signal);
      }
    }
  }

  @action
  setHoveredById(uid) {
    this.parent.setHoverRect(uid, this.uid);
  }

  @action
  async insertColumnLeft() {
    return await this.parent.insertColumn(this.columnNumber);
  }

  @action
  async insertColumnRight() {
    return await this.parent.insertColumn(this.columnEnd);
  }

  @action
  async insertRowTop() {
    return await this.parent.insertRowTop(this.rowNumber);
  }

  @action
  async insertRowBottom() {
    return await this.parent.insertRowBottom(this.rowEnd);
  }

  @action
  async mergeCells() {
    if (this.isHoverValid) {
      await this.parent.mergeCells();
    }
  }

  @action
  async splitCell(direction) {
    await this.parent.splitCell(this.uid, direction);
  }

  @action
  deleteRow() {
    this.parent.deleteRow();
  }

  @action
  deleteColumn() {
    this.parent.deleteColumn(this.columnNumber);
  }

  @action
  decreaseRowspan() {
    this.rowspan = Math.max(this.rowspan - 1, 1);
  }

  @action
  decreaseColspan() {
    this.colspan = Math.max(this.colspan - 1, 1);
  }

  @computed
  get isFirst() {
    return (
      this.parent &&
      this.parent.countedCellIds.length &&
      this.uid === this.parent.countedCellIds[0]
    );
  }

  @computed
  get positionCounted() {
    let index = 0;
    if (!this.parent) {
      return -1;
    }
    index = this.parent.countedCellIds.findIndex((item) => {
      return item === this.uid;
    });

    return index;
  }

  @computed
  get prevSiblingId() {
    if (!this.store) {
      return null;
    }
    if (this.isFirst) {
      return null;
    }
    return (
      this.positionCounted - 1 >= 0 &&
      this.parent.countedCellIds[this.positionCounted - 1]
    );
  }

  @computed
  get columnNumber() {
    return this.prevSibling ? this.prevSibling.columnEnd : 0;
  }

  @computed
  get rowNumber() {
    return this.parent.position;
  }

  @computed
  get columnEnd() {
    return this.columnNumber + this.colspan;
  }

  @computed
  get rowEnd() {
    return this.rowNumber + this.rowspan;
  }

  @computed
  get table() {
    return this.parent && this.parent.table;
  }

  @computed
  get hoverBounds() {
    return this.parent && this.parent.hoverBounds;
  }

  @computed
  get isHoverValid() {
    return this.parent && this.parent.isHoverValid;
  }

  @computed
  get tableUid() {
    return this.parent && this.parent.tableUid;
  }

  @computed
  get isHovered() {
    if (!this.hoverBounds) {
      return false;
    }

    return (
      this.columnEnd > this.hoverBounds.from.column &&
      this.columnNumber < this.hoverBounds.to.column &&
      this.rowEnd > this.hoverBounds.from.row &&
      this.rowNumber < this.hoverBounds.to.row
    );
  }

  @computed
  get slug() {
    return "table-cells";
  }

  @computed
  get category() {
    return "table-cells";
  }

  @computed
  get flatItemsArray() {
    let data = [];
    this.idsArray.forEach((id) => {
      const item = this.getItemById(id);
      data = data.concat(item && item.flatItemsArray || []);
    });
    return data;
  }

  @computed
  get isHeaderCell() {
    return this.parent && this.parent.isHeader;
  }

  @computed
  get defaultInnerItemsArray() {
    return this.defaultItemsArray.map((item) => {
      return { ...item, isParent: true, ancorId: this.uid };
    });
  }

  @computed
  get defaultItemsArray() {
    return this.parent.defaultInnerItemsArray.sort((item) => {
      return this.allowedChildrenTypes.has(item.class);
    });
  }

  @computed
  get availableItemsArray() {
    if (this.isParentLocked && !this.isParentLockedByMe) {
      return [];
    }
    return this.defaultInnerItemsArray;
  }

  @computed
  get rs() {
    return this.rowspan;
  }

  @computed
  get cs() {
    return this.colspan;
  }

  @computed
  get removedDescendantListItemsArray() {
    if (!this.diffCompatitor || this.store.isPending || this.store.isDiffPending) {
      return [];
    }
    if (this.nextSibling && this.nextSibling.diffClass === "removed") {
      return [this.nextSibling, ...this.nextSibling.removedDescendantListItemsArray];
    }
    if (this.diffCompatitor && this.diffCompatitor.nextSibling && this.diffCompatitor.nextSibling.diffClass === "removed") {
      return [this.diffCompatitor.nextSibling, ...this.diffCompatitor.nextSibling.removedDescendantListItemsArray];
    }
    return [];
  }
  
  @computed
  get output() {
    return {
      class:       this.className,
      uid:         this.uid,
      elements:    this.idsArray,
      "@position": this.position
    };
  }
}

export default TableCell;
